<template>
  <div>
    <a-row>
      <a-col>
        <a-card
          :bordered="false"
          class="header-solid mb-24"
          :bodyStyle="{ padding: 0, paddingTop: '0' }"
        >
          <a-form
            ref="ruleForm"
            :form="configData"
            layout="inline"
            class="mx-25"
          >
            <!-- 거래소 -->
            <a-form-item label="" prop="name">
              <a-select
                v-decorator="[
                  'exchange',
                  { rules: [{ required: true}] },
                ]"
                size="small"
                placeholder="Exchange"
                @change="changeExchangeSelect"
                style="width: 120px"
              >
                <a-select-option
                  v-for="v in exchange_list"
                  :key="v.value"
                  :value="v.value"
                  >{{ v.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <!-- 코인 -->
            <a-form-item label="" prop="name">
              <a-select
                v-decorator="[
                  'coin',
                  { rules: [{ required: true}] },
                ]"
                size="small"
                style="width: 120px"
                placeholder="Coin"
                :loading="is_symbols_loading"
                showSearch
              >
                <a-select-option
                  v-for="v in symbols_list"
                  :key="v.value"
                  :value="v.value"
                  >{{ v.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <!-- 페어 -->
            <a-form-item label="" prop="name">
              <a-select
                v-decorator="[
                  'pair',
                  { rules: [{ required: true}] },
                ]"
                size="small"
                style="width: 120px"
                placeholder="Pair"
                :loading="is_symbols_loading"
                showSearch
              >
                <a-select-option
                  v-for="v in pair_list"
                  :key="v.value"
                  :value="v.value"
                  >{{ v.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <!-- 가격 -->
            <a-form-item label="" prop="name">
              <a-input
                v-decorator="[
                  'price',
                  { rules: [{ required: true}] },
                ]"
                size="small"
                placeholder="Price"
              />
            </a-form-item>
            <!-- 상승/하락 -->
            <a-form-item label="" prop="name">
              <a-select
                v-decorator="[
                  'notification_type',
                  { rules: [{ required: true}] },
                ]"
                size="small"
                style="width: 120px"
                placeholder="UP/DOWN"
              >
                <a-select-option value="up">UP</a-select-option>
                <a-select-option value="down">DOWN</a-select-option>
              </a-select>
            </a-form-item>
            <!-- 변동률 -->
            <a-form-item label="" prop="name">
              <a-input
                v-decorator="[
                  'fluctate_rate',
                  { rules: [{ required: true}] },
                ]"
                size="small"
                placeholder="Fluctate Rate"
              />
            </a-form-item>
            <!-- Telegram -->
            <a-form-item label="" prop="name">
              <a-select
                v-decorator="[
                  'telegram_id',
                  { rules: [{ required: true}] },
                ]"
                size="small"
                style="width: 120px"
                placeholder="Telegram"
              >
                <a-select-option
                  v-for="v in telegram_list"
                  :key="v.value"
                  :value="v.value"
                  >{{ v.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button
               size="small"
               type="primary"
               class="mt-5"
               @click="addNotification"
               >
                ADD
              </a-button>
            </a-form-item>
          </a-form>
        </a-card>

        <div style="margin-top: 10px; margin-bottom: 10px;"><strong>* Coinone, Coinstore, Gopax, Xt 변동률 지원하지않음(변동률 = 0 고정)</strong></div>
      </a-col>
      <a-col>
        <a-card
          :bordered="false"
          class="header-solid mb-24"
          :bodyStyle="{ padding: 0, paddingTop: '0' }"
        >
          <a-table
            class="mt-20"
            :columns="columns"
            :data-source="table_data"
            :rowKey="(record) => record.id"
            :pagination="false"
            :loading="loading"
          >
            <template slot="noti" slot-scope="text">
              {{notification_type[text]}}
            </template>
            <template slot="telegram" slot-scope="text">
              {{telegram_dict[text]}}
            </template>
            <template slot="target" slot-scope="text, record">
              <a-input 
                :value="text" 
                type="number" 
                @pressEnter="(e) => updateNotification(e, record)"
                style="width: 150px"
              ></a-input>
            </template>
            <template slot="switch" slot-scope="text, record">
              <a-switch :checked="text === 'running'" @click="notiSwitchClickHandler(record)"></a-switch>
            </template>
            <template slot="fluctaterate" slot-scope="text, record">
              <a-input 
                :value="text" 
                type="number" 
                @pressEnter="(e) => updateNotification2(e, record)"
                style="width: 150px"
              ></a-input>
            </template>
            <template slot="action" slot-scope="text, record">
              <a-button
                @click.native="deleteNotification(record.id)"
                class="px-20"
                size="small"
                type="link"
              >
                DELETE
              </a-button>
            </template>
          </a-table>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const columns = [
  // {
  //   key: "id",
  //   title: "ID",
  //   dataIndex: "id",
  //   sorter: (a, b) => a.id - b.id,
  //   sortDirections: ["descend", "ascend"],
  // },
  {
    title: "EXCHANGE",
    dataIndex: "exchange",
  },
  {
    title: "COIN",
    dataIndex: "coin",
  },
  {
    title: "PAIR",
    dataIndex: "pair",
  },
  {
    title: "PRICE",
    dataIndex: "price",
    scopedSlots: { customRender: "target" },
  },
  {
    title: "TYPE",
    dataIndex: "notification_type",
    scopedSlots: { customRender: "noti" },
  },
  {
    title: "FLUCTATE RATE",
    dataIndex: "fluctate_rate",
    scopedSlots: { customRender: "fluctaterate" },
  },
  {
    title: "TELEGRAM",
    dataIndex: "telegram_id",
    scopedSlots: { customRender: "telegram" },
  },
  {
    title: "",
    dataIndex: "status",
    scopedSlots: { customRender: "switch" },
  },
  {
    title: "",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];


export default {
  components: {},
  data() {
    return {
      columns,
      table_data: [],
      notification_type: {up: 'UP', down: 'DOWN'},
      
      loading: false,
      configData: this.$form.createForm(this, { name: 'form' }),
      exchange_list: [],
      telegram_list: [],
      telegram_dict: {},
      symbols_list: [
        // {
        //     label: 'Coinone',
        //     value: 'coinone'
        // },
      ],
      pair_list: [
        // {
        //     label: 'Coinone',
        //     value: 'coinone'
        // },
      ],
      is_symbols_loading: false,
    };
  },

  async mounted() {
    await this.initData();
    await this.getExchange();
    await this.getTelegram();
  },
  computed: {},
  watch: {},
  methods: {
    async notiSwitchClickHandler(record) {
      try {
        const res = await this.$http.put(`/api/v1/pricenotification/${record.id}`, {
          status: record.status === 'running' ? 'stop' : 'running'
        });
        await this.initData()
      } catch(e) {

      }
      await this.initData()
    },
    async addNotification() {
      // console.log(await this.configData.getFieldsValue())
      try {
        await this.configData.validateFields()

        const res = await this.$http.post(`/api/v1/pricenotification`, this.configData.getFieldsValue());
        await this.initData()
      } catch(e) {

      }

      // 등록

    },
    async updateNotification(e, record) {
      
      const update = {
        price: e.target.value
      }
      try {
        const res = await this.$http.put(`/api/v1/pricenotification/${record.id}`, update);
        await this.initData()
        this.$notification.success({
            message: `Succeess Update`,
            placement: 'bottomLeft',
        });
      } catch(e) {
        this.$notification.error({
            message: `Error Update..`,
            placement: 'bottomLeft',
        });
      }
    },
    async updateNotification2(e, record) {
      
      const update = {
        fluctate_rate: e.target.value
      }
      try {
        const res = await this.$http.put(`/api/v1/pricenotification/${record.id}`, update);
        await this.initData()
        this.$notification.success({
            message: `Succeess Update`,
            placement: 'bottomLeft',
        });
      } catch(e) {
        this.$notification.error({
            message: `Error Update..`,
            placement: 'bottomLeft',
        });
      }
    },
    async deleteNotification(id) {
      // console.log(await this.configData.getFieldsValue())
      try {
        const res = await this.$http.delete(`/api/v1/pricenotification/${id}`);
        await this.initData()
      } catch(e) {

      }

      // 등록

    },
    async changeExchangeSelect(v) {
      await this.get_symbols(v);
    },
    async get_symbols(exchange) {
      const coin_list = [];
      const pair_list = [];
      this.is_symbols_loading = true;
      const res = await this.$http.get(`/api/v1/exchange/${exchange}/symbols`);
      let symbol_split = "";
      if (res.data[0].includes("/")) {
        symbol_split = "/";
      } else if (res.data[0].includes("_")) {
        symbol_split = "_";
      }
      if (symbol_split !== "") {
        res.data.forEach((e) => {
          const _symbol = e.split(symbol_split);
          if (!coin_list.find((s) => s.label === _symbol[0])) {
            coin_list.push({
              label: _symbol[0],
              value: _symbol[0].toLowerCase(),
            });
          }
          if (!pair_list.find((s) => s.label === _symbol[1])) {
            pair_list.push({
              label: _symbol[1],
              value: _symbol[1].toLowerCase(),
            });
          }
        });
      } else {
        res.data.forEach((e) => {
          coin_list.push({
            label: e,
            value: e.toLowerCase(),
          });
        });
        pair_list.push({
          label: "KRW",
          value: "krw",
        });
      }
      this.is_symbols_loading = false;
      this.symbols_list = coin_list
      this.pair_list = pair_list
    },
    async getTelegram() {
      try {
        const res = await this.$http.get("/api/v1/telegram/");
        for (const o of res.data) {
          this.telegram_list.push({
            label: o.name,
            value: o.id,
          });
          this.telegram_dict[o.id] = o.name
        }
        // console.log(this.telegram_list)
      } catch (e) {
        console.error(e);
      }
    },
    async getExchange() {
      try {
        const res = await this.$http.get("/api/v1/info/exchange");
        for (const [key, value] of Object.entries(res.data)) {
          this.exchange_list.push({
            label: key,
            value,
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    async initData() {
      
      try {
        const res = await this.$http.get("/api/v1/pricenotification");
        this.table_data = res.data
      } catch (e) {
        console.error(e);
      }
      
    },
    
    
  },
};
</script>

<style lang="scss"></style>
