<template>
  <div>
    <a-card
      :bordered="false"
      class="header-solid h-full"
      :bodyStyle="{ padding: 0 }"
    >
      <a-table
        :columns="columns"
        :data-source="orderbook"
        :pagination="false"
        :showHeader="true"
        :scroll="{ y: 1200 }"
      >
        <template slot="quantity-column" slot-scope="text">
          <span @click="quantityClickHandler(text)">
            {{
              Number(text).toLocaleString(undefined, {
                maximumFractionDigits: 8,
              })
            }}
          </span>
        </template>
        <template slot="price-column" slot-scope="text">
          <span @click="priceClickHandler(text)">
            {{
              Number(text).toLocaleString(undefined, {
                maximumFractionDigits: 8,
              })
            }}
          </span>
        </template>
        <template slot="comma-format" slot-scope="text">
          {{
            Number(text).toLocaleString(undefined, { maximumFractionDigits: 8 })
          }}
        </template>

        <template slot="left-click-order" slot-scope="text, record">
          <a-button
            v-if="selectClickOrderType == 'bid'"
            type="primary"
            ghost
            class="m-0"
            @click="clickOrderBidButtonHandler(record.bid_p)"
            >Buy</a-button
          >
          <a-button
            v-else
            danger
            class="m-0"
            @click="clickOrderAskButtonHandler(record.bid_p)"
            >Sell</a-button
          >
        </template>
        <template slot="right-click-order" slot-scope="text, record">
          <a-button
            v-if="selectClickOrderType == 'bid'"
            type="primary"
            ghost
            class="m-0"
            @click="clickOrderBidButtonHandler(record.ask_p)"
            >Buy</a-button
          >
          <a-button
            v-else
            danger
            class="m-0"
            @click="clickOrderAskButtonHandler(record.ask_p)"
            >Sell</a-button
          >
        </template>
      </a-table>
    </a-card>
  </div>
</template>
<script>
const ExchangeName = "xt";

export default {
  props: {
    pair: String,
    coin: String,
    selectClickOrderType: String,
    selectPriceUnit: Number,
    selectCumViewType: String,
    running_server: String,
  },
  components: {},
  data() {
    return {
      columns: [
        {
          title: "주문",
          class: "py-0",
          width: "90px",
          scopedSlots: { customRender: "left-click-order" },
        },
        {
          title: "누적매수가",
          dataIndex: "bid_cum_p",
          align: "right",
          class: "cum-cell left-line right-line",
          scopedSlots: { customRender: "comma-format" },
        },
        {
          title: "누적수량",
          dataIndex: "bid_cum_q",
          align: "right",
          class: "cum-cell right-line",
          scopedSlots: { customRender: "quantity-column" },
        },
        {
          title: "수량",
          dataIndex: "bid_q",
          align: "right",
          scopedSlots: { customRender: "quantity-column" },
        },
        {
          title: "매수가",
          dataIndex: "bid_p",
          class: "bid-price font-bold",
          scopedSlots: { customRender: "price-column" },
        },
        {
          title: "매도가",
          dataIndex: "ask_p",
          class: "ask-price font-bold",
          scopedSlots: { customRender: "price-column" },
        },
        {
          title: "수량",
          dataIndex: "ask_q",
          align: "right",
          scopedSlots: { customRender: "quantity-column" },
        },
        {
          title: "누적수량",
          dataIndex: "ask_cum_q",
          align: "right",
          class: "cum-cell left-line right-line",
          scopedSlots: { customRender: "quantity-column" },
        },
        {
          title: "누적매도가",
          dataIndex: "ask_cum_p",
          align: "right",
          class: "cum-cell right-line",
          scopedSlots: { customRender: "comma-format" },
        },
        {
          title: "주문",
          class: "py-0",
          width: "90px",
          scopedSlots: { customRender: "right-click-order" },
        },
      ],

      orderbook_update_timestamp: null,
      orderbook: [],
    };
  },
  methods: {
    getSymbol() {
      return `${this.coin}_${this.pair}`;
    },
    async UpdateOrderbook() {
      const { data: res_data } = await this.$http.get(`/api/v1/exchange2/${ExchangeName}/orderbook/${this.running_server}/${this.getSymbol()}`);
      const orderbook = [];
      const limit = Math.max(res_data.asks.length, res_data.bids.length);
      for (let i = 0; i < limit; i++) {
        orderbook.push({
          index: i,
          ask_p: 0,
          ask_q: 0,
          bid_p: 0,
          bid_q: 0,
          ask_cum_q: 0,
          ask_cum_p: 0,
          bid_cum_q: 0,
          bid_cum_p: 0,
        });
      }
      res_data.asks.forEach((data, index) => {
        const data_0 = parseFloat(data[0]);
        const data_1 = parseFloat(data[1]);
        orderbook[index].ask_p = data[0];
        orderbook[index].ask_q = data[1];
        if (index == 0) {
          orderbook[index].ask_cum_q = data_1;
          orderbook[index].ask_cum_p = data_1 * data_0;
        } else {
          orderbook[index].ask_cum_q =
            parseFloat(orderbook[index - 1].ask_cum_q) + data_1;
          orderbook[index].ask_cum_p =
            parseFloat(orderbook[index - 1].ask_cum_p) + data_1 * data_0;
        }
      });
      res_data.bids.forEach((data, index) => {
        const data_0 = parseFloat(data[0]);
        const data_1 = parseFloat(data[1]);
        orderbook[index].bid_p = data[0];
        orderbook[index].bid_q = data[1];
        if (index == 0) {
          orderbook[index].bid_cum_q = data_1;
          orderbook[index].bid_cum_p = data_1 * data_0;
        } else {
          orderbook[index].bid_cum_q =
            parseFloat(orderbook[index - 1].bid_cum_q) + data_1;
          orderbook[index].bid_cum_p =
            parseFloat(orderbook[index - 1].bid_cum_p) + data_1 * data_0;
        }
      });
      this.orderbook = orderbook;
    },
    init_orderbook() {
      const limit = 30;
      this.orderbook = [];
      for (let i = 0; i < limit; i++) {
        this.orderbook.push({
          index: i,
          ask_p: 0,
          ask_q: 0,
          bid_p: 0,
          bid_q: 0,
          ask_cum_q: 0,
          ask_cum_p: 0,
          bid_cum_q: 0,
          bid_cum_p: 0,
        });
      }
      // this.orderbook_update_timestamp = null
    },
    quantityClickHandler(q) {
      this.$emit("setQuantityForm", q);
    },
    priceClickHandler(p) {
      this.$emit("setPriceForm", p);
    },
    // clickOrderButtonHandler(record, type){
    //     if(type === 'buy'){
    //         this.$emit('clickOrderAsk', record.ask_p)
    //     } else if(type === 'sell'){

    //     }
    // },
    clickOrderAskButtonHandler(ask_price) {
      this.$emit("clickOrderAsk", ask_price);
    },
    clickOrderBidButtonHandler(bid_price) {
      this.$emit("clickOrderBid", bid_price);
    },
  },
  async mounted() {
    this.init_orderbook();
    await this.UpdateOrderbook();
    this.updateInterval = setInterval(() => {
      this.UpdateOrderbook();
    }, 3 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
};
</script>

<style lang="scss">
td.ask-price {
  background-color: #eef6ff !important;
}
td.bid-price {
  background-color: #fff0ef !important;
}
td.cum-cell {
  // border-left: 1px solid #e8e8e8;
  background-color: #f1f1f1 !important;
}
td.left-line {
  border-left: 1px solid black;
}
td.right-line {
  border-right: 1px solid black;
}
</style>