<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="orders_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    <!-- {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }} -->
                    {{ text }}
                </template>
                <template slot="is_ask" slot-scope="text">
                    {{ text === false ? "BUY" : "SELL" }}
                </template>
                <template slot="amount" slot-scope="text, record">
                    {{ (record.side === 'buy' || record.side === 'buy_market') ? record.amount : Number(record.rate * record.amount).toLocaleString(undefined, { maximumFractionDigits: 5 }) }}
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
const ExchangeName = 'bitkub';
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => a.ts - b.ts,
                    defaultSortOrder: 'descend',
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'side',
                    // scopedSlots: { customRender: 'is_ask'}
                },
                {
                    title: this.$i18n.t('trade.column_order_price'),
                    scopedSlots: { customRender: 'amount'}
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    dataIndex: 'rate',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_amount'),
                    dataIndex: 'amount_',
                    scopedSlots: { customRender: 'comma-format'}
                },
            ],

            orders_table: []
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`
        },
        async UpdateOrders(){
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/orders`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                running_server: this.running_server
            })
            let res_ = res.data;
            if (res_.length > 0) {
                res_.forEach(item => {
                    item.cTime = parseInt(item.ts)
                    const date = new Date(item.cTime); 
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                    item.timestamp = koreanTime;

                    if (item.side === 'buy') {
                        item.amount_ = parseFloat(item.amount) / parseFloat(item.rate);
                        item.amount_ = item.amount_.toLocaleString(undefined, { maximumFractionDigits: 8 })
                    } else if (item.side === 'sell') {
                        item.amount_ = parseFloat(item.amount)
                    }

                    if (item.type === 'market'){
                        if(item.side === 'buy') {
                            item.side = 'buy_market'
                        } else {
                            item.side = 'sell_market'
                        }
                    }
                });
            }
            this.orders_table = res_; 
            // {
            //   "txn_id": "OSMOSELL0000012025",
            //   "order_id": "1166762",
            //   "hash": "fwQ6dnXtoNdcSJ1BV62TypCX3Z5",
            //   "parent_order_id": "0",
            //   "parent_order_hash": "fwQ6dnQWQPs4cbeF9jmSTaXYA43",
            //   "super_order_id": "0",
            //   "super_order_hash": "fwQ6dnQWQPs4cbeF9jmSTaXYA43",
            //   "client_id": "",
            //   "taken_by_me": false,
            //   "is_maker": false,
            //   "side": "sell",
            //   "type": "limit",
            //   "rate": "17.82",
            //   "fee": "5.84",
            //   "credit": "0",
            //   "amount": "131.02456583",
            //   "ts": 1727067432153
            // },
            // {
            //   "txn_id": "OSMOSELL0000012024",
            //   "order_id": "1697675",
            //   "hash": "fwQ6dnQY5TFtmdA5MGkzznjDNmE",
            //   "parent_order_id": "1697674",
            //   "parent_order_hash": "fwQ6dnQY5TFtmdA5MGkzzgBgYcx",
            //   "super_order_id": "1697674",
            //   "super_order_hash": "fwQ6dnQY5TFtmdA5MGkzzgBgYcx",
            //   "client_id": "",
            //   "taken_by_me": false,
            //   "is_maker": true,
            //   "side": "buy",
            //   "type": "limit",
            //   "rate": "17.88",
            //   "fee": "1.9",
            //   "credit": "0",
            //   "amount": "759.12",
            //   "ts": 1727066699045
            // },
        }
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">

</style>