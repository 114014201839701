<template>
  <a-form
    :form="form"
    layout="vertical"
    hide-required-mark
    @submit="handleSubmit"
  >
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-20" label="Name">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: 'Please enter user name' }],
              },
            ]"
            placeholder="Name"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-20" label="Email Address">
          <a-input
            v-decorator="[
              'email',
              {
                rules: [
                  { required: true, message: 'Please enter Email Address' },
                ],
              },
            ]"
            placeholder="Email"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-20" label="Password">
          <a-input
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: 'Please enter Password' }],
              },
            ]"
            type="password"
            placeholder="********"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-20" label="Repeat Password">
          <a-input
            v-decorator="[
              'repeatpassword',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please enter Repeat Password',
                  },
                ],
              },
            ]"
            type="password"
            placeholder="********"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      
      <a-col :span="12">
        <a-form-item label="User Role">
          <a-select
            v-decorator="[
              'user_role',
              {
                rules: [{ required: true, message: 'Please select User Role' }],
              },
            ]"
            placeholder="Please select User Role"
          >
            <a-select-option value="admin"> Admin User </a-select-option>
            <a-select-option value="user"> Normal User </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
    
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 25px',
        background: '#fff',
        textAlign: 'right',
      }"
    >
      <a-button
        class="px-15"
        type="primary"
        size="small"
        html-type="submit"
        align="center"
      >
        CREATE
      </a-button>
    </div>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "userlist" });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log("Received values of form: ", values);
          this.$http
            .post(`/api/v1/users/`, {
              name: values.name,
              email: values.email,
              password: values.password,
              role: values.user_role
            })
            .then(() => {
              this.redirect();
            });
        } else {
          console.log(err);
        }
      });
    },
    onChangeEmail() {
      this.email_yn = this.email_yn ? 0 : 1;
    },
    onChangeActive() {
      this.use_yn = this.use_yn ? 0 : 1;
    },
    redirect() {
      // TO-DO : redirect error will solved(Completed)
      // this.$router.push("/pages/UserManagement/UserList")
      this.$router.go("/pages/UserManagement/UserList");
    },
  },
};
</script>

<style></style>
