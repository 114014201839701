<template>
    <div>
    <a-row :gutter="24" type="flex" align="stretch">
        <a-col :span="24" class="mb-5">

            <a-select
             style="width: 200px; margin-right: 10px" 
             placeholder="Select Exchange" 
             @change="changeExchangeSelect"
            >
                <a-select-option v-for="v in exchange_list" :key="v.value" :value="v.value">
                    {{ v.label }}
                </a-select-option>
            </a-select>

            <a-select 
             style="width: 200px; margin-right: 10px" 
             placeholder="Select APIKEY" 
             :loading="is_apikey_loading" 
             @change="handleApikeyChange"
             >
                <a-select-option v-for="v in apikey_list" :key="v.value" :value="v.value">
                    {{ v.label }}
                </a-select-option>
            </a-select>

            <a-select 
             style="width: 200px; margin-right: 10px"
             placeholder="Select Currency" 
             showSearch
             :loading="is_pair_loading"
             @change="handleSelectPairChange"
            >
                <a-select-option v-for="(v, i) in symbol_list" :key="i" :value="v">
                    {{v}}
                </a-select-option>
            </a-select>

            <a-input
             style="width: 200px; margin-right: 10px"
             placeholder="Binding Server" 
             :value="selectedApiKeyServer" 
             disabled>
            </a-input>
        </a-col>
        <template v-if="select_api_key_id && select_pair && select_coin && select_exchange">
            <a-divider />
            <balance ref="balance_ref" :coin="select_coin" :pair="select_pair" :apikeyId="select_api_key_id" :running_server="select_server" :exchange_name="select_exchange"/>
        </template>
        <a-divider />

        <!-- bithumb 출금 정보 -->
        <template v-if="select_api_key_id && select_pair && select_coin && select_exchange==='bithumb'">
            <div>
                <a-form-model
                    ref="ruleForm"
                    :model="configData"
                    :rules="rules"
                    :label-col="{span: 5}"
                    :wrapper-col="{span: 19}"
                    layout="horizontal"
                >

                    <a-form-model-item label="토큰명" prop="name">
                        <a-input
                         style="width: 160px; margin-right: 10px;"
                         placeholder="Token Name" 
                         :value="select_coin" 
                         disabled>
                        </a-input>
                    </a-form-model-item>

                    <a-form-model-item label="네트워크" prop="name">
                        <a-input
                         style="width: 160px;"
                         placeholder="Withd Network" 
                         :value="select_net2" 
                         disabled>
                        </a-input>
                    </a-form-model-item>

                    <a-form-model-item label="주소 입력방식" prop="name">
                        <a-select
                         style="width: 160px; margin-right: 10px;"
                         placeholder="Method"
                         @change="changeAdd"
                        >
                            <a-select-option v-for="v in withd_add_method" :key="v.value" :value="v.value">
                                {{ v.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <!-- 주소 수동입력 -->
                    <template v-if="add_method==='수동입력'">
                        <a-form-model-item label="출금 거래소" prop="name">
                            <a-select
                            style="width: 160px; margin-right: 10px;"
                            placeholder="Destination" 
                            @change="changeExchangeSelect2"
                            show-search>
                                <a-select-option v-for="v in exchange_list2" :key="v.value" :value="v.value">
                                    {{ v.label }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item label="출금 주소" prop="name">
                            <a-input
                            style="width: 350px;"
                            placeholder="Enter Your Address" 
                            v-model="dest_address"
                            >
                            </a-input>
                        </a-form-model-item>
                    </template>

                    <!-- 주소 자동입력 -->
                    <template v-if="add_method==='주소록'">
                        <a-form-model-item label="주소 선택" prop="name">
                            <a-select
                            style="width: 160px; margin-right: 10px;"
                            placeholder="Select" 
                            @change="changeAdd2"
                            >
                                <a-select-option v-for="v in dest_address_list" :key="v.value" :value="v.label">
                                    {{ v.label }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item label="출금 거래소" prop="name">
                            <a-input
                            style="width: 160px; margin-right: 10px;"
                            :value="add_exchange" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item label="출금 주소" prop="name">
                            <a-input
                            style="width: 350px; margin-right: 10px;"
                            :value="add_address" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                    </template>

                    <div style="margin-left: 30px; margin-top: 10px;">(최소 수량 {{ withd_min_amount }} {{ select_coin }})</div>

                    <a-form-model-item label="출금 수량" prop="name">
                        <a-input-number
                            v-model="withd_amount"
                            size="small"
                            style="width: 160px; margin-right: 5%;" 
                            :formatter="value => {
                                const parts = value.toString().split('.');
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                return parts.join('.');
                            }"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            :min="withd_min_amount" 
                            @change="handleInputChange"
                            />                      
                    </a-form-model-item>

                    <a-form-model-item label="수취인명(KR)" prop="name">
                        <a-input
                         v-model="reciever_name_kr"
                         style="width: 160px;"
                         placeholder="Reciever Name(KR)" 
                         @change="handleInputChange2"
                        >
                        </a-input>
                    </a-form-model-item>

                    <a-form-model-item label="수취인명(EN)" prop="name">
                        <a-input
                         v-model="reciever_name_en"
                         style="width: 160px;"
                         placeholder="Reciever Name(EN)" 
                         @change="handleInputChange3"
                        >
                        </a-input>
                    </a-form-model-item>
                </a-form-model>

                <a-button class="mx-15 add-button" @click="submitForm" :disabled="!isSubmitEnabled">출금 요청</a-button>
                <p v-if="missingValues.length > 0" style="color: red; margin-top: 10px; margin-left: 20px;">다음 값이 누락되었습니다: {{ missingValues.join(', ') }}</p>
            </div>
        </template>

        <!-- coinbase 출금 정보 -->
        <template v-if="select_api_key_id && select_pair && select_coin && select_exchange==='coinbase'">
            <div>
                <a-form-model
                    ref="ruleForm"
                    :model="configData"
                    :rules="rules"
                    :label-col="{span: 5}"
                    :wrapper-col="{span: 19}"
                    layout="horizontal"
                >

                    <!-- <div v-if="select_exchange === 'bitget'" style="margin-left: 30px; color: red; margin-bottom: 10px;">
                        * Address Book에 등록되어 있는 주소로만 출금 가능 <br>
                          ( https://www.bitget.com/asia/asset/addressBook )
                    </div> -->
                    <a-form-model-item label="토큰명" prop="name">
                        <a-input
                         style="width: 160px; margin-right: 10px;"
                         placeholder="Token Name" 
                         :value="select_coin" 
                         disabled>
                        </a-input>
                    </a-form-model-item>

                    <a-form-model-item label="Wallet" prop="name">
                        <a-select
                            style="width: 160px; margin-right: 10px"
                            placeholder="Select"
                            :loading="is_account_loading" 
                            @change="handleAccountChange"
                        >
                            <a-select-option v-for="v in account_list" :key="v.value" :value="v.value">
                                {{ v.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="네트워크" prop="name">
                        <a-input
                        style="width: 160px; margin-right: 10px;"
                        placeholder="Withd Status" 
                        :value="select_net2" 
                        disabled>
                        </a-input>
                    </a-form-model-item>
                    
                    <a-form-model-item label="주소 입력방식" prop="name">
                        <a-select
                         style="width: 160px; margin-right: 10px;"
                         placeholder="Method"
                         @change="changeAdd"
                        >
                            <a-select-option v-for="v in withd_add_method" :key="v.value" :value="v.value">
                                {{ v.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <!-- 주소 수동입력 -->
                    <template v-if="add_method==='수동입력'">    
                        <div style="margin-left: 30px; margin-top: 10px;"> * 받는 거래소의 주소와 네트워크를 한 번 더 확인하세요.</div>
                        <a-form-model-item label="출금 주소" prop="name">
                            <a-input
                            style="width: 350px;"
                            placeholder="Enter Your Address" 
                            v-model="dest_address"
                            >
                            </a-input>
                        </a-form-model-item>
                    </template>

                    <!-- 주소 자동입력 -->
                    <template v-if="add_method==='주소록'">
                        <div style="margin-left: 30px; margin-top: 10px;"> * 받는 거래소의 주소와 네트워크를 한 번 더 확인하세요.</div>
                        <a-form-model-item label="주소 선택" prop="name">
                            <a-select
                            style="width: 160px; margin-right: 10px;"
                            placeholder="Select" 
                            @change="changeAdd2"
                            >
                                <a-select-option v-for="v in dest_address_list" :key="v.value" :value="v.label">
                                    {{ v.label }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item label="출금 주소" prop="name">
                            <a-input
                            style="width: 350px; margin-right: 10px;"
                            :value="add_address" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                    </template>

                    <div style="margin-left: 30px; margin-top: 10px;">(최소 수량 정보 지원하지 않음)</div>
                        
                    <a-form-model-item label="출금 수량" prop="name">
                        <a-input-number
                            v-model="withd_amount"
                            size="small"
                            style="width: 160px; margin-right: 5%;" 
                            :formatter="value => {
                                const parts = value.toString().split('.');
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                return parts.join('.');
                            }"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            :min="withd_min_amount" 
                            @change="handleInputChange"
                            />                      
                    </a-form-model-item>
                    <div style="margin-left: 30px; margin-top: 10px;">* 출금 수수료는 출금 수량과 별도로 합산되어 잔고에서 차감됩니다.</div>

                </a-form-model>

                <a-button class="mx-15 add-button" @click="submitForm_coinbase" :disabled="!isSubmitEnabled_coinbase">출금 요청</a-button>
                <p v-if="missingValues_coinbase.length > 0" style="color: red; margin-top: 10px; margin-left: 20px;">다음 값이 누락되었습니다: {{ missingValues_coinbase.join(', ') }}</p>
            </div>
        </template>

        <!-- binance 출금 정보 -->
        <template v-if="select_api_key_id && select_pair && select_coin && (select_exchange==='binance' || select_exchange==='bitget' || select_exchange==='lbank')">
            <div>
                <a-form-model
                    ref="ruleForm"
                    :model="configData"
                    :rules="rules"
                    :label-col="{span: 5}"
                    :wrapper-col="{span: 19}"
                    layout="horizontal"
                >

                    <div v-if="select_exchange === 'bitget'" style="margin-left: 30px; color: red; margin-bottom: 10px;">
                        * Address Book에 등록되어 있는 주소로만 출금 가능 <br>
                          ( https://www.bitget.com/asia/asset/addressBook )
                    </div>
                    <a-form-model-item label="토큰명" prop="name">
                        <a-input
                         style="width: 160px; margin-right: 10px;"
                         placeholder="Token Name" 
                         :value="select_coin" 
                         disabled>
                        </a-input>
                    </a-form-model-item>

                    <a-form-model-item label="주소 입력방식" prop="name">
                        <a-select
                         style="width: 160px; margin-right: 10px;"
                         placeholder="Method"
                         @change="changeAdd"
                        >
                            <a-select-option v-for="v in withd_add_method" :key="v.value" :value="v.value">
                                {{ v.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <!-- 주소 수동입력 -->
                    <template v-if="add_method==='수동입력'">
                        <a-form-model-item label="네트워크" prop="name">
                            <a-select
                             style="width: 150px; margin-right: 10px"
                             placeholder="Select"
                             :loading="is_network_loading" 
                             @change="handleNetworkChange"
                            >
                                <a-select-option v-for="v in network_list" :key="v.value" :value="v.value">
                                    {{ v.label }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item label="네트워크 상태" prop="name">
                            <a-input
                            style="width: 160px;"
                            placeholder="Withd Network"
                            :value="select_net2" 
                            disabled>
                            </a-input>
                        </a-form-model-item>
                        
                        <div style="margin-left: 30px; margin-top: 10px;"> * 받는 거래소의 주소와 네트워크를 한 번 더 확인하세요.</div>
                        <a-form-model-item label="출금 주소" prop="name">
                            <a-input
                            style="width: 350px;"
                            placeholder="Enter Your Address" 
                            v-model="dest_address"
                            >
                            </a-input>
                        </a-form-model-item>
                    </template>

                    <!-- 주소 자동입력 -->
                    <template v-if="add_method==='주소록'">
                        <a-form-model-item label="주소 선택" prop="name">
                            <a-select
                            style="width: 160px; margin-right: 10px;"
                            placeholder="Select" 
                            @change="changeAdd2"
                            >
                                <a-select-option v-for="v in dest_address_list" :key="v.value" :value="v.label">
                                    {{ v.label }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item label="네트워크 상태" prop="name">
                            <a-input
                            style="width: 160px;"
                            placeholder="Withd Network"
                            :value="select_net2" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item label="출금 주소" prop="name">
                            <a-input
                            style="width: 350px; margin-right: 10px;"
                            :value="add_address" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                    </template>

                    <div style="margin-left: 30px; margin-top: 10px;">(최소 수량 {{ withd_min_amount }} {{ select_coin }})</div>
                        
                    <a-form-model-item label="출금 수량" prop="name">
                        <a-input-number
                            v-model="withd_amount"
                            size="small"
                            style="width: 160px; margin-right: 5%;" 
                            :formatter="value => {
                                const parts = value.toString().split('.');
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                return parts.join('.');
                            }"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            :min="withd_min_amount" 
                            @change="handleInputChange"
                            />                      
                    </a-form-model-item>
                    <div style="margin-left: 30px; margin-top: 10px;">* 출금 수수료는 출금 수량에서 차감되어 전송됩니다.</div>

                </a-form-model>

                <a-button class="mx-15 add-button" @click="submitForm_binance" :disabled="!isSubmitEnabled_binance">출금 요청</a-button>
                <p v-if="missingValues_binance.length > 0" style="color: red; margin-top: 10px; margin-left: 20px;">다음 값이 누락되었습니다: {{ missingValues_binance.join(', ') }}</p>
            </div>
        </template>
    </a-row>

        <template v-if="select_api_key_id && select_pair && select_coin && select_exchange">
            <div style="margin-top: 20px;">
                <withdList ref="withd_ref" :coin="select_coin" :pair="select_pair" :apikeyId="select_api_key_id" :running_server="select_server" :network="select_net" :exchange_name="select_exchange" :account_id="select_account_"/>
            </div>
        </template>

    </div>
</template>
<script>

import balance from './balance';
import withdList from './withdList';

export default {
        components: {
        balance,
        withdList
    },
    data() {
        return {
            withd_add_method:
            [
                {label: '주소록', value: '주소록'},
                {label: '수동입력', value: '수동입력'},
            ],
            // bithumb 트래블룰
            exchange_list2:
            [
                {label: 'Binance', value: 'binance'},
                {label: 'Coinone', value: 'coinone'},
                {label: 'Korbit',value: 'korbit'},
                {label: 'Hexlant',value: 'hexlant'},
                {label: 'Kdac',value: 'kdac'},
                {label: 'Upbit',value: 'upbit'},
                {label: 'Flybit',value: 'flybit'},
                {label: 'Gopax',value: 'gopax'},
                {label: 'Pravang',value: 'pravang'},
                {label: 'Aprobit',value: 'aprobit'},
                {label: 'Foblgate',value: 'foblgate'},
                {label: 'Borabit',value: 'borabit'},
                {label: 'Coredax',value: 'coredax'},
                {label: 'Btx',value: 'btx'},
                {label: 'Htx',value: 'htx'},
                {label: 'Paycoin',value: 'paycoin'},
                {label: 'Bingx',value: 'bingx'},
                {label: 'Bitget',value: 'bitget'},
                {label: 'Oasis',value: 'oasis'},
                {label: 'Flipster',value: 'flipster'},
                {label: 'Lcash',value: 'lcash'},
                {label: 'Lbank',value: 'lbank'},
                {label: 'Bybit',value: 'bybit'},
                {label: 'Okx',value: 'okx'},
                {label: 'WooX',value: 'woox'},
                {label: 'Infiniteblock',value: 'infiniteblock'},
                {label: 'Hyperithm',value: 'hyperithm'},
                {label: 'Coinbase',value: 'coinbase'},
                {label: 'Kraken',value: 'kraken'},
                {label: 'Linebitmax',value: 'linebitmax'},
                {label: 'Coincheck',value: 'coincheck'},
                {label: 'Bitflyer',value: 'bitflyer'},
                {label: 'Gemini',value: 'gemini'},
                {label: 'Bitbank',value: 'bitbank'},
                {label: 'CoinlistPro',value: 'coinlistpro'},
                {label: 'Bithumb',value: 'bithumb'},
            ],
            input_price: '',
            input_amount: '',
            is_apikey_loading: false,
            is_pair_loading: false,
            select_api_key_id: null,
            exchange_list: [
                {
                    label: 'BITHUMB',
                    value: 'bithumb',
                },
                {
                    label: 'BINANCE',
                    value: 'binance',
                },
                {
                    label: 'BITGET',
                    value: 'bitget',
                },
                {
                    label: 'LBANK',
                    value: 'lbank',
                },
                {
                    label: 'COINBASE',
                    value: 'coinbase',
                },
            ],
            apikey_list: [],
            apikey_list2: [],
            symbol_list: [],
            select_coin: null,
            select_pair: null,
            selectedApiKeyServer: '',
            select_server: null,
            select_exchange: '',
            select_net: '',
            select_net2: '',
            select_add: '',
            withd_avail: 0,
            withd_sign: '',
            dest_exchange: '',
            dest_address: '',
            add_method: '수동입력',
            withd_min_amount: 0,
            withd_amount: 0,
            reciever_name_kr: '',
            reciever_name_en: '',
            dest_address_list: [],
            dest_address_list2: [],
            add_exchange: '',
            add_address: '',
            add_network: '',
            network_list: [],
            is_network_loading: false,
            network_data: '',
            withd_fee: '',
            account_list: [],
            account_data: [],
            is_account_loading: false,
            select_account: '',
            select_account_: '',
        }
    },
    async mounted() {
        // await this.getExchange();
    },
    methods: {
        async getExchange() {
            try {
                const res = await this.$http.get('/api/v1/info/exchange')
                for (const [key, value] of Object.entries(res.data)) {
                    this.exchange_list.push({
                        label: key,
                        value
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        async changeExchangeSelect(v){
            await this.get_apikeys(v);
            this.select_exchange = v;
        },
        async get_withd_lists(){        
            try {
                this.dest_address_list = [];
                const res = await this.$http.get('/api/v1/depositadd/')
                this.dest_address_list2 = res.data;
                for (const o of res.data) {
                    let symbol = o.token.toLowerCase();
                    let token;
                    if (symbol.includes('/')) {
                        token = symbol.split('/')[0];
                    } else {
                        token = symbol;
                    }

                    if ( token === this.select_coin.toLowerCase() ) {
                        this.dest_address_list.push({
                            label: o.name,
                            value: o.address,
                        })
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
        changeExchangeSelect2(v){
            this.dest_exchange = v;
        },
        async changeAdd(v){
            await this.get_withd_lists();

            this.add_method = v;
        },
        changeAdd2(v){
            for (const o of this.dest_address_list2) {
                if ( v === o.name ) {
                    this.add_exchange = o.exchange;
                    this.add_address = o.address;
                    if (this.select_exchange === 'binance' || this.select_exchange === 'bitget' || this.select_exchange === 'lbank') {
                        this.add_network = o.network.toUpperCase();
                    }
                }
            }
            this.dest_exchange = this.add_exchange;
            this.dest_address = this.add_address;
            if (this.select_exchange === 'binance' || this.select_exchange === 'bitget' || this.select_exchange === 'lbank') {
                this.select_net = this.add_network;
                this.withd_avail = this.network_data.find(item => item.network.toUpperCase() === this.select_net.toUpperCase());
                if (this.select_exchange === 'binance') {
                    if (this.withd_avail.withdrawEnable === true) {
                        this.withd_sign = '출금 가능';
                    } else {
                        this.withd_sign = '출금 불가';
                    }
                } else if (this.select_exchange === 'bitget') {
                    if (this.withd_avail.withdrawEnable === 'true') {
                        this.withd_sign = '출금 가능';
                    } else {
                        this.withd_sign = '출금 불가';
                    }
                } else if (this.select_exchange === 'lbank') {
                    this.withd_sign = '가능여부 정보없음';
                    this.withd_fee = this.withd_avail.withdrawFee;
                }

                this.select_net2 = `${this.select_net}(${this.withd_sign})`;
                // min amount
                this.withd_min_amount = parseFloat(this.withd_avail.withdrawMin);
            }
        },
        handleInputChange(v){
            this.withd_amount = v;
        },
        handleInputChange2(v){
            this.reciever_name_kr = v.target.value;
        },
        handleInputChange3(v){
            this.reciever_name_en = v.target.value;
        },
        async get_apikeys(v){
            this.apikey_list = []
            this.is_apikey_loading = true
            const res = await this.$http.get('/api/v1/apikeys/', {
                params:{
                    exchange: v
                    }
                })
            res.data.forEach(e => {
                this.apikey_list.push(
                    {
                        label: e.name,
                        value: e.id,
                    }
                )
            });
            this.apikey_list2 = res.data
            this.is_apikey_loading = false
        },
        handleApikeyChange(value){
            this.select_api_key_id = value
            this.is_pair_loading = true
            const _symbols = this.apikey_list2.find(apikey => apikey.id === value);
            if (_symbols) {
                this.symbol_list = _symbols.currencies;
                this.selectedApiKeyServer = _symbols.running_server;
                this.select_server = _symbols.running_server;
            } else {
                this.symbol_list = [];
                this.selectedApiKeyServer = '';
            }
            this.is_pair_loading = false
        },
        handleSelectPairChange(value){
            const v = value.split('/');
            this.select_coin = v[0].toUpperCase();
            this.select_pair = v[1].toUpperCase();

            if (this.select_exchange === 'bithumb') {
                // withd 주소 네트워크 호출
                this.GetNetwork_bithumb();
                // 최소 주문수량 조회
                this.GetMinAmount_bithumb();
            }  else if (this.select_exchange === 'binance' || this.select_exchange === 'bitget' || this.select_exchange === 'lbank') {
                // withd 주소 네트워크 호출
                this.GetNetwork_binanace();
            } else if (this.select_exchange === 'coinbase') {
                this.GetAccount_coinbase();
            }
        },
        async GetAccount_coinbase(){
            this.is_account_loading = true;
            const res = await this.$http.post(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/account`, {
                apikeyId: this.select_api_key_id,
                running_server: this.select_server,
                symbol: this.get_symbol(),
            });
            this.account_data = res.data;
            this.account_list = res.data.map(item => ({
                                            label: item.walletname,
                                            value: item.walletname
                                            }));
            this.is_account_loading = false;
        },
        handleAccountChange(value){
            this.select_account = value;
            this.withd_avail = this.account_data.find(item => item.walletname === this.select_account);
            if (this.withd_avail.withdrawEnable === true) {
                this.withd_sign = '입금 가능';
            } else {
                this.withd_sign = '입금 불가';
            }

            // 네트워크 불러오기
            this.GetNetwork_coinbase();
        },
        async GetNetwork_coinbase() {
            this.is_network_loading = true;
            // account name으로 account id 찾기
            const find_name = this.account_data.find(item => item.walletname === this.select_account);
            // console.log(find_name);
            const find_id = find_name ? find_name.accountId : "";
            this.select_account_ = find_id;
            // console.log(find_id);
            const res = await this.$http.post(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/address`, {
                apikeyId: this.select_api_key_id,
                running_server: this.select_server,
                symbol: this.get_symbol(),
                accountId: find_id
            });
            this.select_add = res.data.address;
            this.select_net2 = `${res.data.network}(${this.withd_sign})`;
            this.select_net = res.data.network;
            this.is_network_loading = false;
        },
        async GetNetwork_bithumb(){
            const res = await this.$http.get(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/network/${this.select_server}/${this.get_symbol()}`);
            this.withd_avail = res.data.withdrawal_status;
            if (this.withd_avail === 1) {
                this.withd_sign = '출금 가능';
            } else {
                this.withd_sign = '출금 불가';
            }
            this.select_net2 = `${res.data.net_type}(${this.withd_sign})`;
            this.select_net = res.data.net_type;

        },
        handleNetworkChange(value){
            this.select_net = value;
            this.withd_avail = this.network_data.find(item => item.network.toUpperCase() === this.select_net.toUpperCase());
            if (this.select_exchange === 'binance') {
                if (this.withd_avail.withdrawEnable === true) {
                    this.withd_sign = '출금 가능';
                } else {
                    this.withd_sign = '출금 불가';
                }
            } else if (this.select_exchange === 'bitget') {
                if (this.withd_avail.withdrawEnable === 'true') {
                    this.withd_sign = '출금 가능';
                } else {
                    this.withd_sign = '출금 불가';
                }
            } else if (this.select_exchange === 'lbank') {
                this.withd_sign = '가능여부 정보없음';
                this.withd_fee = this.withd_avail.withdrawFee;
            }

            this.select_net2 = `${this.select_net}(${this.withd_sign})`;
            // min amount
            this.withd_min_amount = parseFloat(this.withd_avail.withdrawMin);
        },
        async GetNetwork_binanace(){
            this.is_network_loading = true;
            const res = await this.$http.post(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/network`, {
                apikeyId: this.select_api_key_id,
                running_server: this.select_server,
                symbol: this.get_symbol(),
            });
            this.network_data = res.data;
            this.network_list = res.data.map(item => ({
                                            label: item.network,
                                            value: item.network
                                            }));
            this.is_network_loading = false;
        },
        async GetMinAmount_bithumb(){
            const res = await this.$http.get(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/withdmin/${this.select_server}/${this.get_symbol()}`);
            this.withd_min_amount = parseFloat(res.data);
        },
        get_symbol(){
            return `${this.select_coin.toUpperCase()}_${this.select_pair.toUpperCase()}`
        },

        refresh_order_tables(){
            this.$refs.balance_ref.UpdateBalance()
            this.$refs.deposit_ref.UpdateDeposit()

        },
        async submitForm() {
            if (this.isSubmitEnabled) {
                const res = await this.$http.post(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/withd`, {
                    apikeyId: this.select_api_key_id,
                    symbol: this.get_symbol(),
                    running_server: this.select_server,
                    dest_ex: this.dest_exchange,
                    dest_add: this.dest_address,
                    amount: this.withd_amount,
                    name_kr: this.reciever_name_kr,
                    name_en: this.reciever_name_en
                })

                if(res.data.status === '0000'){
                    this.$notification.open({
                            message: `출금 요청 성공`,
                            description: `${this.select_exchange} to ${this.dest_exchange} - Amount: ${this.withd_amount} ${this.select_coin}`,
                            placement: 'bottomLeft',
                        });
                } else {
                    this.$notification.open({
                            message: `출금 요청 실패`,
                            description: `${res.data.message}`,
                            placement: 'bottomLeft',
                        });
                }
            }
        },
        async submitForm_binance() {
            if (this.isSubmitEnabled_binance) {
                let params = {
                    apikeyId: this.select_api_key_id,
                    symbol: this.get_symbol(),
                    running_server: this.select_server,
                    network: this.select_net,
                    dest_add: this.dest_address,
                    amount: this.withd_amount,
                };
                if (this.select_exchange === 'lbank') {
                    params.fee = this.withd_fee;
                }

                const res = await this.$http.post(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/withd`, params);

                if (res.data.statusCode === 200) {
                    this.$notification.open({
                            message: `출금 요청 성공`,
                            description: `Withdraw - Amount: ${this.withd_amount} ${this.select_coin}`,
                            placement: 'bottomLeft',
                        });
                } else {
                    this.$notification.open({
                            message: `출금 요청 실패`,
                            description: res.data.errorMessage,
                            placement: 'bottomLeft',
                        });
                }
            }
        },
        async submitForm_coinbase() {
            if (this.isSubmitEnabled_coinbase) {
                let params = {
                    apikeyId: this.select_api_key_id,
                    symbol: this.get_symbol(),
                    running_server: this.select_server,
                    accountId: this.select_account_,
                    dest_add: this.dest_address,
                    amount: this.withd_amount,
                };
                const res = await this.$http.post(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/withd`, params);

                if (res.data.statusCode === 200) {
                    this.$notification.open({
                            message: `출금 요청 성공`,
                            description: `Withdraw - Amount: ${this.withd_amount} ${this.select_coin}`,
                            placement: 'bottomLeft',
                        });
                } else {
                    this.$notification.open({
                            message: `출금 요청 실패`,
                            description: res.data.errorMessage,
                            placement: 'bottomLeft',
                        });
                }
            }
        },
    },
    computed: {
        isSubmitEnabled() {
            return this.select_coin !== null && this.dest_exchange !== '' && this.dest_address !== '' && this.withd_amount !== 0 && this.reciever_name_kr !== '' && this.reciever_name_en !== '';
        },
        missingValues() {
            const missing = [];
            if (this.select_coin === null) {
                missing.push('토큰명');
            }
            if (this.dest_exchange === '') {
                missing.push('출금 거래소');
            }
            if (this.dest_address === '') {
                missing.push('출금 주소');
            }
            if (this.withd_amount === 0) {
                missing.push('출금 수량');
            }
            if (this.reciever_name_kr === '') {
                missing.push('수취인명(KR)');
            }
            if (this.reciever_name_en === '') {
                missing.push('수취인명(EN)');
            }
            return missing;
        },
        isSubmitEnabled_binance() {
            if (this.select_exchange === 'binance' || this.select_exchange === 'bitget') {
                return this.select_coin !== null && this.dest_address !== '' && this.withd_amount !== 0 && this.select_net !== '';
            } else if (this.select_exchange === 'lbank') {
                return this.select_coin !== null && this.dest_address !== '' && this.withd_amount !== 0 && this.select_net !== '' && this.withd_fee !== '';
            }
        },
        missingValues_binance() {
            const missing = [];
            if (this.select_coin === null) {
                missing.push('토큰명');
            }
            if (this.select_net === '') {
                missing.push('출금 네트워크');
            }
            if (this.dest_address === '') {
                missing.push('출금 주소');
            }
            if (this.withd_amount === 0) {
                missing.push('출금 수량');
            }
            if (this.select_exchange === 'lbank' && this.withd_fee === '') {
                missing.push('출금 수수료');
            }
            return missing;
        },
        isSubmitEnabled_coinbase() {
            return this.select_coin !== null && this.dest_address !== '' && this.withd_amount !== 0;
        },
        missingValues_coinbase() {
            const missing = [];
            if (this.select_coin === null) {
                missing.push('토큰명');
            }
            if (this.dest_address === '') {
                missing.push('출금 주소');
            }
            if (this.withd_amount === 0) {
                missing.push('출금 수량');
            }
            return missing;
        },
    },
}
</script>
<style lang="scss">

.add-button {
    // background-color: blue;
    background-color: rgba(0, 0, 255, 0.7);
    color: white;
    border-radius: 8px;
    border: none;
    // padding: 0 15px;
    height: 50px;
    line-height: 50px;
    margin-top: 10px;
}

</style>