<template>
  <a-form
    :form="form"
    layout="vertical"
    hide-required-mark
    @submit="handleSubmit"
  >
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-20" label="Name">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: 'Please enter user name' }],
              },
            ]"
            placeholder="Name"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-divider/>
    <span>INFO</span>
    <a-row :gutter="16" class="mx-15">
      <a-col :span="12">
        <a-form-item label="Token">
          <a-input
            v-decorator="[
              'info_token',
              {
                rules: [{ required: true, message: 'Please enter Token' }],
              },
            ]"
            placeholder=""
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Chat ID">
          <a-input
            v-decorator="[
              'info_chat_id',
              {
                rules: [{ required: true, message: 'Please enter Chat ID' }],
              },
            ]"
            placeholder=""
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-divider/>  
    
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 25px',
        background: '#fff',
        textAlign: 'right',
      }"
    >
      <a-button
        class="px-15"
        type="primary"
        size="small"
        html-type="submit"
        align="center"
      >
        CREATE
      </a-button>
    </div>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      exchange: [
        
      ]
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "apikeylist" });
  },
  async mounted() {
    
  },
  methods: {
    
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$http
            .post(`/api/v1/telegram/`, {
              name: values.name,
              info: {
                token: values.info_token,
                chat_id: values.info_chat_id
              }
            })
            .then(() => {
              this.redirect();
            });
        } else {
          console.log(err);
        }
      });
    },
    redirect() {
      // TO-DO : redirect error will solved(Completed)
      this.$router.go()
    },
  },
};
</script>

<style></style>
