var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":""},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-20",attrs:{"label":"Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [{ required: true, message: 'Please enter user name' }],
            } ]),expression:"[\n            'name',\n            {\n              rules: [{ required: true, message: 'Please enter user name' }],\n            },\n          ]"}],attrs:{"placeholder":"Name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-20",attrs:{"label":"Email Address"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                { required: true, message: 'Please enter Email Address' } ],
            } ]),expression:"[\n            'email',\n            {\n              rules: [\n                { required: true, message: 'Please enter Email Address' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Email"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-20",attrs:{"label":"Password"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [{ required: true, message: 'Please enter Password' }],
            } ]),expression:"[\n            'password',\n            {\n              rules: [{ required: true, message: 'Please enter Password' }],\n            },\n          ]"}],attrs:{"type":"password","placeholder":"********"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-20",attrs:{"label":"Repeat Password"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'repeatpassword',
            {
              rules: [
                {
                  required: true,
                  message: 'Please enter Repeat Password',
                } ],
            } ]),expression:"[\n            'repeatpassword',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please enter Repeat Password',\n                },\n              ],\n            },\n          ]"}],attrs:{"type":"password","placeholder":"********"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"User Role"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'user_role',
            {
              rules: [{ required: true, message: 'Please select User Role' }],
            } ]),expression:"[\n            'user_role',\n            {\n              rules: [{ required: true, message: 'Please select User Role' }],\n            },\n          ]"}],attrs:{"placeholder":"Please select User Role"}},[_c('a-select-option',{attrs:{"value":"admin"}},[_vm._v(" Admin User ")]),_c('a-select-option',{attrs:{"value":"user"}},[_vm._v(" Normal User ")])],1)],1)],1)],1),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 25px',
      background: '#fff',
      textAlign: 'right',
    })},[_c('a-button',{staticClass:"px-15",attrs:{"type":"primary","size":"small","html-type":"submit","align":"center"}},[_vm._v(" CREATE ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }