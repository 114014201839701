<template>
    <div>
        <a-row :gutter="24" type="flex" align="stretch">
            <!-- 필수입력 -->
            <a-col :span="24" class="mb-5">
                <a-select
                style="width: 200px; margin-right: 10px" 
                placeholder="Select Exchange" 
                @change="changeExchangeSelect"
                >
                    <a-select-option v-for="v in exchange_list" :key="v.value" :value="v.value">
                        {{ v.label }}
                    </a-select-option>
                </a-select>

                <a-select 
                style="width: 200px; margin-right: 10px" 
                placeholder="Select APIKEY" 
                :loading="is_apikey_loading" 
                @change="handleApikeyChange"
                >
                    <a-select-option v-for="v in apikey_list" :key="v.value" :value="v.value">
                        {{ v.label }}
                    </a-select-option>
                </a-select>

                <a-select 
                style="width: 200px; margin-right: 10px"
                placeholder="Select Currency" 
                showSearch
                :loading="is_pair_loading"
                @change="handleSelectPairChange"
                >
                    <a-select-option v-for="(v, i) in symbol_list" :key="i" :value="v">
                        {{v}}
                    </a-select-option>
                </a-select>

                <a-input
                style="width: 200px; margin-right: 10px"
                placeholder="Binding Server" 
                :value="selectedApiKeyServer" 
                disabled>
                </a-input>
            </a-col>

            <!-- 잔고 -->
            <template v-if="select_api_key_id && select_pair && select_coin && select_exchange">
                <a-divider />
                <balance ref="balance_ref" :coin="select_coin" :pair="select_pair" :apikeyId="select_api_key_id" :running_server="select_server" :exchange_name="select_exchange"/>
            </template>
            <a-divider />

            <!-- bithumb 입금 주소 정보 -->
            <template v-if="select_api_key_id && select_pair && select_coin && select_exchange==='bithumb'">
                <div>
                    <a-form-model
                        ref="ruleForm"
                        :model="configData"
                        :rules="rules"
                        :label-col="{span: 5}"
                        :wrapper-col="{span: 19}"
                        layout="horizontal"
                    >

                        <a-form-model-item label="토큰명" prop="name">
                            <a-input
                            style="width: 150px; margin-right: 10px;"
                            placeholder="Token Name" 
                            :value="select_coin" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item label="네트워크" prop="name">
                            <a-input
                            style="width: 150px; margin-right: 10px;"
                            placeholder="Network" 
                            :value="select_net" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item label="주소상태" prop="name">
                            <a-input
                            style="width: 150px; margin-right: 10px;"
                            placeholder="Deposit Status" 
                            :value="deposit_sign" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item label="입금주소" prop="name">
                            <a-input
                            style="width: 350px; margin-right: 10px;"
                            placeholder="Deposit Address" 
                            :value="select_add" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                    </a-form-model>
                </div>
            </template>

            <!-- binance 입금 주소 정보 -->
            <template v-if="select_api_key_id && select_pair && select_coin && (select_exchange === 'binance' || select_exchange === 'bitget' || select_exchange === 'lbank')">
                <div>
                    <a-form-model
                        ref="ruleForm"
                        :model="configData"
                        :rules="rules"
                        :label-col="{span: 5}"
                        :wrapper-col="{span: 19}"
                        layout="horizontal"
                    >

                        <a-form-model-item label="토큰명" prop="name">
                            <a-input
                            style="width: 150px; margin-right: 10px;"
                            placeholder="Token Name" 
                            :value="select_coin" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item label="네트워크" prop="name">
                            <a-select
                             style="width: 150px; margin-right: 10px"
                             placeholder="Select"
                             :loading="is_network_loading" 
                             @change="handleNetworkChange"
                            >
                                <a-select-option v-for="v in network_list" :key="v.value" :value="v.value">
                                    {{ v.label }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item label="주소상태" prop="name">
                            <a-input
                            style="width: 150px; margin-right: 10px;"
                            placeholder="Deposit Status" 
                            :value="deposit_sign" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item label="입금주소" prop="name">
                            <a-input
                            style="width: 350px; margin-right: 10px;"
                            placeholder="Deposit Address" 
                            :value="select_add" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                    </a-form-model>
                </div>
            </template>

            <!-- coinbase 입금 주소 정보 -->
            <template v-if="select_api_key_id && select_pair && select_coin && select_exchange === 'coinbase'">
                <div>
                    <a-form-model
                        ref="ruleForm"
                        :model="configData"
                        :rules="rules"
                        :label-col="{span: 5}"
                        :wrapper-col="{span: 19}"
                        layout="horizontal"
                    >

                        <a-form-model-item label="토큰명" prop="name">
                            <a-input
                            style="width: 150px; margin-right: 10px;"
                            placeholder="Token Name" 
                            :value="select_coin" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item label="Wallet" prop="name">
                            <a-select
                             style="width: 150px; margin-right: 10px"
                             placeholder="Select"
                             :loading="is_account_loading" 
                             @change="handleAccountChange"
                            >
                                <a-select-option v-for="v in account_list" :key="v.value" :value="v.value">
                                    {{ v.label }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item label="네트워크" prop="name">
                            <a-input
                            style="width: 150px; margin-right: 10px;"
                            placeholder="Deposit Status" 
                            :value="select_net" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item label="주소상태" prop="name">
                            <a-input
                            style="width: 150px; margin-right: 10px;"
                            placeholder="Deposit Status" 
                            :value="deposit_sign" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item label="입금주소" prop="name">
                            <a-input
                            style="width: 350px; margin-right: 10px;"
                            placeholder="Deposit Address" 
                            :value="select_add" 
                            disabled>
                            </a-input>
                        </a-form-model-item>

                    </a-form-model>
                </div>
            </template>
        </a-row>

        <!-- 입금 현황 테이블         -->
        <template v-if="select_api_key_id && select_pair && select_coin && select_exchange">
            <div style="margin-top: 10px;">
                <depositList ref="deposit_ref" :coin="select_coin" :pair="select_pair" :apikeyId="select_api_key_id" :running_server="select_server" :network="select_net" :exchange_name="select_exchange" :account_id="select_account_"/>
            </div>
        </template>

    </div>
</template>
<script>

import balance from './balance';
import depositList from './depositList';

export default {
        components: {
        balance,
        depositList
    },
    data() {
        return {
            input_price: '',
            input_amount: '',
            is_apikey_loading: false,
            is_pair_loading: false,
            select_api_key_id: null,
            exchange_list: [
                {
                    label: 'BITHUMB',
                    value: 'bithumb',
                },
                {
                    label: 'BINANCE',
                    value: 'binance',
                },
                {
                    label: 'BITGET',
                    value: 'bitget',
                },
                {
                    label: 'LBANK',
                    value: 'lbank',
                },
                {
                    label: 'COINBASE',
                    value: 'coinbase',
                },
            ],
            apikey_list: [],
            apikey_list2: [],
            symbol_list: [],
            select_coin: null,
            select_pair: null,
            selectedApiKeyServer: '',
            select_server: null,
            select_exchange: '',
            select_net: '',
            select_add: '',
            deposit_avail: 0,
            deposit_sign: '',
            network_list: [],
            network_data: [],
            is_network_loading: false,
            account_list: [],
            account_data: [],
            is_account_loading: false,
            select_account: '',
            select_account_: '',
        }
    },
    async mounted() {
        // await this.getExchange()
    },
    methods: {
        async getExchange() {
            try {
                const res = await this.$http.get('/api/v1/info/exchange')
                for (const [key, value] of Object.entries(res.data)) {
                    this.exchange_list.push({
                        label: key,
                        value
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        // get apikeys
        async changeExchangeSelect(v){
            await this.get_apikeys(v)
            this.select_exchange = v
        },
        async get_apikeys(v){
            this.apikey_list = []
            this.is_apikey_loading = true
            const res = await this.$http.get('/api/v1/apikeys/', {
                params:{
                    exchange: v
                    }
                })
            res.data.forEach(e => {
                this.apikey_list.push(
                    {
                        label: e.name,
                        value: e.id,
                    }
                )
            });
            this.apikey_list2 = res.data
            this.is_apikey_loading = false
        },
        handleApikeyChange(value){
            this.select_api_key_id = value;
            this.is_pair_loading = true;
            const _symbols = this.apikey_list2.find(apikey => apikey.id === value);
            if (_symbols) {
                this.symbol_list = _symbols.currencies;
                this.selectedApiKeyServer = _symbols.running_server;
                this.select_server = _symbols.running_server;
            } else {
                this.symbol_list = [];
                this.selectedApiKeyServer = '';
            }
            this.is_pair_loading = false;
        },
        handleNetworkChange(value){
            this.select_net = value;
            this.deposit_avail = this.network_data.find(item => item.network === this.select_net);
            if (this.select_exchange === 'binance') {
                if (this.deposit_avail.depositEnable === true) {
                    this.deposit_sign = '입금 가능';
                } else {
                    this.deposit_sign = '입금 불가';
                }
            } else if (this.select_exchange === 'bitget') {
                if (this.deposit_avail.depositEnable === 'true') {
                    this.deposit_sign = '입금 가능';
                } else {
                    this.deposit_sign = '입금 불가';
                }
            } else if (this.select_exchange === 'lbank') {
                this.deposit_sign = '가능여부 정보없음';
            }

            this.GetAddress_binance();
        },
        handleAccountChange(value){
            this.select_account = value;
            this.deposit_avail = this.account_data.find(item => item.walletname === this.select_account);
            if (this.deposit_avail.depositEnable === true) {
                this.deposit_sign = '입금 가능';
            } else {
                this.deposit_sign = '입금 불가';
            }

            // 네트워크 불러오기
            this.GetNetwork_coinbase();
        },
        async GetNetwork_coinbase() {
            this.is_network_loading = true;
            // account name으로 account id 찾기
            const find_name = this.account_data.find(item => item.walletname === this.select_account);
            // console.log(find_name);
            const find_id = find_name ? find_name.accountId : "";
            this.select_account_ = find_id;
            // console.log(find_id);
            const res = await this.$http.post(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/address`, {
                apikeyId: this.select_api_key_id,
                running_server: this.select_server,
                symbol: this.get_symbol(),
                accountId: find_id
            });
            this.select_add = res.data.address;
            this.select_net = res.data.network;
            this.is_network_loading = false;
        },
        handleSelectPairChange(value){
            const v = value.split('/');
            this.select_coin = v[0].toUpperCase();
            this.select_pair = v[1].toUpperCase();

            // bithumb은 네트워크 선택할 필요없기 때문에 여기서 바로 땡겨오기
            if (this.select_exchange === 'bithumb') {
                // deposit 주소 네트워크 호출
                this.GetNetwork_bithumb();
                // deposit 주소 입금주소 호출
                this.GetAddress_bithumb();
            } else if (this.select_exchange === 'binance' || this.select_exchange === 'bitget' || this.select_exchange === 'lbank') {
                // deposit 주소 네트워크 호출
                this.GetNetwork_binanace();
            } else if (this.select_exchange === 'coinbase') {
                this.GetAccount_coinbase();
            }
        },
        async GetAccount_coinbase(){
            this.is_account_loading = true;
            const res = await this.$http.post(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/account`, {
                apikeyId: this.select_api_key_id,
                running_server: this.select_server,
                symbol: this.get_symbol(),
            });
            this.account_data = res.data;
            this.account_list = res.data.map(item => ({
                                            label: item.walletname,
                                            value: item.walletname
                                            }));
            this.is_account_loading = false;
        },
        async GetAddress_binance(){
            const res = await this.$http.post(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/address`, {
                apikeyId: this.select_api_key_id,
                running_server: this.select_server,
                symbol: this.get_symbol(),
                network: this.select_net
            });
            this.select_add = res.data;
        },
        async GetNetwork_binanace(){
            this.is_network_loading = true;
            const res = await this.$http.post(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/network`, {
                apikeyId: this.select_api_key_id,
                running_server: this.select_server,
                symbol: this.get_symbol(),
            });
            this.network_data = res.data;
            this.network_list = res.data.map(item => ({
                                            label: item.network,
                                            value: item.network
                                            }));
            this.is_network_loading = false;
        },
        async GetNetwork_bithumb(){
            const res = await this.$http.get(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/network/${this.select_server}/${this.get_symbol()}`);
            this.select_net = res.data.net_type;
            this.deposit_avail = res.data.deposit_status;
            if (this.deposit_avail === 1) {
                this.deposit_sign = '입금 가능';
            } else {
                this.deposit_sign = '입금 불가';
            }
        },
        async GetAddress_bithumb(){
            const res = await this.$http.post(`/api/v1/exchange2/${this.select_exchange.toLowerCase()}/address`, {
                apikeyId: this.select_api_key_id,
                running_server: this.select_server,
                symbol: this.get_symbol()
            });
            this.select_add = res.data;
        },
        get_symbol(){
            return `${this.select_coin.toUpperCase()}_${this.select_pair.toUpperCase()}`
        },

        refresh_order_tables(){
            this.$refs.balance_ref.UpdateBalance()
            this.$refs.deposit_ref.UpdateDeposit()

        },
    },
}
</script>
<style lang="">

</style>