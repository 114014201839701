<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="orders_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    <!-- {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }} -->
                    {{ text }}
                </template>
                <template slot="is_ask" slot-scope="text">
                    {{ text === false ? "BUY" : "SELL" }}
                </template>
                <template slot="amount" slot-scope="text, record">
                    {{ Number(parseFloat(record.price) * parseFloat(record.executed_amount)).toLocaleString(undefined, { maximumFractionDigits: 5 }) }}
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
const ExchangeName = 'coinbase';
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => new Date(a.trade_time) - new Date(b.trade_time),
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'side',
                    // scopedSlots: { customRender: 'is_ask'}
                },
                
                // {
                //     title: '주문통화',
                //     dataIndex: 'order_currency',
                // },
                // {
                //     title: '결제통화',
                //     dataIndex: 'payment_currency',
                // },
                {
                    title: this.$i18n.t('trade.column_order_price'),
                    scopedSlots: { customRender: 'amount'}
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    dataIndex: 'price',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_amount'),
                    dataIndex: 'executed_amount',
                    scopedSlots: { customRender: 'comma-format'}
                },
                
                
            ],

            orders_table: []
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`
        },
        async UpdateOrders(){
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/orders`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                running_server: this.running_server
            })
            let res_ = res.data;
            if (res_.length > 0) {
                res_.forEach(item => {
                    const originTime = item.trade_time;
                    const date = new Date(originTime); 
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

                    item.timestamp = koreanTime;

                    if (item.size_in_quote === true) {
                        item.price = parseFloat(item.price);
                        item.executed_amount = parseFloat(item.size) / parseFloat(item.price);
                        // item.executed_amount = Number(item.executed_amount).toLocaleString(undefined, { maximumFractionDigits: 8 })
                    } else {
                        item.price = parseFloat(item.price);
                        item.executed_amount = parseFloat(item.size);
                        // item.executed_amount = Number(item.executed_amount).toLocaleString(undefined, { maximumFractionDigits: 8 })
                    }
                });
            }
            this.orders_table = res_; 
            // {
            //   "fills": {
            //     "entry_id": "22222-2222222-22222222",
            //     "trade_id": "1111-11111-111111",
            //     "order_id": "0000-000000-000000",
            //     "trade_time": "2021-05-31T09:59:59Z",
            //     "trade_type": "FILL",
            //     "price": "10000.00",
            //     "size": "0.001",
            //     "commission": "1.25",
            //     "product_id": "BTC-USD",
            //     "sequence_timestamp": "2021-05-31T09:58:59Z",
            //     "liquidity_indicator": "UNKNOWN_LIQUIDITY_INDICATOR",
            //     "size_in_quote": false,
            //     "user_id": "3333-333333-3333333",
            //     "side": "",
            //     "retail_portfolio_id": "4444-444444-4444444"
            //   },
            //   "cursor": "789100"
            // }
        }
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">

</style>