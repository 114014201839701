var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":""},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-20",attrs:{"label":"Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [{ required: true, message: 'Please enter user name' }],
            } ]),expression:"[\n            'name',\n            {\n              rules: [{ required: true, message: 'Please enter user name' }],\n            },\n          ]"}],attrs:{"placeholder":"Name"}})],1)],1)],1),_c('a-divider'),_c('span',[_vm._v("INFO")]),_c('a-row',{staticClass:"mx-15",attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Token"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'info_token',
            {
              rules: [{ required: true, message: 'Please enter Token' }],
            } ]),expression:"[\n            'info_token',\n            {\n              rules: [{ required: true, message: 'Please enter Token' }],\n            },\n          ]"}],attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Chat ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'info_chat_id',
            {
              rules: [{ required: true, message: 'Please enter Chat ID' }],
            } ]),expression:"[\n            'info_chat_id',\n            {\n              rules: [{ required: true, message: 'Please enter Chat ID' }],\n            },\n          ]"}],attrs:{"placeholder":""}})],1)],1)],1),_c('a-divider'),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 25px',
      background: '#fff',
      textAlign: 'right',
    })},[_c('a-button',{staticClass:"px-15",attrs:{"type":"primary","size":"small","html-type":"submit","align":"center"}},[_vm._v(" CREATE ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }