<template>
    <div>
        <a-row>
            <a-col :lg="24">
                <a-card :bordered="true">
                    <template #title>
                        <h6>{{ $t("sidebar.auto_price_v2") }}</h6>
                        
                    </template>
                    <template #extra>
                        <a-button
                            class="m-5"
                            size="small"
                            type="primary"
                            @click="createBtn"
                            >
                            <a-icon type="plus" />Create New Configration
                        </a-button>
                    </template>
                    <div class="my-15">
                        <!-- <a-select style="width: 200px" placeholder="Select APIKEY" :loading="is_apikey_loading" @change="handleSelectApikeyChange">
                            <a-select-option v-for="v in api_key_list" :key="v.id" :value="v.id">
                                {{v.name}}
                            </a-select-option>
                        </a-select>
                        <a-select 
                        style="width: 200px"
                        placeholder="Select PAIR" 
                        showSearch
                        :loading="is_pair_loading"
                        @change="handleSelectPairChange"
                        >
                            <a-select-option v-for="(v, i) in symbol_list" :key="i" :value="v">
                                {{v}}
                            </a-select-option>
                        </a-select> -->
                    </div>
                    <a-table 
                        :columns="columns" 
                        :data-source="cross_trade_setting_data"
                        :pagination="false" 
                        size="small"
                        :loading="loading">
                        <template slot="currency" slot-scope="text, record">
                            {{ record.coin | toUpperCase }}/{{ record.pair | toUpperCase }}
                        </template>
                        <template slot="isRun" slot-scope="text, record">
                            <a-switch :checked="text === 'running'" @change="isRunChange(text, record)" />
                        </template>
                        <template slot="inSideMethod" slot-scope="text, record">
                            <a-button size="small" 
                                    @click="requestOutRangeCancel(record)" 
                                    :loading="record.loading_out_range_cancel">{{ $t("trade.label_out_range_cancel") }}</a-button>
                            <!-- <a-button size="small"
                                    @click="requestRemoveDuplicate(record)"
                                    :loading="record.loading_remove_dulplicate" >{{ $t("trade.label_deduplication") }}</a-button> -->
                        </template>
                        
                        <template slot="editBtn" slot-scope="text, record">
                            <a-button type="link" @click="editBtnHandler(text, record)">
                                <a-icon type="edit" theme="twoTone" />
                            </a-button>
                            <a-divider type="vertical" />
                            <a-button type="link" @click="deleteBtnHandler(text, record)">
                                <a-icon type="delete" theme="twoTone" />
                            </a-button>
                        </template>

                    </a-table>
                </a-card>
            </a-col>
        </a-row>
        <a-drawer
            :title="isEnableEdit ? 'Modify' : 'Add'"
            :width="720"
            :visible="isEdit"
            
            @close="onClose"
            >
            <edit
                ref="editComp"
                ></edit>
        
        </a-drawer>
    </div>
</template>
<script>
import Edit from "./edit.vue"
import TradeLog from './tradeLog.vue'

export default {
    components: {
        Edit,
        TradeLog
    },

    data() {
        return {
            columns: [
                {
                    title: 'name',
                    dataIndex: 'name'
                },
                {
                    title: 'Exchange',
                    dataIndex: 'exchange'
                },
                {
                    title: 'APIKEY',
                    dataIndex: 'apikey_name'
                },
                {
                    title: 'Currency',
                    scopedSlots: { customRender: 'currency' },
                },
                {
                    title: 'Binding Server',
                    dataIndex: 'server',
                },
                {
                    title: 'run',
                    dataIndex: 'status',
                    scopedSlots: { customRender: 'isRun' },
                },
                {
                    title: '',
                    scopedSlots: { customRender: 'inSideMethod' },
                },
                {
                    title: '',
                    scopedSlots: { customRender: 'editBtn' },
                    width: 150,
                },
            ],
            cross_trade_setting_data: [
            ],
            isEdit: false,
            loading: true,
            isEnableEdit: false
        }
    },
    filters: {
        toUpperCase(value){
            return value.toUpperCase()
        }
    },
    async mounted() {
        const corssTrade = await this.getCrossTradeConfig()
        this.cross_trade_setting_data = corssTrade
        this.loading = false
    },
    methods: {
        async refreshCrossTradeConfig(){
            this.loading = true
            this.cross_trade_setting_data = await this.getCrossTradeConfig()
            this.loading = false
        },
        async getCrossTradeConfig(){
            const res = await this.$http.get('/api/v1/autotrade/fillblank_v2/')
            const result = res.data.map(v => {
                return {
                    ...v,
                    loading_out_range_cancel: false,
                    loading_remove_dulplicate: false
                }
            })
            return result
        },
        onClose(){
            this.isEdit = false
        },
        async isRunChange(text, record){
            // const data_index = this.cross_trade_setting_data.indexOf(record)
            this.loading = true
            const res = await this.$http.get(`/api/v1/autotrade/fillblank_v2/run/${record._id}`, {
                params: {
                    server: record.server
                }
            });
            record.status = res.data
            this.loading = false
        },
        editBtnHandler(text, record){
            this.isEdit = true
            this.isEnableEdit = true
            try {
                this.$refs.editComp.createEdit(record)
            } catch (e) {
                setTimeout(() => {this.$refs.editComp.createEdit(record)}, 500)
            }
        },
        async deleteBtnHandler(text, record){
            try {
                const res = await this.$http.delete(`/api/v1/autotrade/fillblank_v2/${record._id}`)
                this.refreshCrossTradeConfig()
            } catch (e) {
                console.error('삭제하지못함')
            }
        },
        createBtn(){
            this.isEdit = true
            this.isEnableEdit = false
            
            try {
                this.$refs.editComp.createAdd()
            } catch (e) {
                setTimeout(() => {this.$refs.editComp.createAdd()}, 500)
            }
        },
        async requestOutRangeCancel(record){
            record.loading_out_range_cancel = true
            try {
                const res = await this.$http.get(`/api/v1/autotrade/fillblank_v2/cancel_out_range/${record._id}`, {
                    params: {
                        server: record.server
                    } 
                });
                this.$notification.open({
                    message: `범위외취소 성공`,
                    description: `Success ${res.data.count}`,
                    placement: 'bottomLeft',
                });
            } catch (e) {
                this.$notification.error({
                    message: `범위외취소 실패..`,
                    description: e.response.data.message,
                    placement: 'bottomLeft',
                });
            }
            record.loading_out_range_cancel = false
        },
        // async requestRemoveDuplicate(record){
        //     record.loading_remove_dulplicate = true
        //     try {
        //         const res = await this.$http.get(`/api/v1/autotrade/fillblank_v2/remove_duplicate/${record._id}`, {
        //             params: {
        //                 server: record.server
        //             }
        //         });
        //         this.$notification.open({
        //             message: `호가 중복제거 성공`,
        //             description: `Success ${res.data.count}`,
        //             placement: 'bottomLeft',
        //         });
        //     } catch (e) {
        //         this.$notification.error({
        //             message: `호가 중복제거 실패..`,
        //             description: e.response.data.message,
        //             placement: 'bottomLeft',
        //         });
        //     }
        //     record.loading_remove_dulplicate = false
        // }
    },
}
</script>
<style lang="">
    
</style>