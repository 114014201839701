<template>
  <a-form-model ref="ruleForm" :model="updateData" :rules="rules" layout="vertical">
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item class="mb-20" label="Name">
          <a-input v-model="updateData.name" disabled />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item class="mb-20" label="Email Address">
          <a-input v-model="updateData.email" disabled />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item class="mb-20" label="Password" prop="password">
          <a-input
            v-model="updateData.password"
            type="password"
            placeholder="********"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item
          class="mb-20"
          label="Repeat Password"
          prop="repeatpassword"
        >
          <a-input
            v-model="updateData.repeatpassword"
            type="password"
            placeholder="********"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      
      <a-col :span="12">
        <a-form-model-item label="User Role">
          <a-select v-model="updateData.role">
            <a-select-option value="admin"> Admin </a-select-option>
            <a-select-option value="user"> Normal </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 25px',
        background: '#fff',
        textAlign: 'right',
      }"
    >
      <a-button
        class="px-15"
        :style="{ marginRight: '5px' }"
        type="primary"
        size="small"
        html-type="submit"
        @click="onSubmit"
      >
        MODIFY
      </a-button>
      <a-button class="px-15" type="danger" size="small" @click="onDelete">
        DELETE
      </a-button>
    </div>
  </a-form-model>
</template>

<script>
export default {
  props: ["updateData"],
  data() {
    return {
      user: {},
      rules: {
        password: [
          {
            required: true,
            message: "Please enter Password",
          },
        ],
        repeatpassword: [
          {
            required: true,
            message: "Please enter Repeat Password",
          },
        ],
      },
    };
  },
  watch: {
    // updateData() {
    //   this.user = this.updateData;
    // },
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log("Received values of form: ", this.updateData);
          this.$http
            .put(`/api/v1/users/${this.updateData.id}`, {
              password: this.updateData.password,
              role: this.updateData.role,
            })
            .then(() => {
              this.redirect();
            });
        } else {
          console.log(err);
        }
      });
    },
    onDelete() {
      this.$http
        .delete(`/api/v1/users/${this.updateData.id}`, {
          params: {
            id: this.updateData.id,
          },
        })
        .then(() => {
          this.redirect();
        });
    },
    redirect() {
      // TO-DO : redirect error will solved(Completed)
      // this.$router.push("/pages/UserManagement/UserList")
      this.$router.go("/pages/UserManagement/UserList");
    },
  },
};
</script>

<style></style>
