<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="orders_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    <!-- {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }} -->
                    {{ text }}

                </template>
                <template slot="is_ask" slot-scope="text">
                    {{ text === false ? "BUY" : "SELL" }}
                </template>
                <template slot="amount" slot-scope="text, record">
                    <!-- {{ Number(record.price * record.amount).toLocaleString(undefined, { maximumFractionDigits: 5 }) }} -->
                    {{ record.side === 'buy_market' ? record.amount_ : Number(record.price * record.amount).toLocaleString(undefined, { maximumFractionDigits: 5 }) }}
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
const ExchangeName = 'whitebit';
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => a.ftime - b.ftime,
                    defaultSortOrder: 'descend',
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'side',
                    // scopedSlots: { customRender: 'is_ask'}
                },
                
                // {
                //     title: '주문통화',
                //     dataIndex: 'order_currency',
                // },
                // {
                //     title: '결제통화',
                //     dataIndex: 'payment_currency',
                // },
                {
                    title: this.$i18n.t('trade.column_order_price'),
                    scopedSlots: { customRender: 'amount'}
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    dataIndex: 'price',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_amount'),
                    dataIndex: 'amount',
                    scopedSlots: { customRender: 'comma-format'}
                },
                
                
            ],

            orders_table: []
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin}_${this.pair}`
        },
        async UpdateOrders(){
            const symbol = this.getSymbol()
            // 원본 
            // const res = await this.$http.post(`/api/v1/exchange/${ExchangeName}/orders`, {
            //     apikeyId: this.apikeyId,
            //     symbol: symbol,
            // })
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/orders`, {
                apikeyId: this.apikeyId,
                symbol: symbol,
                running_server: this.running_server
            })
            let res_ = res.data.filter(data => data.status === 'filled')
            
            if (res_.length > 0) {
                res_.forEach(item => {
                    const date = new Date(item.ftime * 1000); 
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

                    item.timestamp = koreanTime;

                    if (item.type === 'market' && item.side === 'buy') {
                        item.side = 'buy_market'
                        item.amount_ = item.amount;
                        item.amount = 0;
                    } else if (item.type === 'market' && item.side === 'sell') {
                        item.side = 'sell_market'
                    }
                });
            }
            this.orders_table = res_;
            //  {
            //     "id": 519479315396,
            //     "clientOrderId": "ccxt66cfe919265f0466",
            //     "ctime": 1712828633.204322,
            //     "ftime": 1712828633.204322,
            //     "side": "buy",
            //     "amount": "0.0001",
            //     "price": "70756.63",
            //     "type": "limit",
            //     "takerFee": "0.001",
            //     "makerFee": "0.001",
            //     "dealFee": "0.007073213",
            //     "dealStock": "0.0001",
            //     "dealMoney": "7.073213",
            //     "postOnly": false,
            //     "ioc": false,
            //     "status": "filled"
            //   }
        }
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">

</style>