<template>
  <a-form
    :form="form"
    layout="vertical"
    hide-required-mark
    @submit="handleSubmit"
  >
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-20" label="Client Name">
          <a-input
            v-decorator="[
              'client_name',
              {
                rules: [{ required: true, message: 'Please enter client name' }],
              },
            ]"
            placeholder="Name"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-20" label="Name">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: 'Please enter user name' }],
              },
            ]"
            placeholder="Name"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-10" label="Exchange" :colon="false">
          <a-select default-value="Bithumb" v-decorator="[
              'exchange',
              {
                rules: [{ required: true, message: 'Please select exchange' }],
              },
            ]"
            placeholder="exchange" @change="get_symbols">
            <a-select-option v-for="(e, i) in exchange" :value="e.value" :key="i">{{ e.label }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col>
        <a-form-item class="mb-10" label="Currencies" :colon="false">
          <a-select v-decorator="[
              'currencies',
              {
                rules: [{ required: true, message: 'Please select currencies' }],
              },
            ]"
            placeholder="currencies" :loading="is_currency_loading" show-search>
            <a-select-option v-for="(e, i) in currency_list" :value="e.toLowerCase()" :key="i">{{e}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-20" label="Api Key">
          <a-input
            v-decorator="[
              'apikey',
              {
                rules: [{ required: true, message: 'Please enter API Key' }],
              },
            ]"
            placeholder=""
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-20" label="Secret">
          <a-input
            v-decorator="[
              'secret',
              {
                rules: [{ required: true, message: 'Please enter Secret Key' }],
              },
            ]"
            placeholder=""
          />
        </a-form-item>
      </a-col>
    </a-row>
    
    
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 25px',
        background: '#fff',
        textAlign: 'right',
      }"
    >
      <a-button
        class="px-15"
        type="primary"
        size="small"
        html-type="submit"
        align="center"
      >
        CREATE
      </a-button>
    </div>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      exchange: [
        
      ],
      currency_list: [

      ],
      is_currency_loading: true
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "userlist" });
  },
  async mounted() {
    await this.getExchange()
  },
  methods: {
    async get_symbols(e){
      // console.log(e)
      this.is_currency_loading = true
      const res = await this.$http.get(`/api/v1/exchange/${e}/symbols`)
      this.currency_list = res.data
      this.is_currency_loading = false
      // console.log(this.currency_list)
    },
    async getExchange() {
        try {
            const res = await this.$http.get('/api/v1/info/exchange')
            for (const [key, value] of Object.entries(res.data)) {
                this.exchange.push({
                    label: key,
                    value
                })
            }
        } catch (e) {
            console.error(e)
        }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        // console.log(values)
        if (!err) {
          // console.log("Received values of form: ", values);
          this.$http
            .post(`/api/v1/telegrambot/`, {
              client_name: values.client_name,
              name: values.name,
              exchange: values.exchange.toLowerCase(),
              api_key: values.apikey,
              secret_key: values.secret,
              currencies: values.currencies
            })
            .then(() => {
              this.redirect();
            });
        } else {
          console.log(err);
        }
      });
    },
    redirect() {
      // TO-DO : redirect error will solved(Completed)
      // this.$router.push("/pages/UserManagement/UserList")
      this.$router.go("/pages/UserManagement/UserList");
    },
  },
};
</script>

<style></style>
