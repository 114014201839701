var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.updateData,"rules":_vm.rules,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{staticClass:"mb-20",attrs:{"label":"Name"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(_vm.updateData.name),callback:function ($$v) {_vm.$set(_vm.updateData, "name", $$v)},expression:"updateData.name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{staticClass:"mb-20",attrs:{"label":"Email Address"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(_vm.updateData.email),callback:function ($$v) {_vm.$set(_vm.updateData, "email", $$v)},expression:"updateData.email"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{staticClass:"mb-20",attrs:{"label":"Password","prop":"password"}},[_c('a-input',{attrs:{"type":"password","placeholder":"********"},model:{value:(_vm.updateData.password),callback:function ($$v) {_vm.$set(_vm.updateData, "password", $$v)},expression:"updateData.password"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{staticClass:"mb-20",attrs:{"label":"Repeat Password","prop":"repeatpassword"}},[_c('a-input',{attrs:{"type":"password","placeholder":"********"},model:{value:(_vm.updateData.repeatpassword),callback:function ($$v) {_vm.$set(_vm.updateData, "repeatpassword", $$v)},expression:"updateData.repeatpassword"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{attrs:{"label":"User Role"}},[_c('a-select',{model:{value:(_vm.updateData.role),callback:function ($$v) {_vm.$set(_vm.updateData, "role", $$v)},expression:"updateData.role"}},[_c('a-select-option',{attrs:{"value":"admin"}},[_vm._v(" Admin ")]),_c('a-select-option',{attrs:{"value":"user"}},[_vm._v(" Normal ")])],1)],1)],1)],1),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 25px',
      background: '#fff',
      textAlign: 'right',
    })},[_c('a-button',{staticClass:"px-15",style:({ marginRight: '5px' }),attrs:{"type":"primary","size":"small","html-type":"submit"},on:{"click":_vm.onSubmit}},[_vm._v(" MODIFY ")]),_c('a-button',{staticClass:"px-15",attrs:{"type":"danger","size":"small"},on:{"click":_vm.onDelete}},[_vm._v(" DELETE ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }