<template>
    <div>
        <a-button @click="CancelAllOrders()" :loading="loading_cancel_all_orders">{{ $t("trade.label_cancel_all_orders") }}</a-button>
        <a-button @click="CancelAllOrders('buy')" :loading="loading_cancel_all_orders">{{ $t("trade.label_cancel_buy_orders") }}</a-button>
        <a-button @click="CancelAllOrders('sell')" :loading="loading_cancel_all_orders">{{ $t("trade.label_cancel_sell_orders") }}</a-button>
        <a-button @click="UpdateOrders()" class="ml-5">
            <a-icon type="reload" theme="outlined" />
                Refresh
        </a-button>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns"
                     :row-key="record => record.id"
                     :data-source="orders_table" 
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }}
                </template>
                <template slot="cancel-order" slot-scope="text, record">
                    <a-button danger class="m-0" @click="clickCancelOrderButtonHandler(record)" :loading="cancel_order_list.includes(record.id)">{{ $t("trade.label_cancel") }}</a-button>
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
const ExchangeName = 'huobi';

export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {
        
    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => a.timestamp - b.timestamp,
                    defaultSortOrder: 'descend',
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'side',
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    sorter: (a, b) => a.price - b.price,
                    dataIndex: 'price',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_amount'),
                    dataIndex: 'amount',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_remaining'),
                    dataIndex: 'remaining',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_cancelorder'),
                    scopedSlots: { customRender: 'cancel-order'}
                }
                
                
            ],

            orders_table: [],
            cancel_order_list: [],
            loading_cancel_all_orders: false
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin}/${this.pair}`
        },
        async CancelAllOrders(type='all'){
            this.loading_cancel_all_orders = true
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/cancelallorders`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                type,
                running_server: this.running_server
            })
            // {
            //     "status": "ok",
            //     "data": {
            //         "success-count": "1",
            //         "failed-count": "0",
            //         "next-id": "-1"
            //     }
            // }
            this.$notification.open({
                message: `Cancel Order`,
                description: `Success : ${res.data['total_order_count']}`,
                placement: 'bottomLeft',
            });
            this.loading_cancel_all_orders = false
        },
        async UpdateOrders(){
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/openorders`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                running_server: this.running_server
            })
            this.orders_table = res.data
            // amount: 100
            // cost: 0
            // datetime: "2022-07-21T13:22:11.423Z"
            // fees: []
            // filled: 0
            // id: "ebd47b4a-a52f-4c51-9261-870a9890ba10"
            // info: {symbol: "crfi_usdt", amount: "1E+2", create_time: "1658409731423", price: "0.008559", avg_price: "0",…}
            // price: 0.008559
            // remaining: 100
            // side: "buy"
            // status: "open"
            // symbol: "CRFI/USDT"
            // timestamp: 1658409731423
            // trades: []
        },
        async clickCancelOrderButtonHandler(record){
            this.cancel_order_list.push(record.id)
            // record.order_id
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/cancelorder`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                order_id: record.id,
                running_server: this.running_server
            })
            // {
            //     "info": {
            //         "status": "ok",
            //         "data": "642022285188807"
            //     },
            //     "id": "642022285188807",
            //     "symbol": "KLAY/USDT",
            //     "status": "canceled",
            //     "trades": [],
            //     "fees": []
            // }
            this.$notification.open({
					message: `Cancel Order`,
					description: `${res.data.status} - ${res.data.info.status}`,
					placement: 'bottomLeft',
				});
            this.UpdateOrders()
        }
        
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (5 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">


</style>