<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="balance_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true"
                     :loading="loading">
                
            </a-table>
		</a-card>
    </div>
</template>
<script>
import axios from 'axios';

const Exchange = 'whitebit'
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: 'Pair',
                    dataIndex: 'currency',
                },
                {
                    title: 'Free',
                    dataIndex: 'free',
                },
                {
                    title: 'Used',
                    dataIndex: 'used',
                },
                {
                    title: 'Total',
                    dataIndex: 'total',
                },
                
                
            ],
            // amount: "1312800"
            // fee: "3,282"
            // fee_currency: "KRW"
            // order_balance: "0.00001006"
            // order_currency: "ETH"
            // payment_balance: "4445860"
            // payment_currency: "KRW"
            // price: "4000000"
            // search: "매도 완료"
            // transfer_date: "1649490057830038"
            // units: "0.3282"

            balance_table: [
            ],
            loading: true
        }
    },
    computed: {
        ConvertCoin() {
            return this.coin.toUpperCase()
        },
        ConvertPair() {
            return this.pair.toUpperCase()
        },
    },
    methods: {
        async UpdateBalance(){
            // 원본
            // const res = await this.$http.post(`/api/v1/exchange/${Exchange}/balance`, {
            //     apikeyId: this.apikeyId
            // })

            // 웹 통해서 다이렉트 하면 안됨
            const res = await this.$http.post(`/api/v1/exchange2/${Exchange}/balance`, {
                apikeyId: this.apikeyId,
                running_server: this.running_server
            })

            let balance_coin = res.data[this.ConvertCoin]
            let balance_pair = res.data[this.ConvertPair]
            balance_coin['total'] = parseFloat(balance_coin.available) + parseFloat(balance_coin.freeze)
            balance_pair['total'] = parseFloat(balance_pair.available) + parseFloat(balance_pair.freeze)

            this.balance_table[0].currency = this.coin
            if (balance_coin !== undefined) {
                this.balance_table[0].free = this.setPriceFormat(parseFloat(balance_coin.available))
                this.balance_table[0].used = this.setPriceFormat(parseFloat(balance_coin.freeze))
                this.balance_table[0].total = this.setPriceFormat(parseFloat(balance_coin.total))
            } else {
                this.balance_table[0].free = 0
                this.balance_table[0].used = 0
                this.balance_table[0].total = 0
            }
            
            this.balance_table[1].currency = this.pair
            if (balance_pair !== undefined) {
                this.balance_table[1].free = this.setPriceFormat(parseFloat(balance_pair.available))
                this.balance_table[1].used = this.setPriceFormat(parseFloat(balance_pair.freeze))
                this.balance_table[1].total = this.setPriceFormat(parseFloat(balance_pair.total))
            } else {
                this.balance_table[1].free = 0
                this.balance_table[1].used = 0
                this.balance_table[1].total = 0
            }
        },
        init_balance(){
            this.balance_table = []
            this.balance_table.push({
                currency: this.coin,
                free: 0,
                used: 0,
                total: 0
            })
            this.balance_table.push({
                currency: this.pair,
                free: 0,
                used: 0,
                total: 0
            })
        },
        setPriceFormat(v){
            return Number(v).toLocaleString(undefined, { maximumFractionDigits: 5 })
        }
        
    },
    async mounted() {
        this.init_balance()
        await this.UpdateBalance()
        this.loading = false
        this.updateInterval = setInterval(() => {
            this.UpdateBalance()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        pair(v){
            this.loading = true
            this.UpdateBalance()
            this.loading = false
        }
    },

}
</script>

<style lang="scss">

</style>