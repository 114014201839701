<template>
    <div>
        <span>Base Price {{ stand_price }}</span>
        <a-row type="flex" justify="end">
            <a-col class="mr-15">
                <a-button @click="changeData">Shuffle</a-button>
            </a-col>
            <a-col>
                <a-button type="primary" @click="okHandler">OK</a-button>
            </a-col>
        </a-row>
        <a-card>
            <canvas ref="chart" class="chart-line" :style="{'height': height + 'px'}"></canvas>
        </a-card>
    </div>
</template>
<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    
    data() {
        return {
            chart: null,
            height: 500,
            schedule_time: 0,
            change_cycle: 0,
            change_percent: 0,
            trend: 0,
            chart_data: {
                labels: [],
                datasets: [{
                    label: "",
                    tension: 0.4,
                    borderWidth: 0,
                    pointRadius: 0,
                    borderColor: "#1890FF",
                    borderWidth: 3,
                    data: [],
                    maxBarThickness: 6

                }],
            },
            dataset: {
                label: "",
                tension: 0.4,
                borderWidth: 0,
                pointRadius: 0,
                borderColor: "#1890FF",
                borderWidth: 3,
                data: [],
                maxBarThickness: 6
            },
            scheduler_table: [],
            stand_price: 0,
            min_price: 0,
            max_price: 0
        };
    },
    mounted () {
        this.initChart()
    },
    watch: {
        // chart_data() {
        //     initChart
        // }
    },
    methods: {
        okHandler() {
            this.$emit('result', this.scheduler_table)
        },
        setProps(schedule_time, change_cycle, change_percent, trend, stand_price, scheduler_table, min_price, max_price) {
            this.schedule_time = schedule_time
            this.change_cycle = change_cycle
            this.change_percent = change_percent
            this.trend = trend
            this.stand_price = stand_price
            this.min_price = min_price
            this.max_price = max_price
            if (scheduler_table) {
                this.scheduler_table = scheduler_table
                this.editmode()
            }
        },
        editmode(){
            const y_count = parseInt(this.schedule_time / this.change_cycle)
            const y_label = this.calcYLabel(this.change_percent, y_count)
            const x_bool_table = this.calcXData(this.scheduler_table, this.change_percent)
            this.chart.data.labels = y_label
            this.chart.data.datasets[0].data = x_bool_table
            this.chart.update()
        },
        changeData() {
            const x_count = parseInt(this.schedule_time / this.change_cycle) // x축 길이 측정
            this.scheduler_table = this.calcTend(this.trend, x_count, this.change_percent, this.stand_price, this.min_price, this.max_price) // 추세 계산
            const y_label = this.calcYLabel(this.change_percent, x_count)
            const x_bool_table = this.calcXData(this.scheduler_table, this.change_percent)
            this.chart.data.labels = y_label
            this.chart.data.datasets[0].data = x_bool_table
            this.chart.update()


        },
        calcYLabel(change_percent, y_count) {
            const result = []
            let _last = 0
            for (let y = 0 ; y < y_count ; y ++) {
                _last = _last + change_percent
                // _last = parseFloat(_last.toFixed(4))
                result.push(_last)
            }
            return result
        },
        calcXData(bool_table, change_percent) {
            const result = []
            let last = this.stand_price
            for(let b in bool_table) {
                // if (b > 0) {
                //     last = result[b - 1]
                // }
                last = parseFloat(last)
                if(bool_table[b] === 0) {
                    // down
                    last = last - parseFloat(last * (change_percent/100))
                } else {
                    // up
                    last = last + parseFloat(last * (change_percent/100))
                }
                last = parseFloat(last.toFixed(5))
                result.push(last)
            }
            return result
        },
        calcTend(trend, x_count, change_percent, start_price, t_min_price, t_max_price) {
            // up: 1 down: 0
            const result = []
            const up_count = parseInt(x_count * (trend / 100))
            const down_count = x_count - up_count
            let last = parseFloat(start_price)
            const min_price = parseFloat(t_min_price)
            const max_price = parseFloat(t_max_price)
            
            for (let up = 0 ; up < up_count ; up++) {
                result.push(1)
            }
            for (let down = 0 ; down < down_count ; down++) {
                result.push(0)
            }
            // shuffle
            result.sort(() => Math.random() - 0.5);
            result.sort(() => Math.random() - 0.5);

            // 교정
            for(let b in result) {
                last = parseFloat(last)
                if(result[b] === 0) {
                    // down
                    const _t = last - parseFloat(last * (change_percent/100))
                    if (_t <= min_price) {
                        last = last + parseFloat(last * (change_percent/100))
                        result[b] = 1
                        continue
                    }
                    last = _t
                } else {
                    // up
                    const _t = last + parseFloat(last * (change_percent/100))
                    if (_t >= max_price) {
                        last = last - parseFloat(last * (change_percent/100))
                        result[b] = 0
                        continue
                    }
                    last = _t
                }
            }
            // console.log(result)
            return result
        },
        initChart() {
            let ctx = this.$refs.chart.getContext("2d");

			this.chart = new Chart(ctx, {
				type: "line",
				data: this.chart_data,
     			options: {
					layout: {
						padding: {
							top: 30,
							right: 15,
							left: 10,
							bottom: 5,
						},
					},
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false,
						},
					},
					tooltips: {
						enabled: true,
						mode: "index",
						intersect: false,
					},
					scales: {
						y: {
							grid: {
								display: true,
								color: "rgba(0, 0, 0, .2)",
								zeroLineColor: "#000000",
								borderDash: [6],
								borderDashOffset: [6],
							},
							ticks: {
								suggestedMin: 0,
								suggestedMax: 1000,
								display: true,
								color: "#8C8C8C",
								font: {
									size: 14,
									lineHeight: 1.8,
									weight: '600',
									family: "Open Sans",
								},
							},
						},
						x: {
							grid: {
								display: false,
							},
							ticks: {
								display: true,
								color: "#8C8C8C",
								font: {
									size: 14,
									lineHeight: 1.5,
									weight: '600',
									family: "Open Sans",
								},
							},
						},
					},
				}
			});
        }
    },
    beforeDestroy: function () {
        if(!this.chart){
            this.chart.destroy() ;
        }
    },
	
}
</script>

<style lang="">
    
</style>