<template>
    <div>
        <!-- choice apikey, exchange, coin, pair -->
        <a-form-model
          ref="ruleForm"
          :model="configData"
          :rules="rules"
          :label-col="{span: 5}"
          :wrapper-col="{span: 19}"
          
          layout="horizontal"
        >
            <a-form-model-item ref="name" label="Name" prop="name">
                <a-input
                    v-model="configData.name"
                    size="small"
                    style="width: 60%"
                />
            </a-form-model-item>

            <a-form-model-item label="Exchange" prop="name">
                <a-select
                    v-model="configData.exchange"
                    size="small"
                    style="width: 40%"
                    @change="changeExchangeSelect"
                >
                    <a-select-option v-for="v in exchange_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <template v-if="configData.exchange">
                <a-form-model-item label="Coin" prop="name">
                    <a-select
                        v-model="configData.coin"
                        size="small"
                        style="width: 40%"
                        :loading="is_symbols_loading"
                        showSearch
                    >
                        <a-select-option v-for="v in symbols_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Pair" prop="name">
                    <a-select
                        v-model="configData.pair"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in pair_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-divider/>
                <a-form-model-item label="Running Server" prop="name">
                    <a-select
                        v-model="configData.running_server"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in server_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Port" prop="name">
                    <a-select
                        v-model="configData.port"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in port_options" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <!-- Client Config Start -->
                <template v-if="configData.exchange && configData.coin && configData.pair && configData.running_server === 'master'">
                    <div v-for="(item, index) in apikeyInputs" :key="item.id">
                        <a-divider />
                        <a-form-model-item :label="'API Key ' + (index + 1)" :prop="'apikey_' + item.id">
                            <a-select
                                v-model="item.apikey"
                                size="small"
                                style="width: 40%"
                                :loading="is_apikey_loading"
                                @change="updateLpServer(index)"
                            >
                                <a-select-option v-for="v in apikey_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                            </a-select>
                            <a-button type="error" shape="circle" size="small" style="margin-left: 40px;" @click="deleteApiKeyInput(index)" class="mx-15">-</a-button>
                        </a-form-model-item>

                        <!-- <a-form-model-item :label="$t('multi_crosstrade.lp_server')" :prop="'lp_server_' + item.id">
                            <a-select
                                v-model="item.lp_server"
                                size="small"
                                style="width: 40%"
                            >
                                <a-select-option v-for="v in lp_server_options" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                            </a-select>
                        </a-form-model-item> -->

                        <a-form-model-item :label="$t('multi_crosstrade.lp_server')" :prop="'lp_server_' + item.id">
                            <a-input
                                v-model="item.lp_server"
                                size="small"
                                style="width: 40%"
                                disabled
                                placeholder="Binding Server"
                            />
                        </a-form-model-item> 
                        
                        <a-form-model-item :label="$t('multi_crosstrade.buy_sell')" :prop="'buy_sell_' + item.id">
                            <a-select
                                v-model="item.buy_sell"
                                size="small"
                                style="width: 40%"
                            >
                                <a-select-option v-for="v in buy_sell_options" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item :label="$t('multi_crosstrade.order_buy_amount_out_limit')" :prop="'order_buy_amount_out_limit_' + item.id">
                            <a-input-number
                                v-model="item.order_buy_amount_out_limit"
                                size="small"
                                style="width: 40%"
                                :formatter="value => {
                                    const parts = value.toString().split('.');
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    return parts.join('.');
                                }"
                                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            />
                        </a-form-model-item>

                        <a-form-model-item :label="$t('multi_crosstrade.order_sell_amount_out_limit')" :prop="'order_sell_amount_out_limit_' + item.id">
                            <a-input-number
                                v-model="item.order_sell_amount_out_limit"
                                size="small"
                                style="width: 40%"
                                :formatter="value => {
                                    const parts = value.toString().split('.');
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    return parts.join('.');
                                }"
                                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            />
                        </a-form-model-item>

                        <a-form-model-item :label="$t('multi_crosstrade.coin_protect')" :prop="'coin_protect_' + item.id">
                            <a-input-number
                                v-model="item.coinProtect"
                                size="small"
                                style="width: 40%"
                                :formatter="value => {
                                    const parts = value.toString().split('.');
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    return parts.join('.');
                                }"
                                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            />
                        </a-form-model-item>

                        <a-form-model-item :label="$t('multi_crosstrade.pair_protect')" :prop="'pair_protect_' + item.id">
                            <a-input-number
                                v-model="item.pairProtect"
                                size="small"
                                style="width: 40%"
                                :formatter="value => {
                                    const parts = value.toString().split('.');
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    return parts.join('.');
                                }"
                                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            />
                        </a-form-model-item>
                    </div>
                    <a-button type="primary" shape="circle" size="small" style="margin-left: 40px;" @click="addApiKeyInput">+</a-button>
                </template>
                <!-- Client Config End -->  

            </template>
        </a-form-model>

        <a-divider/>
        <template v-if="configData.exchange && configData.coin && configData.pair && configData.running_server === 'master'">
            <!-- config -->
            <a-form-model
                :model="configDetailData"
                :rules="rules"
                :label-col="{span: 5}"
                :wrapper-col="{span: 19}"
                layout="horizontal">

                <template v-for="form in config_form">
                    <a-form-model-item :label="form.label" prop="name" :key="form.value">
                        <template v-if="form.type === 'number'">
                            <a-input-number
                                v-model="configDetailData[form.value]"
                                size="small"
                                style="width: 40%"
                                :min="0"
                                :max="10"
                                :formatter="value => `${value}`" 
                                :parser="value => value" 
                                v-if="form.filter === 'percent'"
                            />
                            <a-input-number
                                v-model="configDetailData[form.value]"
                                size="small"
                                style="width: 40%"
                                :formatter="value => {
                                    const parts = value.toString().split('.');
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    return parts.join('.');
                                }"
                                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                                v-else
                            />
                        </template>
                        <template v-if="form.type === 'boolean'">
                            <a-switch
                                v-model="configDetailData[form.value]"
                            />
                        </template>
                    </a-form-model-item>
                </template>
                <a-divider/>
                <a-form-model-item :label="$t('multi_crosstrade.gap_method')" prop="name">
                    <a-select
                        v-model="gap_method"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in gap_method_options" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="value" prop="name">
                    <a-input-number
                            v-model="gap_method_value"
                            size="small"
                            style="width: 40%"
                            :formatter="value => {
                                const parts = value.toString().split('.');
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                return parts.join('.');
                            }"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        />
                </a-form-model-item>
                <a-form-model-item :label="$t('multi_crosstrade.base_price')" prop="name">
                    <a-select
                        v-model="base_price"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in base_price_options" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item :label="$t('multi_crosstrade.after_price')" prop="name">
                    <a-select
                        v-model="after_price"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in base_price_options2" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-divider/>
                <template>
                    <div>
                        <a-form-model-item :label="$t('multi_crosstrade.amount_min')" prop="name">
                            <a-input 
                                v-model="newItem1" 
                                size="small"
                                style="width: 40%"
                                @input="handleInput"
                                placeholder="Amount Min">
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item :label="$t('multi_crosstrade.amount_max')" prop="name">
                            <a-input 
                                v-model="newItem2" 
                                size="small"
                                style="width: 40%"
                                @input="handleInput2"
                                placeholder="Amount Max">
                            </a-input>
                        </a-form-model-item>

                        <a-button type="primary" shape="circle" size="small" style="margin-left: 40px;" @click="addItem" :disabled="!areBothItemsEntered">+</a-button>
                        <div v-for="(pairIndex, index) in Math.floor(items.length / 2)" :key="pairIndex">
                            <div class="item-content">
                                <div style="margin-left: 40px; margin-top: 10px;">Amount {{index+1}} : {{ items[index * 2] }} ~ {{ items[index * 2 + 1] }}</div>
                                <a-button type="primary" shape="circle" size="small" @click="deleteItem(index)">-</a-button>
                            </div>
                        </div>
                    </div>
                </template>
                
                <a-divider/>
                <a-form-model-item :label="$t('multi_crosstrade.brick_break')" prop="name">
                    <a-switch
                        v-model="brick_break_"
                    />
                </a-form-model-item>
                <a-form-model-item :label="$t('multi_crosstrade.brick_break_amount')" prop="name">
                    <a-input
                            v-model="brick_break_amount_"
                            size="small"
                            style="width: 40%"
                            @input="handleInput3">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item :label="$t('multi_crosstrade.brick_break_side')" prop="name">
                    <a-select
                        v-model="brick_break_side_"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in bb_side_options" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-divider/>
                <a-form-model-item label="Delay Time" prop="name">
                    <a-input-number
                            v-model="trade_delay_time_"
                            size="small"
                            style="width: 40%"
                            placeholder="Seconds"
                        />
                </a-form-model-item>
                <a-divider/>

            </a-form-model>
        </template>

        <a-form-model
            :model="configDetailData"
            :rules="rules"
            :label-col="{span: 5}"
            :wrapper-col="{span: 19}"
            layout="horizontal">

            <a-form-model-item :label="$t('multi_crosstrade.telegram1')" prop="name">
                <a-select
                    v-model="select_telegram_id"
                    size="small"
                    style="width: 40%"
                >
                    <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item :label="$t('multi_crosstrade.telegram2')" prop="name">
                <a-select
                    v-model="select_telegram_id2"
                    size="small"
                    style="width: 40%"
                >
                    <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="Select On/Off" prop="name">
                <a-switch
                    v-model="telegram_select_active"
                ></a-switch>
            </a-form-model-item>
            <a-form-model-item v-if="configData.running_server === 'master'" label="Trade Log" prop="name">
                <a-switch
                    v-model="trade_log_active"
                ></a-switch>
            </a-form-model-item>
        </a-form-model>

        <a-row>
            <a-col>
                <span>{{ error_log }}</span>
            </a-col>
            <a-col>
                <a-button class="m-15" @click="emitBtn">{{ is_edit? 'Modify' : 'Add' }}</a-button>
            </a-col>
        </a-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            configData: {},
            configDetailData: {},
            rules: {},
            exchange_list:
            [
                
            ],
            coin_list:
            [
                {
                    label: 'klaytn',
                    value: 'klay'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            gap_method_options: [
                {
                    label: 'Tick',
                    value: 'tick',
                },
                {
                    label: 'Percent',
                    value: 'percent',
                },
            ],
            base_price_options: [
                {
                    label: 'Ask',
                    value: 'ask',
                },
                {
                    label: 'Bid',
                    value: 'bid',
                },
                {
                    label: 'Last',
                    value: 'last',
                },
            ],
            base_price_options2: [
                {
                    label: 'Ask',
                    value: 'ask',
                },
                {
                    label: 'Bid',
                    value: 'bid',
                },
                {
                    label: 'Last',
                    value: 'last',
                },
            ],
            buy_sell_options: [
                {
                    label: 'Disable',
                    value: 'disable',
                },
                {
                    label: 'Buy',
                    value: 'buy',
                },
                {
                    label: 'Sell',
                    value: 'sell',
                },
            ],
            lp_server_options: [
                {
                    label: 'Disable',
                    value: 'disable',
                },
                {
                    label: 'LP1',
                    value: 'lp1',
                },
                {
                    label: 'LP2',
                    value: 'lp2',
                },
                {
                    label: 'LP3',
                    value: 'lp3',
                },
                {
                    label: 'LP4',
                    value: 'lp4',
                },
                {
                    label: 'LP5',
                    value: 'lp5',
                },
                {
                    label: 'LP6',
                    value: 'lp6',
                },
            ],
            server_list: [
                {
                    label: 'Master(Dev)',
                    value: 'master',
                },
                {
                    label: 'LP1',
                    value: 'lp1',
                },
                {
                    label: 'LP2',
                    value: 'lp2',
                },
                {
                    label: 'LP3',
                    value: 'lp3',
                },
                {
                    label: 'LP4',
                    value: 'lp4',
                },
                {
                    label: 'LP5',
                    value: 'lp5',
                },
                {
                    label: 'LP6',
                    value: 'lp6',
                },
            ],
            pair_list:
            [
                {
                    label: 'KRW',
                    value: 'krw'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            config_form: [
                {
                    label: this.$i18n.t("multi_crosstrade.speed_min"),
                    value: 'speed_min',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("multi_crosstrade.speed_max"),
                    value: 'speed_max',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("multi_crosstrade.trend"),
                    value: 'trend',
                    filter: 'percent',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("multi_crosstrade.cross_balance"),
                    value: 'cross_balance',
                    filter: 'percent',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("multi_crosstrade.gap_amount"),
                    value: 'gap_amount',
                    type: 'number',
                    required: true
                },
            ],
            port_options: [
                {
                    label: 'Disable',
                    value: 'disable',
                },
                {
                    label: '1',
                    value: '1',
                },
                {
                    label: '2',
                    value: '2',
                },
                {
                    label: '3',
                    value: '3',
                },
                {
                    label: '4',
                    value: '4',
                },
                                {
                    label: '5',
                    value: '5',
                },
                                {
                    label: '6',
                    value: '6',
                },
            ],
            bb_side_options: [
                {
                    label: 'Both',
                    value: 'both',
                },
                {
                    label: '매도호가',
                    value: 'ask',
                },
                {
                    label: '매수호가',
                    value: 'bid',
                },
            ],
            telegram_list: [],
            symbols_list: [],
            apikey_list: [],
            select_telegram_id: null,
            select_telegram_id2: null,
            is_apikey_loading: true,
            is_symbols_loading: true,
            error_log: '',
            is_edit: false,
            gap_method: 'tick',
            gap_method_value: 0,
            base_price: 'last',
            after_price: 'last',
            newItem1: '',
            newItem2: '', 
            items: [],
            brick_break_: false,
            brick_break_amount_: '',
            brick_break_side_: 'both',
            trade_delay_time_: 0,
            // Client Config ----------------
            apikeyInputs: [{ id: 0, 
                             apikey: '', 
                             lp_server: 'disable',
                             buy_sell: 'disable',
                             order_buy_amount_out_limit: null, 
                             order_sell_amount_out_limit: null, 
                             coinProtect: null, 
                             pairProtect: null 
                             }], 
            // apikeyInputs: [], 
            apikeyCounter: 1, // Counter for generating unique IDs
            // ------------------------------
            telegram_select_active: false,
            trade_log_active: false,
            apikey_list2: [],
        }
    },
    async mounted() {
        await this.getExchange()
        await this.getTelegram()
    },
    methods: {
        // // 가짜 apikey 생성
        // generateMongoObjectId() {
        //     const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        //     return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
        //         return (Math.random() * 16 | 0).toString(16);
        //     }).toLowerCase();
        // },

        // Client Config ------------------------
        addApiKeyInput() {
            this.apikeyInputs.push({ id: this.apikeyCounter++, 
                                     apikey: '',
                                     but_sell: 'disable',
                                     lp_server: 'disable',
                                     order_buy_amount_out_limit: null, 
                                     order_sell_amount_out_limit: null, 
                                     coinProtect: null, 
                                     pairProtect: null 
                                     }); 
        },
        deleteApiKeyInput(index) {
            if (this.apikeyInputs.length > 1) {
                this.apikeyInputs.splice(index, 1); 
            }
        },
        // Client Config ------------------------

        async getTelegram() {
            try {
                const res = await this.$http.get('/api/v1/telegram/')
                for (const o of res.data) {
                    this.telegram_list.push({
                        label: o.name,
                        value: o.id
                    })
                }
                // console.log(this.telegram_list)
            } catch (e) {
                console.error(e)
            }
        },
        async getExchange() {
            try {
                const res = await this.$http.get('/api/v1/info/exchange')
                for (const [key, value] of Object.entries(res.data)) {
                    this.exchange_list.push({
                        label: key,
                        value
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        // get apikeys
        async changeExchangeSelect(v){
            
            await this.get_apikeys(v)
            await this.get_symbols(v)
        },
        async get_apikeys(v){
            this.apikey_list = []
            this.is_apikey_loading = true
            const res = await this.$http.get('/api/v1/apikeys/', {
                params:{
                    exchange: v
                    }
                })
            res.data.forEach(e => {
                this.apikey_list.push(
                    {
                        label: e.name,
                        value: e.id
                    }
                )
            });
            this.apikey_list2 = res.data
            this.is_apikey_loading = false
        },
        updateLpServer(index) {
            const selectedApiKey = this.apikeyInputs[index].apikey;
            const selectedApiKey2 = this.apikey_list2.find(apikey => apikey.id === selectedApiKey);
            if (selectedApiKey2 && selectedApiKey2.running_server) {
                this.$set(this.apikeyInputs[index], 'lp_server', selectedApiKey2.running_server);
            }
        },
        async get_symbols(exchange){
            this.symbols_list = []
            this.is_symbols_loading = true
            const res = await this.$http.get(`/api/v1/exchange/${exchange}/symbols`)
            let symbol_split = ''
            if (res.data[0].includes('/')) {
                symbol_split = '/'
            } else if (res.data[0].includes('_')) {
                symbol_split = '_'
            }
            if (symbol_split !== '') {
                this.pair_list = []
                res.data.forEach(e => {
                    const _symbol = e.split(symbol_split)
                    if (!this.symbols_list.find(s => s.label === _symbol[0])) {
                        this.symbols_list.push(
                            {
                                label: _symbol[0],
                                value: _symbol[0].toLowerCase()
                            }
                        )
                    }
                    if (!this.pair_list.find(s => s.label === _symbol[1])) {
                        this.pair_list.push({
                            label: _symbol[1],
                            value: _symbol[1].toLowerCase()
                        })
                    }

                });
            } else {
                res.data.forEach(e => {
                    this.symbols_list.push(
                        {
                            label: e,
                            value: e.toLowerCase()
                        }
                    )
                });
            }
            this.is_symbols_loading = false
            return res.data
        },
        async emitBtn(){
            await this.registCrossTrade()
        },
        async registCrossTrade(){
            const crossTrade_multi = {
                ...this.configData,
                telegram_id: this.select_telegram_id,
                telegram_id2: this.select_telegram_id2,
                telegram_select: this.telegram_select_active,
                trade_log: this.trade_log_active,
                config: {...this.configDetailData, gap: {
                    method_type: this.gap_method,
                    range: this.gap_method_value,
                    start_price: this.base_price,
                    after_price_: this.after_price,
                    amounts: this.items,
                    apikeys: this.apikeyInputs,
                    brick_break: this.brick_break_,
                    brick_break_amount: this.brick_break_amount_,
                    brick_break_side: this.brick_break_side_,
                    trade_delay_time: this.trade_delay_time_
                }},
            }
            try{
                if (this.is_edit === true){
                    const res = await this.$http.put(`/api/v1/autotrade/crosstrade_multi/${this.configData._id}`, crossTrade_multi)
                    this.error_log = ''
                } else {
                    const res = await this.$http.post('/api/v1/autotrade/crosstrade_multi/', crossTrade_multi)
                    this.error_log = ''
                }
                this.$router.go();
            } catch (e){
                console.error(e.response)
                this.error_log = e.response.data.message
            }
        },
        handleInput(event) {
            let value = event.target.value;
            // 정수와 소수 부분을 나누기
            let parts = value.split('.');
            // 정수 부분에 쉼표 추가
            let integerPart = parts[0].replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // 소수 부분은 쉼표 없이 유지
            let decimalPart = parts[1] ? parts[1].replace(/[^\d]/g, '') : '';
            // 소수점이 입력된 경우 처리
            if (value.includes('.')) {
                event.target.value = `${integerPart}.${decimalPart}`;
            } else {
                event.target.value = integerPart;
            }
            this.newItem1 = event.target.value;
        },
        handleInput2(event) {
            let value = event.target.value;
            // 정수와 소수 부분을 나누기
            let parts = value.split('.');
            // 정수 부분에 쉼표 추가
            let integerPart = parts[0].replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // 소수 부분은 쉼표 없이 유지
            let decimalPart = parts[1] ? parts[1].replace(/[^\d]/g, '') : '';
            // 소수점이 입력된 경우 처리
            if (value.includes('.')) {
                event.target.value = `${integerPart}.${decimalPart}`;
            } else {
                event.target.value = integerPart;
            }
            this.newItem2 = event.target.value;
        },
        handleInput3(event) {
            let value = event.target.value;
            // 정수와 소수 부분을 나누기
            let parts = value.split('.');
            // 정수 부분에 쉼표 추가
            let integerPart = parts[0].replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // 소수 부분은 쉼표 없이 유지
            let decimalPart = parts[1] ? parts[1].replace(/[^\d]/g, '') : '';
            // 소수점이 입력된 경우 처리
            if (value.includes('.')) {
                event.target.value = `${integerPart}.${decimalPart}`;
            } else {
                event.target.value = integerPart;
            }
            this.newItem3 = event.target.value;
        },
        areBothItemsEntered() {
            // Check if both input fields have value
            return this.newItem1.trim() !== '' && this.newItem2.trim() !== ''
        },
        addItem() {
            if (this.areBothItemsEntered()) {
                this.items.push(this.newItem1, this.newItem2);
                this.newItem1 = ''
                this.newItem2 = ''
            }
        },
        deleteItem(index) {
            const startIndex = index * 2;
            this.items.splice(startIndex, 2);
        },
        createEdit(record){
            this.is_edit = true
            this.configData = record
            this.configDetailData = record.config
            this.select_telegram_id = record.telegram_id
            this.select_telegram_id2 = record.telegram_id2
            this.telegram_select_active = record.telegram_select
            this.trade_log_active = record.trade_log
            this.gap_method = record.config.gap.method_type 
            this.gap_method_value = record.config.gap.range 
            this.base_price = record.config.gap.start_price 
            this.after_price = record.config.gap.after_price_
            this.items = record.config.gap.amounts
            this.apikeyInputs = record.config.gap.apikeys
            this.brick_break_ = record.config.gap.brick_break
            this.brick_break_amount_ = record.config.gap.brick_break_amount
            this.brick_break_side_ = record.config.gap.brick_break_side
            this.trade_delay_time_ = record.config.gap.trade_delay_time
            this.changeExchangeSelect(record.exchange)
        },
        createAdd(){
            if(this.is_edit === true) {
                this.is_edit = false
                this.clearForm()
            }
        },
        clearForm(){
            this.configData = {}
            this.configDetailData = {}
            this.amounts = []
        }
    },
}

</script>
<style lang="scss">

.ant-form-item {
    margin-bottom: 5px;
}

.item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>