<template>
    <div>
        <a-table :columns="columns" 
                :data-source="trade_log_list"
                :row-key="record => record._id"
                :pagination="{ pageSize: 20 }"
                size="small"
                :loading="loading">
            <template slot="timestamp" slot-scope="text">
                {{ $moment(text).format('YYYY-MM-DD HH:mm:ss') }}
            </template>

        </a-table>
    </div>
</template>
<script>
export default {
    data() {
        return {
            columns: [
                {
                    title: 'log_time',
                    dataIndex: 'log_time',
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: 'exchange',
                    dataIndex: 'exchange'
                },
                {
                    title: 'coin',
                    dataIndex: 'coin'
                },
                {
                    title: 'pair',
                    dataIndex: 'pair'
                },
                {
                    title: 'status',
                    dataIndex: 'status'
                },
                {
                    title: 'status_str',
                    dataIndex: 'status_str'
                },
                {
                    title: 'apikey',
                    dataIndex: 'apikey'
                }
                
            ],
            trade_log_list: [],
            loading: true
        }
    },
    async mounted() {
        await this.get_trade_log()
        this.loading = false
        this.updateInterval = setInterval(() => {
            this.get_trade_log()
        
        }, (1 * 5000))
    },
    methods: {
        async get_trade_log(){
            const res = await this.$http.get('/api/v1/tradelog')
            this.trade_log_list = res.data
        }
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    }
}
</script>
<style lang="">
    
</style>