<template>
  <a-form-model ref="ruleForm" :model="updateData" layout="vertical">
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item class="mb-20" label="Name">
          <a-input v-model="updateData.name" />
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-divider/>
    <span>INFO</span>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item label="Token">
          <a-input
            v-model="updateData.info.token"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item
          label="Chat ID"
        >
          <a-input
            v-model="updateData.info.chat_id"
          />
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-divider/>
    
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 25px',
        background: '#fff',
        textAlign: 'right',
      }"
    >
      <a-button
        class="px-15"
        :style="{ marginRight: '5px' }"
        type="primary"
        size="small"
        html-type="submit"
        @click="onSubmit"
      >
        MODIFY
      </a-button>
      <a-button class="px-15" type="danger" size="small" @click="onDelete">
        DELETE
      </a-button>
    </div>
  </a-form-model>
</template>

<script>
export default {
  props: ["updateData"],
  data() {
    return {
      
    };
  },
  computed: {
  },
  watch: {
    // updateData() {
    //   this.user = this.updateData;
    // },
  },
  async mounted() {
    
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log("Received values of form: ", this.user);
          this.$http
            .put(`/api/v1/telegram/${this.updateData.id}`, {
              name: this.updateData.name,
              info: {
                token: this.updateData.info.token,
                chat_id: this.updateData.info.chat_id,
              }
            })
            .then(() => {
              this.redirect();
            });
        } else {
          console.log(err);
        }
      });
    },
    onDelete() {
      this.$http
        .delete(`/api/v1/telegram/${this.updateData.id}`, {
          // params: {
          //   id: this.updateData.id,
          // },
        })
        .then(() => {
          this.redirect();
        });
    },
    redirect() {
      // TO-DO : redirect error will solved(Completed)
      // this.$router.push("/pages/UserManagement/UserList")
      this.$router.go()
    },
  },
};
</script>

<style></style>
