<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="orders_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    <!-- {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }} -->
                    {{ text }}
                </template>
                <template slot="amount" slot-scope="text, record">
                    {{ Number(record.price * record.qty).toLocaleString(undefined, { maximumFractionDigits: 5 }) }}
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'koTime',
                    sorter: (a, b) => a.timestamp - b.timestamp,
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'is_ask',
                },
                
                // {
                //     title: '주문통화',
                //     dataIndex: 'order_currency',
                // },
                // {
                //     title: '결제통화',
                //     dataIndex: 'payment_currency',
                // },
                {
                    title: this.$i18n.t('trade.column_order_price'),
                    scopedSlots: { customRender: 'amount'}
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    dataIndex: 'price',
                    sorter: (a, b) => a.price - b.price,
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_amount'),
                    dataIndex: 'qty',
                    scopedSlots: { customRender: 'comma-format'}
                },
                
                
            ],
            // [
            //   {
            //     "trade_id": "4587c15c-1e4d-11e9-9ec7-00e04c3600d7",
            //     "order_id": "dcc75a5f-1e4f-11e9-9ec7-00e04c3600d7",
            //     "quote_currency": "KRW",
            //     "target_currency": "BTC",
            //     "order_type": "MARKET",
            //     "is_ask": false,
            //     "is_maker": false,
            //     "price": "76001000",
            //     "qty": "0.00009364",
            //     "timestamp": 1725603930000,
            //     "fee_rate": "0.00200",
            //     "fee": "14.2334",
            //     "fee_currency": "KRW"
            //   }
            // ]

            orders_table: []
        }
    },
    methods: {
        setPriceFormat(v){
            return Number(v).toLocaleString(undefined, { maximumFractionDigits: 5 })
        },
        async UpdateOrders(){
            const res = await this.$http.post('/api/v1/exchange2/coinone/orders', {
                apikeyId: this.apikeyId,
                symbol: `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`,
                running_server: this.running_server
            })
            let res_ = res.data;
            if (res_.length > 0) {
                res_.forEach(item => {
                    item.cTime = parseInt(item.timestamp)
                    const date = new Date(item.cTime); 
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                    item.koTime = koreanTime;

                    if (item.order_type === 'LIMIT') {
                        item.is_ask = !item.is_ask ? 'buy' : 'sell';
                    } else if (item.order_type === 'MARKET') {
                        item.is_ask = !item.is_ask ? 'buy_market' : 'sell_market';
                    }

                });
            }
            
            this.orders_table = res_;
        },
        
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">

</style>