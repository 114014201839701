var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.updateData,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{staticClass:"mb-20",attrs:{"label":"Name"}},[_c('a-input',{model:{value:(_vm.updateData.name),callback:function ($$v) {_vm.$set(_vm.updateData, "name", $$v)},expression:"updateData.name"}})],1)],1)],1),_c('a-divider'),_c('span',[_vm._v("INFO")]),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{attrs:{"label":"Token"}},[_c('a-input',{model:{value:(_vm.updateData.info.token),callback:function ($$v) {_vm.$set(_vm.updateData.info, "token", $$v)},expression:"updateData.info.token"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{attrs:{"label":"Chat ID"}},[_c('a-input',{model:{value:(_vm.updateData.info.chat_id),callback:function ($$v) {_vm.$set(_vm.updateData.info, "chat_id", $$v)},expression:"updateData.info.chat_id"}})],1)],1)],1),_c('a-divider'),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 25px',
      background: '#fff',
      textAlign: 'right',
    })},[_c('a-button',{staticClass:"px-15",style:({ marginRight: '5px' }),attrs:{"type":"primary","size":"small","html-type":"submit"},on:{"click":_vm.onSubmit}},[_vm._v(" MODIFY ")]),_c('a-button',{staticClass:"px-15",attrs:{"type":"danger","size":"small"},on:{"click":_vm.onDelete}},[_vm._v(" DELETE ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }