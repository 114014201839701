<template>
    <div>
        <a-button @click="CancelAllOrders()" :loading="loading_cancel_all_orders">{{ $t("trade.label_cancel_all_orders") }}</a-button>
        <a-button @click="CancelAllOrders('buy')" :loading="loading_cancel_all_orders">{{ $t("trade.label_cancel_buy_orders") }}</a-button>
        <a-button @click="CancelAllOrders('sell')" :loading="loading_cancel_all_orders">{{ $t("trade.label_cancel_sell_orders") }}</a-button>
        <a-button @click="UpdateOrders()" class="ml-5">
            <a-icon type="reload" theme="outlined" />
                Refresh
        </a-button>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns"
                     :row-key="record => record.id"
                     :data-source="orders_table" 
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    <!-- {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }} -->
                    {{ text }}
                </template>
                <template slot="cancel-order" slot-scope="text, record">
                    <a-button danger class="m-0" @click="clickCancelOrderButtonHandler(record)" :loading="cancel_order_list.includes(record.id)">{{ $t("trade.label_cancel") }}</a-button>
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
const ExchangeName = 'bitkub';

export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {
        
    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => a.ts - b.ts,
                    defaultSortOrder: 'descend',
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'side',
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    sorter: (a, b) => a.rate - b.rate,
                    dataIndex: 'rate',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: '수량(= 남은수량)',
                    dataIndex: 'amount_',
                    scopedSlots: { customRender: 'comma-format'}
                },
                // {
                //     title: this.$i18n.t('trade.column_remaining'),
                //     dataIndex: 'remaining',
                // },
                {
                    title: this.$i18n.t('trade.column_cancelorder'),
                    scopedSlots: { customRender: 'cancel-order'}
                }
            ],

            orders_table: [],
            cancel_order_list: [],
            loading_cancel_all_orders: false
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`
        },
        async CancelAllOrders(type='all'){
            this.loading_cancel_all_orders = true
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/cancelallorders`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                type,
                running_server: this.running_server
            })
            // {
            //     "status": "ok",
            //     "data": {
            //         "success-count": "1",
            //         "failed-count": "0",
            //         "next-id": "-1"
            //     }
            // }
            this.$notification.open({
                message: `Cancel Order`,
                description: `Success : ${res.data['total_order_count']}`,
                placement: 'bottomLeft',
            });
            this.loading_cancel_all_orders = false
        },
        async UpdateOrders(){
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/openorders`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                running_server: this.running_server
            })
            let res_ = res.data;
            if (res_.length > 0) {
                res_.forEach(item => {
                    item.cTime = parseInt(item.ts)
                    const date = new Date(item.cTime); 
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                    item.timestamp = koreanTime;

                    if (item.side === 'buy') {
                        item.amount_ = parseFloat(item.amount) / parseFloat(item.rate);
                        // item.amount_ = item.amount_.toLocaleString(undefined, { maximumFractionDigits: 8 })
                    } else if (item.side === 'sell') {
                        item.amount_ = parseFloat(item.amount)
                    }

                });
            }
            this.orders_table = res_; 
            // buy
            // {
            //   "id": "1698016",
            //   "hash": "fwQ6dnQY5TFtmdA5MSES9CApN5v",
            //   "side": "buy",
            //   "type": "limit",
            //   "rate": "17.95",
            //   "fee": "0.54",
            //   "credit": "0",
            //   "amount": "215.39",
            //   "receive": "11.96935933",
            //   "parent_id": "0",
            //   "super_id": "0",
            //   "client_id": "",
            //   "ts": 1727070588000
            // }
            // sell
            // {
            //   "id": "1166870",
            //   "hash": "fwQ6dnXtoNdcSJ1BVFmKUj9gavF",
            //   "side": "sell",
            //   "type": "limit",
            //   "rate": "17.96",
            //   "fee": "0.05",
            //   "credit": "0",
            //   "amount": "1",
            //   "receive": "17.91",
            //   "parent_id": "0",
            //   "super_id": "0",
            //   "client_id": "",
            //   "ts": 1727070951000
            // },
        },
        async clickCancelOrderButtonHandler(record){
            this.cancel_order_list.push(record.hash)
            // record.order_id
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/cancelorder`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                order_id: record.hash,
                running_server: this.running_server
            })
            this.$notification.open({
					message: `Cancel Order`,
					description: `${this.coin.toUpperCase()}/${this.pair.toUpperCase()} - Price: ${record.rate} Amount: ${record.amount_}`,
					placement: 'bottomLeft',
				});
            this.UpdateOrders()
        }
        
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (5 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">


</style>