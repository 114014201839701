<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="orders_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }}
                </template>
                <template slot="is_ask" slot-scope="text">
                    {{ text === false ? "BUY" : "SELL" }}
                </template>
                <template slot="amount" slot-scope="text, record">
                    {{ Number(record.price * record.amount).toLocaleString(undefined, { maximumFractionDigits: 5 }) }}
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
const ExchangeName = 'poloniex';
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => a.timestamp - b.timestamp,
                    defaultSortOrder: 'descend',
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'side',
                    // scopedSlots: { customRender: 'is_ask'}
                },
                
                // {
                //     title: '주문통화',
                //     dataIndex: 'order_currency',
                // },
                // {
                //     title: '결제통화',
                //     dataIndex: 'payment_currency',
                // },
                {
                    title: this.$i18n.t('trade.column_order_price'),
                    scopedSlots: { customRender: 'amount'}
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    dataIndex: 'price',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_amount'),
                    dataIndex: 'amount',
                    scopedSlots: { customRender: 'comma-format'}
                },
                
                
            ],

            orders_table: []
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin}_${this.pair}`
        },
        async UpdateOrders(){
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/orders`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                running_server: this.running_server
            })
            this.orders_table = res.data
            // {
            //     "id": "77474f48-613c-44e5-b894-f3ef758b5315",
            //     "datetime": "2022-09-28T13:22:49.212Z",
            //     "timestamp": 1664371369212,
            //     "status": "canceled",
            //     "symbol": "KLAY/USDT",
            //     "type": "limit",
            //     "postOnly": false,
            //     "side": "buy",
            //     "price": 0.1924,
            //     "cost": 0,
            //     "amount": 20.91,
            //     "filled": 0,
            //     "remaining": 20.91,
            //     "trades": [],
            //     "info": {
            //         "cummulativeQuoteQty": "0",
            //         "symbol": "klay_usdt",
            //         "executedQty": "0",
            //         "orderId": "77474f48-613c-44e5-b894-f3ef758b5315",
            //         "origQty": "20.91",
            //         "price": "0.1924",
            //         "origQuoteOrderQty": "4.023084",
            //         "updateTime": "1664371451000",
            //         "time": "1664371369212",
            //         "type": "buy",
            //         "status": "-1"
            //     },
            //     "fees": []
            // }
        }
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">

</style>