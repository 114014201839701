<template>
    <div>
        <!-- choice apikey, exchange, coin, pair -->
        <a-form-model
          ref="ruleForm"
          :model="configData"
          :rules="rules"
          :label-col="{span: 4}"
          :wrapper-col="{span: 20}"
          
          layout="horizontal"
        >
            <a-form-model-item ref="name" label="Name" prop="name">
                <a-input
                    v-model="configData.name"
                    size="small"
                    style="width: 60%"
                />
            </a-form-model-item>

            <a-divider/>
            <!-- 기준거래소 config start -->
            <div style="font-weight: bold; margin-bottom: 8px;">
                기준 거래소
            </div>

            <a-form-model-item label="Exchange" prop="name">
                <a-select
                    v-model="configData.exchange"
                    size="small"
                    style="width: 40%"
                    @change="changeExchangeSelect"
                >
                    <a-select-option v-for="v in exchange_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                </a-select>
            </a-form-model-item>

            <template v-if="configData.exchange">
                <a-form-model-item label="Apikey" prop="name">
                    <a-select
                        v-model="configData.apikey"
                        size="small"
                        style="width: 40%"
                        :loading="is_apikey_loading"
                        @change="handleApikeyChange"
                    >
                        <a-select-option v-for="v in apikey_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank_central.lp_server')" prop="name">
                    <a-input
                        v-model="select_server"
                        size="small"
                        style="width: 40%"
                        disabled
                        placeholder="Binding Server"
                    />
                </a-form-model-item> 

                <a-form-model-item label="Coin" prop="name">
                    <a-select
                        v-model="configData.coin"
                        size="small"
                        style="width: 40%"
                        :loading="is_symbols_loading"
                        showSearch
                    >
                        <a-select-option v-for="v in symbols_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Pair" prop="name">
                    <a-select
                        v-model="configData.pair"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in pair_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank_central.target_price')" prop="name">
                    <a-input-number
                            v-model="base_price"
                            size="small"
                            style="width: 40%"
                            :formatter="value => {
                                const parts = value.toString().split('.');
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                return parts.join('.');
                            }"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        />
                </a-form-model-item>
                <!-- 기준거래소 config end -->

                <!-- 페어거래소 config start -->
                <template>
                    <div v-for="(item, index) in apikeyInputs" :key="item.id">
                        <a-divider />
                        <div style="font-weight: bold; margin-bottom: 8px;">
                            {{ apikeyInputs.length > 1 ? '페어 거래소 ' + (index + 1) : '페어 거래소' }}
                        </div>

                        <a-form-model-item :label="'Exchange'" :prop="'exchange_' + item.id">
                            <a-select
                                v-model="item.exchange"
                                size="small"
                                style="width: 40%"
                                @change="changeExchangeSelect2(index)"
                            >
                                <a-select-option v-for="v in exchange_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                            </a-select>
                            <a-button class="mx-15 toggle-button" @click="toggleCollapse(index)">{{ collapsed[index] ? 'Open' : 'Close' }}</a-button>
                            <a-button class="mx-15 delete-button" @click="deleteApiKeyInput(index)">Delete</a-button>
                        </a-form-model-item>

                        <div v-show="!collapsed[index]">
                            <a-form-model-item :label="'API Key'" :prop="'apikey_' + item.id">
                                <a-select
                                    v-model="item.apikey"
                                    size="small"
                                    style="width: 40%"
                                    :loading="is_apikey_loading"
                                    @change="handleApikeyChange2(index)"
                                >
                                    <a-select-option v-for="v in item.apikey_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                                </a-select>
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.lp_server')" :prop="'lp_server_' + item.id">
                                <a-input
                                    v-model="item.lp_server"
                                    size="small"
                                    style="width: 40%"
                                    disabled
                                    placeholder="Binding Server"
                                />
                            </a-form-model-item> 

                            <a-form-model-item label="Pair" prop="name">
                                <a-select
                                    v-model="item.pair"
                                    size="small"
                                    style="width: 40%"
                                    :loading="is_symbols_loading"
                                    showSearch
                                >
                                    <a-select-option v-for="v in item.pair_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                                </a-select>
                            </a-form-model-item>

                            <a-form-model-item v-if="getBasePrice() === -1" :label="$t('fillblank_central.target_price')" prop="name">
                                <a-input-number
                                        v-model="item.p_price2"
                                        size="small"
                                        style="width: 40%"
                                        :formatter="value => {
                                            const parts = value.toString().split('.');
                                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                            return parts.join('.');
                                        }"
                                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                                    />
                            </a-form-model-item>
                            
                            <a-form-model-item label="Speed" prop="name">
                                <a-input-number
                                    v-model="item.speed"
                                    size="small"
                                    style="width: 40%"
                                    :formatter="value => {
                                        const parts = value.toString().split('.');
                                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                        return parts.join('.');
                                    }"
                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')" 
                                    :min="0"      
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.amount_min')" prop="name">
                                <a-input-number
                                    v-model="item.amount_min"
                                    size="small"
                                    style="width: 40%"
                                    :formatter="value => {
                                        const parts = value.toString().split('.');
                                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                        return parts.join('.');
                                    }"
                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')" 
                                    :min="0"      
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.amount_max')" prop="name">
                                <a-input-number
                                    v-model="item.amount_max"
                                    size="small"
                                    style="width: 40%"
                                    :formatter="value => {
                                        const parts = value.toString().split('.');
                                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                        return parts.join('.');
                                    }"
                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')" 
                                    :min="0"      
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.fill_range_percent')" prop="name">
                                <a-input-number
                                    v-model="item.fill_range_percent"
                                    size="small"
                                    style="width: 40%"
                                    :formatter="value => {
                                        const parts = value.toString().split('.');
                                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                        return parts.join('.');
                                    }"
                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')" 
                                    :min="0"      
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.fill_total_price')" prop="name">
                                <a-input-number
                                    v-model="item.fill_total_price"
                                    size="small"
                                    style="width: 40%"
                                    :formatter="value => {
                                        const parts = value.toString().split('.');
                                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                        return parts.join('.');
                                    }"
                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')" 
                                    :min="0"      
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.target_fill_gap')" prop="name">
                                <a-input-number
                                    v-model="item.target_fill_gap"
                                    size="small"
                                    style="width: 40%"
                                    :formatter="value => {
                                        const parts = value.toString().split('.');
                                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                        return parts.join('.');
                                    }"
                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')" 
                                    :min="0"      
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.is_ask_mode')" :prop="'is_ask_mode_' + item.id">
                                <a-switch
                                    v-model="item.is_ask_mode"
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.is_bid_mode')" :prop="'is_bid_mode_' + item.id">
                                <a-switch
                                    v-model="item.is_bid_mode"
                                />
                            </a-form-model-item>
                            
                            <a-form-model-item :label="$t('fillblank_central.is_out_amount_ignore')" :prop="'is_out_amount_ignore_' + item.id">
                                <a-switch
                                    v-model="item.is_out_amount_ignore"
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.is_cancel_out_range')" :prop="'is_cancel_out_range_' + item.id">
                                <a-switch
                                    v-model="item.is_cancel_out_range"
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.is_opposite_allowance')" :prop="'is_opposite_allowance' + item.id">
                                <a-switch
                                    v-model="item.is_opposite_allowance"
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.coin_protect')" :prop="'coin_protect_' + item.id">
                                <a-input-number
                                    v-model="item.coin_protect"
                                    size="small"
                                    style="width: 40%"
                                    :formatter="value => {
                                        const parts = value.toString().split('.');
                                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                        return parts.join('.');
                                    }"
                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')" 
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.pair_protect')" :prop="'pair_protect_' + item.id">
                                <a-input-number
                                    v-model="item.pair_protect"
                                    size="small"
                                    style="width: 40%"
                                    :formatter="value => {
                                        const parts = value.toString().split('.');
                                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                        return parts.join('.');
                                    }"
                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')" 
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.precision')" :prop="'precision_' + item.id">
                                <a-input-number
                                    v-model="item.precision"
                                    size="small"
                                    style="width: 40%"
                                    :formatter="value => {
                                        const parts = value.toString().split('.');
                                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                        return parts.join('.');
                                    }"
                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                                    :min="0"
                                />
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.telegram1')" :prop="'telegram1_' + item.id">
                                <a-select
                                    v-model="item.telegram_id"
                                    size="small"
                                    style="width: 40%"
                                >
                                    <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                                </a-select>
                            </a-form-model-item>

                            <a-form-model-item :label="$t('fillblank_central.telegram2')" :prop="'telegram2_' + item.id">
                                <a-select
                                    v-model="item.telegram_id2"
                                    size="small"
                                    style="width: 40%"
                                >
                                    <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                                </a-select>
                            </a-form-model-item>

                            <a-form-model-item label="Select On/Off" :prop="'select_onoff_' + item.id">
                                <a-switch
                                    v-model="item.telegram_select"
                                ></a-switch>
                            </a-form-model-item>
                        </div>

                        <a-form-model-item :label="$t('fillblank_central.onoff_button')" :prop="'onoff_button_' + item.id">
                            <div style="display: flex; align-items: center;">
                                <a-switch
                                    v-model="item.onoff_button"
                                    :loading="loading"
                                    @change="isRunChange(index)"
                                />
                                <div style="margin-left: 15px;">
                                    <a-button size="small"
                                        @click="requestOutRangeCancel(index)"
                                        :loading="loading_out_range_cancel">{{ $t("trade.label_out_range_cancel") }}</a-button>
                                </div>
                            </div>
                        </a-form-model-item>
                    </div>
                    <a-button class="mx-15 add-button" @click="addApiKeyInput">Add</a-button>
                </template>
                <!-- 페어거래소 config end -->

            </template>
        </a-form-model>
            
        <a-row>
            <a-col>
                <span>{{ error_log }}</span>
            </a-col>
            <a-col>
                <a-button class="m-15" @click="emitBtn">{{ is_edit? 'Modify' : 'Add' }}</a-button>
            </a-col>
        </a-row>
        
    </div>
</template>
<script>
export default {
    data() {
        return {
            configData: {},
            configDetailData: {},
            configOptionPrecisionData: {},
            rules: {},
            exchange_list: [],
            coin_list:
            [
                {
                    label: 'klaytn',
                    value: 'klay'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            pair_list:
            [
                {
                    label: 'KRW',
                    value: 'krw'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            option_form: [
                // {
                //     label: '소수점지정(수량)',
                //     value: 'amount',
                //     type: 'number',
                //     required: false
                // },
                {
                    label: this.$i18n.t("fillblank_central.precision"),
                    value: 'price',
                    type: 'number',
                    required: false
                },
            ],
            config_form: [],
            symbols_list: [],
            apikey_list: [],
            apikey_list2: [],
            is_apikey_loading: false,
            is_symbols_loading: false,
            error_log: '',
            is_edit: false,
            select_server: null,
            base_price: 0,
            // Client Config ----------------
            apikeyInputs: [{ id: 0, 
                             exchange: '',
                             apikey: '',
                             apikey_list: [],
                             apikey_list2: [],
                             lp_server: 'disable',
                             pair: '',
                             symbols_list: [],
                             pair_list: [{label: 'KRW', value: 'krw'},],
                             speed: '',
                             p_price2: '',
                             amount_min: '',
                             amount_max: '',
                             fill_range_percent: '',
                             fill_total_price: '',
                             target_fill_gap: '',
                             is_ask_mode: false,
                             is_bid_mode: false,
                             is_out_amount_ignore: false,
                             is_cancel_out_range: true,
                             is_opposite_allowance: false,
                             coin_protect: '',
                             pair_protect: '',
                             telegram_id: '',
                             telegram_id2: '',
                             telegram_select: false,
                             precision: '',
                             onoff_button: false,
                             onoff_status: 'stop',
                             }], 
            apikeyCounter: 1, // Counter for generating unique IDs
            // ------------------------------
            collapsed: [],
            telegram_list: [],
            loading: false,
            loading_out_range_cancel: false,
        }
    },
    async mounted() {
        await this.getExchange()
        await this.getTelegram()
    },
    methods: {
        // Client Config ------------------------
        toggleCollapse(index) {
            this.$set(this.collapsed, index, !this.collapsed[index]);
        },
        addApiKeyInput() {
            this.apikeyInputs.push({ id: this.apikeyCounter++, 
                                     exchange: '',
                                     apikey: '',
                                     apikey_list: [],
                                     apikey_list2: [],
                                     lp_server: 'disable',
                                     pair: '',
                                     symbols_list: [],
                                     pair_list: [{label: 'KRW', value: 'krw'},],
                                     speed: '',
                                     p_price2: '',
                                     amount_min: '',
                                     amount_max: '',
                                     fill_range_percent: '',
                                     fill_total_price: '',
                                     target_fill_gap: '',
                                     is_ask_mode: false,
                                     is_bid_mode: false,
                                     is_out_amount_ignore: false,
                                     is_cancel_out_range: true,
                                     is_opposite_allowance: false,
                                     coin_protect: '',
                                     pair_protect: '',
                                     telegram_id: '',
                                     telegram_id2: '',
                                     telegram_select: false,
                                     precision: '',
                                     onoff_button: false,
                                     onoff_status: 'stop',
                                     });
            this.collapsed.push(false);                         
        },
        deleteApiKeyInput(index) {
            if (this.apikeyInputs.length > 1) {
                this.apikeyInputs.splice(index, 1); 
                this.collapsed.splice(index, 1);
            }
        },
        async isRunChange(index){
            this.loading = true
            const record = this.configData
            const running_server = record.apikeys[index].lp_server
            const res = await this.$http.get(`/api/v1/autotrade/fillblank_central/run/${record._id}`, {
                params: {
                    server: running_server,
                    index: index,
                }
            })
            this.configData.apikeys[index].onoff_status = res.data
            // this.configData.apikeys[index].onoff_status = res.data
            this.loading = false
        },
        // Client Config ------------------------
        async requestOutRangeCancel(index){
            this.loading_out_range_cancel = true
            const record = this.configData
            const running_server = record.apikeys[index].lp_server
            try {
                const res = await this.$http.get(`/api/v1/autotrade/fillblank_central/cancel_out_range/${record._id}`, {
                    params: {
                        server: running_server,
                        index: index,
                    }
                });
                this.$notification.open({
                    message: `범위외취소 성공`,
                    description: `취소된 주문 수: ${res.data.count}`,
                    placement: 'bottomLeft',
                });
            } catch (e) {
                this.$notification.error({
                    message: `범위외취소 실패..`,
                    description: e.response.data.message,
                    placement: 'bottomLeft',
                });
            }

            this.loading_out_range_cancel = false
        },
        getBasePrice() {
            return this.base_price;
        },
        async getTelegram() {
            try {
                const res = await this.$http.get('/api/v1/telegram/')
                for (const o of res.data) {
                    this.telegram_list.push({
                        label: o.name,
                        value: o.id
                    })
                }
                // console.log(this.telegram_list)
            } catch (e) {
                console.error(e)
            }
        },
        async getExchange() {
            try {
                const res = await this.$http.get('/api/v1/info/exchange')
                for (const [key, value] of Object.entries(res.data)) {
                    this.exchange_list.push({
                        label: key,
                        value
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        async changeExchangeSelect2(index){
            const v = this.apikeyInputs[index].exchange
            await this.get_apikeys2(v, index)
        },
        async get_apikeys2(v, index){
            this.apikeyInputs[index].apikey_list = []
            this.is_apikey_loading = true
            const res = await this.$http.get('/api/v1/apikeys/', {
                params:{
                    exchange: v
                    }
                })
            res.data.forEach(e => {
                this.apikeyInputs[index].apikey_list.push(
                    {
                        label: e.name,
                        value: e.id
                    }
                )
            });
            this.apikeyInputs[index].apikey_list2 = res.data
            this.is_apikey_loading = false
        },
        async handleApikeyChange2(index){
            const selectedApiKey = this.apikeyInputs[index].apikey;
            const selectedApiKey2 = this.apikeyInputs[index].apikey_list2.find(apikey => apikey.id === selectedApiKey);
            if (selectedApiKey2 && selectedApiKey2.running_server) {
                this.$set(this.apikeyInputs[index], 'lp_server', selectedApiKey2.running_server);
                await this.get_symbols2(this.apikeyInputs[index].exchange, this.apikeyInputs[index].lp_server, index);
            }
        },
        async get_symbols2(exchange, runserver, index){
            this.apikeyInputs[index].symbols_list = []
            this.is_symbols_loading = true
            const res = await this.$http.get(`/api/v1/exchange2/${exchange}/symbols/${runserver}`)
            let symbol_split = ''
            if (res.data[0].includes('/')) {
                symbol_split = '/'
            } else if (res.data[0].includes('_')) {
                symbol_split = '_'
            }
            if (symbol_split !== '') {
                this.apikeyInputs[index].pair_list = []
                res.data.forEach(e => {
                    const _symbol = e.split(symbol_split)
                    if (!this.apikeyInputs[index].symbols_list.find(s => s.label === _symbol[0])) {
                        this.apikeyInputs[index].symbols_list.push(
                            {
                                label: _symbol[0],
                                value: _symbol[0].toLowerCase()
                            }
                        )
                    }
                    if (!this.apikeyInputs[index].pair_list.find(s => s.label === _symbol[1])) {
                        this.apikeyInputs[index].pair_list.push({
                            label: _symbol[1],
                            value: _symbol[1].toLowerCase()
                        })
                    }

                });
            } else {
                res.data.forEach(e => {
                    this.apikeyInputs[index].symbols_list.push(
                        {
                            label: e,
                            value: e.toLowerCase()
                        }
                    )
                });
            }

            if (!this.apikeyInputs[index].symbols_list.find(s => s.value === this.configData.coin.toLowerCase())) {
                this.apikeyInputs[index].pair_list = [];
                this.$notification.open({
                        message: `Bad Symbol`,
                        description: `기준 거래소의 토큰을 페어 거래소에서 불러올 수 없습니다.`,
                        placement: 'bottomLeft',
                    });
            }
            this.is_symbols_loading = false
        },
        async changeExchangeSelect(v){
            
            await this.get_apikeys(v)
            // await this.get_symbols(v)
        },
        async get_apikeys(v){
            this.apikey_list = []
            this.is_apikey_loading = true
            const res = await this.$http.get('/api/v1/apikeys/', {
                params:{
                    exchange: v
                    }
                })
            res.data.forEach(e => {
                this.apikey_list.push(
                    {
                        label: e.name,
                        value: e.id
                    }
                )
            });
            this.apikey_list2 = res.data
            this.is_apikey_loading = false
        },
        async handleApikeyChange(value) {
            const selectedApiKey = this.apikey_list2.find(apikey => apikey.id === value);
            if (selectedApiKey) {
                this.select_server = selectedApiKey.running_server;
                await this.get_symbols(this.configData.exchange, this.select_server);
            }
        },
        async get_symbols(exchange, runserver){
            this.symbols_list = []
            this.is_symbols_loading = true
            const res = await this.$http.get(`/api/v1/exchange2/${exchange}/symbols/${runserver}`)
            let symbol_split = ''
            if (res.data[0].includes('/')) {
                symbol_split = '/'
            } else if (res.data[0].includes('_')) {
                symbol_split = '_'
            }
            if (symbol_split !== '') {
                this.pair_list = []
                res.data.forEach(e => {
                    const _symbol = e.split(symbol_split)
                    if (!this.symbols_list.find(s => s.label === _symbol[0])) {
                        this.symbols_list.push(
                            {
                                label: _symbol[0],
                                value: _symbol[0].toLowerCase()
                            }
                        )
                    }
                    if (!this.pair_list.find(s => s.label === _symbol[1])) {
                        this.pair_list.push({
                            label: _symbol[1],
                            value: _symbol[1].toLowerCase()
                        })
                    }

                });
            } else {
                res.data.forEach(e => {
                    this.symbols_list.push(
                        {
                            label: e,
                            value: e.toLowerCase()
                        }
                    )
                });
            }
            this.is_symbols_loading = false
            return res.data
        },
        async emitBtn(){
            await this.registCrossTrade()
        },
        async registCrossTrade(){
            const crossTrade = {
                ...this.configData,
                server: this.select_server,
                p_price: this.base_price,
                apikeys: this.apikeyInputs,
                toggle: this.collapsed,
                config: this.configDetailData,
            }
            try{
                if (this.is_edit === true){
                    const res = await this.$http.put(`/api/v1/autotrade/fillblank_central/${this.configData._id}`, crossTrade)
                    this.error_log = ''
                } else {
                    const res = await this.$http.post('/api/v1/autotrade/fillblank_central/', crossTrade)
                    this.error_log = ''
                }
                this.$router.go();
                // this.$notification.open({
                //     message: `Modify Success`,
                //     description: `설정이 저장되었습니다.`,
                //     placement: 'bottomLeft',
                // });
            } catch (e){
                console.error(e.response)
                this.error_log = e.response.data.message
            }
        },
        createEdit(record){
            this.is_edit = true
            this.configData = record
            this.configDetailData = record.config
            this.select_server = record.server
            this.base_price = record.p_price
            this.apikeyInputs = record.apikeys
            this.collapsed = record.toggle
            this.changeExchangeSelect(record.exchange)
        },
        createAdd(){
            if(this.is_edit === true) {
                this.is_edit = false
                this.clearForm()
            }
        },
        clearForm(){
            this.configData = {}
            this.configDetailData = {}
            this.configOptionPrecisionData = {}
        }

    },
    
}
</script>
<style lang="scss">

.ant-form-item {
    margin-bottom: 5px;
}
    
.delete-button {
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    border-radius: 8px;
    border: none;
    // padding: 0 15px;
    height: 35px;
    line-height: 35px;
}

.add-button {
    // background-color: blue;
    background-color: rgba(0, 0, 255, 0.7);
    color: white;
    border-radius: 8px;
    border: none;
    // padding: 0 15px;
    height: 35px;
    line-height: 35px;
}

</style>