<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="orders_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    <!-- {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }} -->
                    {{ text }}

                </template>
                <template slot="is_ask" slot-scope="text">
                    {{ text === false ? "BUY" : "SELL" }}
                </template>
                <template slot="amount" slot-scope="text, record">
                    {{ Number(record.price * record.amount).toLocaleString(undefined, { maximumFractionDigits: 5 }) }}
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
const ExchangeName = 'coinstore';
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => a.ftime - b.ftime,
                    defaultSortOrder: 'descend',
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'side',
                    // scopedSlots: { customRender: 'is_ask'}
                },
                
                // {
                //     title: '주문통화',
                //     dataIndex: 'order_currency',
                // },
                // {
                //     title: '결제통화',
                //     dataIndex: 'payment_currency',
                // },
                {
                    title: this.$i18n.t('trade.column_order_price'),
                    scopedSlots: { customRender: 'amount'}
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    dataIndex: 'price',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_amount'),
                    dataIndex: 'amount',
                    scopedSlots: { customRender: 'comma-format'}
                },
                
                
            ],

            orders_table: []
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin}${this.pair}`
        },
        countDecimalPlaces(number) {
            if (Math.floor(number) === number) return 0;
            return number.toString().split(".")[1].length || 0;
        },
        roundPrice(value, decimals) {
            const factor = Math.pow(10, decimals);
            return Math.round(value * factor) / factor;
        },
        async UpdateOrders(){
            const symbol = this.getSymbol();
            const market_info = await this.$http.get(`/api/v1/exchange/${ExchangeName}/precision/${symbol}`);
            // console.log(`shit = ${JSON.stringify(market_info.data[0])}`)
            const price_round = market_info.data[0]['tickSz']
            // const price_round = this.countDecimalPlaces(price_round_);
            // console.log(`price1 = ${price_round}`);

            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/orders`, {
                apikeyId: this.apikeyId,
                symbol: symbol,
                running_server: this.running_server
            });
            // console.log(`price2 = ${price_round}`);

            let res_ = res.data.filter(data => data.orderState === 50);
            if (res_.length > 0) {
                res_.forEach(item => {
                    item.ftime = item.matchTime;
                    const date = new Date(item.matchTime*1000); 
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

                    item.timestamp = koreanTime;
                    item.side = (item.side === 1) ? 'BUY' : (item.side === -1 ? 'SELL' : item.side);
                    item.amount = item.execQty;
                    // console.log(`price3 = ${price_round}`);
                    item.price = this.roundPrice((item.execAmt / item.execQty), price_round);
                });
            }
            this.orders_table = res_;


            // {
            //     "id": 441182723,
            //     "remainingQty": 0,
            //     "matchRole": 2,
            //     "feeCurrencyId": 34,
            //     "acturalFeeRate": 0.002,
            //     "role": -1,
            //     "accountId": 13272093,
            //     "instrumentId": 4,
            //     "baseCurrencyId": 34,
            //     "quoteCurrencyId": 30,
            //     "execQty": 0.00001,
            //     "orderState": 50,
            //     "matchId": 16450167850,
            //     "orderId": 1796781487686233,
            //     "side": 1,
            //     "execAmt": 0.6385893,
            //     "selfDealingQty": 0,
            //     "tradeId": 62157622,
            //     "fee": 2e-8,
            //     "matchTime": 1713544362,
            //     "seq": null,
            //     "taxRate": 0,
            //     "tradeScale": null,
            //     "baseCurrencyName": null,
            //     "orderType": null
            //   },
        }
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">

</style>