<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="orders_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }}
                </template>
                <template slot="is_ask" slot-scope="text">
                    {{ text === false ? "BUY" : "SELL" }}
                </template>
                <template slot="amount" slot-scope="text, record">
                    {{ Number(record.price * record.amount).toLocaleString(undefined, { maximumFractionDigits: 5 }) }}
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
const ExchangeName = 'okex';
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => a.timestamp - b.timestamp,
                    defaultSortOrder: 'descend',
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'side',
                    // scopedSlots: { customRender: 'is_ask'}
                },
                
                // {
                //     title: '주문통화',
                //     dataIndex: 'order_currency',
                // },
                // {
                //     title: '결제통화',
                //     dataIndex: 'payment_currency',
                // },
                {
                    title: this.$i18n.t('trade.column_order_price'),
                    scopedSlots: { customRender: 'amount'}
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    dataIndex: 'price',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_amount'),
                    dataIndex: 'amount',
                    scopedSlots: { customRender: 'comma-format'}
                },
                
                
            ],

            orders_table: []
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin}_${this.pair}`
        },
        async UpdateOrders(){
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/orders`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                running_server: this.running_server
            })
            this.orders_table = res.data
            // [
            //     {
            //         "info": {
                //         "id": "4286666303",
                //         "create_time": "1665054022",
                //         "create_time_ms": "1665054022001.413000",
                //         "currency_pair": "KLAY_USDT",
                //         "side": "buy",
                //         "role": "taker",
                //         "amount": "4",
                //         "price": "0.1913",
                //         "order_id": "208893636391",
                //         "fee": "0.008",
                //         "fee_currency": "KLAY",
                //         "point_fee": "0",
                //         "gt_fee": "0"
            //         },
            //         "id": "4286666303",
            //         "timestamp": 1665054022001,
            //         "datetime": "2022-10-06T11:00:22.001Z",
            //         "symbol": "KLAY/USDT",
            //         "order": "208893636391",
            //         "side": "buy",
            //         "takerOrMaker": "taker",
            //         "price": 0.1913,
            //         "amount": 4,
            //         "cost": 0.7652,
            //         "fee": {
            //         "currency": "KLAY",
            //         "cost": 0.008
            //         },
            //         "fees": [
            //         {
            //             "cost": "0.008",
            //             "currency": "KLAY"
            //         },
            //         {
            //             "cost": "0",
            //             "currency": "GT"
            //         },
            //         {
            //             "cost": "0",
            //             "currency": "POINT"
            //         }
            //         ]
            //     }
            //     ]
        }
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">

</style>