<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="orders_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    <!-- {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }} -->
                    {{ text }}
                </template>
                <template slot="is_ask" slot-scope="text">
                    {{ text === false ? "BUY" : "SELL" }}
                </template>
                <template slot="amount" slot-scope="text, record">
                    <!-- {{ Number(record.price * record.origQty).toLocaleString(undefined, { maximumFractionDigits: 5 }) }} -->
                    {{ record.type === 'buy_market' ? record.origQty_ : Number(record.price * record.size).toLocaleString(undefined, { maximumFractionDigits: 5 }) }}
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
const ExchangeName = 'kucoin';
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => a.createdAt - b.createdAt,
                    defaultSortOrder: 'descend',
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'side',
                },
                {
                    title: this.$i18n.t('trade.column_order_price'),
                    scopedSlots: { customRender: 'amount'}
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    dataIndex: 'price',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_amount'),
                    dataIndex: 'size',
                    scopedSlots: { customRender: 'comma-format'}
                },
                
                
            ],

            orders_table: []
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin}_${this.pair}`
        },
        async UpdateOrders(){
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/orders`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                running_server: this.running_server
            })

            let res_ = res.data;
            if (res_.length > 0) {
                res_.forEach(item => {
                    item.cTime = parseInt(item.createdAt)
                    const date = new Date(item.cTime); 
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                    item.timestamp = koreanTime;

                    // if (item.type === 'buy_market') {
                    //     item.origQty_ = item.origQty;
                    //     item.origQty = 0;
                    //     item.price = 0;
                    // }
                });
            }
            this.orders_table = res_;
            //   "items": [
            //     {
            //       "id": "66d01cce98f65700076661bf",
            //       "symbol": "BTC-USDT",
            //       "opType": "DEAL",
            //       "type": "limit",
            //       "side": "buy",
            //       "price": "20000",
            //       "size": "0.00001",
            //       "funds": "0",
            //       "dealFunds": "0",
            //       "dealSize": "0",
            //       "fee": "0",
            //       "feeCurrency": "USDT",
            //       "stp": "",
            //       "stop": "",
            //       "stopTriggered": false,
            //       "stopPrice": "0",
            //       "timeInForce": "GTC",
            //       "postOnly": false,
            //       "hidden": false,
            //       "iceberg": false,
            //       "visibleSize": "0",
            //       "cancelAfter": 0,
            //       "channel": "API",
            //       "clientOid": "a2c031a9-4e0a-40a1-bb5d-28c767138429",
            //       "remark": null,
            //       "tags": null,
            //       "isActive": false,
            //       "cancelExist": true,
            //       "createdAt": 1724914894108,
            //       "tradeType": "TRADE"
            //     },
        }
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">

</style>