<template>
  <a-form
    :form="form"
    layout="vertical"
    hide-required-mark
    @submit="handleSubmit"
  >
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-20" label="Name">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: 'Please enter user name' }],
              },
            ]"
            placeholder="Name"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-10" label="Running Server" :colon="false">
          <a-select v-decorator="[
              'runserver',
              {
                rules: [{ required: true, message: 'Please select server' }],
              },
            ]"
            placeholder="server">
            <a-select-option v-for="v in server_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-10" label="Exchange" :colon="false">
          <a-select default-value="Bithumb" v-decorator="[
              'exchange',
              {
                rules: [{ required: true, message: 'Please select exchange' }],
              },
            ]"
            placeholder="exchange" @change="get_symbols">
            <a-select-option v-for="(e, i) in exchange" :value="e.value" :key="i">{{ e.label }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-10" label="Currencies" :colon="false">
          <a-select v-decorator="[
              'currencies',
              {
                rules: [{ required: true, message: 'Please select currencies' }],
              },
            ]"
            placeholder="currencies" :loading="is_currency_loading" mode="multiple">
            <a-select-option v-for="(e, i) in currency_list" :value="e.toLowerCase()" :key="i">{{e}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-20" label="Api Key">
          <a-input
            v-decorator="[
              'apikey',
              {
                rules: [{ required: true, message: 'Please enter API Key' }],
              },
            ]"
            placeholder=""
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-20" label="Secret">
          <a-input
            v-decorator="[
              'secret',
              {
                rules: [{ required: true, message: 'Please enter Secret Key' }],
              },
            ]"
            placeholder=""
          />
        </a-form-item>
      </a-col>
    </a-row>
    
   <template>
       <a-row :gutter="16">
           <a-col>
               <a-button @click="toggleImage">{{ isImageVisible ? '접기' : 'API 입력 가이드' }}</a-button>
               <transition name="fade">
                   <img v-if="isImageVisible" src="./API_rule.png" alt="api_rule" class="responsive-image">
               </transition>
           </a-col>
       </a-row>
   </template>

    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 25px',
        background: '#fff',
        textAlign: 'right',
      }"
    >
      <a-button
        class="px-15"
        type="primary"
        size="small"
        html-type="submit"
        align="center"
      >
        CREATE
      </a-button>
    </div>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      exchange: [
        
      ],
      currency_list: [

      ],
      server_list: [
        {
            label: 'Master',
            value: 'master',
        },
        {
            label: 'LP1',
            value: 'lp1',
        },
        {
            label: 'LP2',
            value: 'lp2',
        },
        {
            label: 'LP3',
            value: 'lp3',
        },
        {
            label: 'LP4',
            value: 'lp4',
        },
        {
            label: 'LP5',
            value: 'lp5',
        },
        {
            label: 'Dev1',
            value: 'lp6',
        },
        {
            label: 'Dev2',
            value: 'lp7',
        },
      ],
      is_currency_loading: true,
      isImageVisible: false
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "userlist" });
  },
  async mounted() {
    await this.getExchange()
  },
  methods: {
    toggleImage() {
        this.isImageVisible = !this.isImageVisible;
    },
    async get_symbols(e){
      // console.log(e)
      this.is_currency_loading = true
      const runserverValue = this.form.getFieldValue('runserver');
      const res = await this.$http.get(`/api/v1/exchange2/${e}/symbols/${runserverValue}`)
      this.currency_list = res.data
      this.is_currency_loading = false
      // console.log(this.currency_list)
    },
    async getExchange() {
        try {
            const res = await this.$http.get('/api/v1/info/exchange')
            for (const [key, value] of Object.entries(res.data)) {
                this.exchange.push({
                    label: key,
                    value
                })
            }
        } catch (e) {
            console.error(e)
        }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        // console.log(values)
        if (!err) {
          // console.log("Received values of form: ", values);
          this.$http
            .post(`/api/v1/apikeys/`, {
              name: values.name,
              exchange: values.exchange.toLowerCase(),
              api_key: values.apikey,
              secret_key: values.secret,
              running_server: values.runserver,
              currencies: Array.from(values.currencies)
            })
            .then(() => {
              this.redirect();
            });
        } else {
          console.log(err);
        }
      });
    },
    redirect() {
      // TO-DO : redirect error will solved(Completed)
      // this.$router.push("/pages/UserManagement/UserList")
      this.$router.go("/pages/UserManagement/UserList");
      // this.$router.push(`/api/v1/apikeys`);
    },
  },
};
</script>

<style>
.responsive-image {
    max-width: 100%;
    height: auto;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
</style>
