<template>
    <div>
        <a-row :gutter="24" type="flex" align="stretch">
            <a-col :span="24" class="mb-5">
                <a-select style="width: 200px; margin-right: 10px" placeholder="Select APIKEY" :loading="is_apikey_loading" @change="handleSelectApikeyChange">
                    <a-select-option v-for="v in api_key_list" :key="v.id" :value="v.id">
                        {{v.name}}
                    </a-select-option>
                </a-select>
                <a-select 
                style="width: 200px; margin-right: 10px"
                placeholder="Select PAIR" 
                showSearch
                :loading="is_pair_loading"
                @change="handleSelectPairChange"
                >
                    <a-select-option v-for="(v, i) in symbol_list" :key="i" :value="v">
                        {{v}}
                    </a-select-option>
                </a-select>
                <a-input
                style="width: 200px; margin-right: 10px"
                placeholder="Binding Server" 
                :value="selectedApiKeyServer" 
                disabled>
                </a-input>
            </a-col>
            <template v-if="select_api_key_id && select_pair && select_coin">
                <a-divider />                 
                <balance ref="balance_ref" :coin="select_coin" :pair="select_pair" :apikeyId="select_api_key_id" :running_server="select_server"/>
            </template>
            <a-divider />
            
                
            <!--호가창-->
            <template v-if="select_api_key_id && select_pair && select_coin">
                <a-col :span="14">
                    <a-input-number v-model="click_order_amount" placeholder="Click Order(Amount)" style="width: 300px;" class='mb-5 mr-5'></a-input-number>
                    <a-radio-group v-model="select_click_order_type" class='mb-5 mr-5' button-style="solid">
                        <a-radio-button value="bid">Buy</a-radio-button>
                        <a-radio-button value="ask" class='danger'>Sell</a-radio-button>
                    </a-radio-group>
                    
                </a-col>
                <a-col :span="8">
                    <!-- <a-select
                    style="width: 200px"
                    placeholder="모아보기" 
                    showSearch
                    @change="handleSelectPriceUnitChange"
                    >
                        <a-select-option v-for="(v, i) in price_unit" :key="i" :value="v">
                            x{{v}}
                        </a-select-option>
                    </a-select> -->
                    <!--누적가격/수량 보기 설정-->
                    <!--<a-radio-group v-model:value="select_click_cum_view_type" class='mb-5 mr-5' button-style="solid">
                        <a-radio-button value="price">누적가격</a-radio-button>
                        <a-radio-button value="amount" class='danger'>누적수량</a-radio-button>
                    </a-radio-group>-->
                </a-col>
                <a-col :span="18" class="mb-24">
                    <ordertable :pair="select_pair"
                                :coin="select_coin"
                                :selectClickOrderType="select_click_order_type"
                                :selectPriceUnit="select_price_unit"
                                :selectCumViewType="select_click_cum_view_type"
                                :running_server="select_server"
                                @setQuantityForm="set_quantity_form"
                                @setPriceForm="set_price_form"
                                @clickOrderAsk="handleClickOrderAsk"
                                @clickOrderBid="handleClickOrderBid"
                    />
                </a-col>
            
                <!--주문 입력 창-->
                <a-col :span="4" class="mb-24">
                    <a-card>
                        
                        <a-input-number v-model.number="input_price" placeholder="price" style="width: 100%">
                            <!--<a-icon slot="prefix" type="user" />-->
                        </a-input-number>
                        
                        <a-input-number v-model.number="input_amount" placeholder="amount" style="width: 100%">
                            <!--<a-icon slot="prefix" type="user" />-->
                        </a-input-number>
                        <a-row type="flex" :gutter="6" class="card-footer mt-5" align="middle">
                            <a-col :span="12">
                                <a-button type="primary" size="small" :loading="false" @click="handleAskButton">Buy</a-button>
                            </a-col>
                            <a-col :span="12" class="text-right">
                                <a-button type="danger" size="small" :loading="false" @click="handleBidButton">Sell</a-button>
                            </a-col>
                        </a-row>
                    </a-card>
                    <!--지갑현황-->
                </a-col>
                <a-col :span="8" >
                    <openOrders ref="open_orders_ref" :pair="select_pair" :coin="select_coin" :apikeyId="select_api_key_id" :running_server="select_server"/>
                </a-col>
                <!--오더리스트-->
                <a-col :span="8">
                
                    <orders ref="orders_ref" :pair="select_pair" :coin="select_coin" :apikeyId="select_api_key_id" :running_server="select_server"/>
                    
                </a-col>
            </template>
        </a-row>
        
    </div>
</template>
<script>

import ordertable from './ordertable';
import orders from './orders';
import openOrders from './openOrders';
import balance from './balance';

const ExchangeName = 'huobi';

export default {
    components: {
        ordertable,
        orders,
        openOrders,
        balance,
    },
    data() {
        return {
            input_price: '',
            input_amount: '',
            is_apikey_loading: true,
            is_pair_loading: false,
            select_api_key_id: null,
            api_key_list: [],
            symbol_list: [],
            select_coin: null,
            select_pair: null,
            click_order_amount: '',
            select_click_order_type: 'ask',
            price_unit: [
                1, 10, 100, 1000
            ],
            select_price_unit: 1,
            select_click_cum_view_type: 'amount',
            selectedApiKeyServer: '',
            select_server: null,
        }
    },
    methods: {
        handleSelectPriceUnitChange(value){
            this.select_price_unit = value
        },
        handleSelectApikeyChange(value){
            this.select_api_key_id = value
            this.is_pair_loading = true
            const _symbols = this.api_key_list.filter((v) => v.id === value)
            if (_symbols.length > 0) {
                this.symbol_list = _symbols[0].currencies;
                this.selectedApiKeyServer = _symbols[0].running_server;
                this.select_server = _symbols[0].running_server;
            } else {
                this.symbol_list = [];
                this.selectedApiKeyServer = '';
            }
            this.is_pair_loading = false
        },
        handleSelectPairChange(value){
            const v = value.split('/')
            this.select_coin = v[0]
            this.select_pair = v[1]
            this.select_price_unit = 1
        },
        set_price_form(v){
            this.input_price = Number(v)
        },
        set_quantity_form(v){
            this.input_amount = Number(v)
        },
        get_symbol(){
            return `${this.select_coin}/${this.select_pair}`
        },
        async get_apikeys(){
            const res = await this.$http.get('/api/v1/apikeys/')
            this.is_apikey_loading = false
            return res.data
        },
        async get_symbols(){
            const res = await this.$http.get(`/api/v1/exchange/${ExchangeName}/symbols`)
            this.is_pair_loading = false
            return res.data
        },
        async handleBidButton(){
            if (this.input_price > 0 || this.input_amount > 0){
                await this.createOrder(this.input_price, this.input_amount, 'sell')
            }
        },
        async handleAskButton(){
            if (this.input_price > 0 || this.input_amount > 0){
                await this.createOrder(this.input_price, this.input_amount, 'buy')
            }
        },
        async handleClickOrderBid(p){
            if (this.click_order_amount > 0 && p > 0){
                await this.createOrder(p, this.click_order_amount, 'buy')
            }
        },
        async handleClickOrderAsk(p){
            if (this.click_order_amount > 0 && p > 0){
                await this.createOrder(p, this.click_order_amount, 'sell')
            }
        },
        async createOrder(p, q, side){
            // this.select_api_key_id
            // price
            // quentity
            // exchange name
            const body = {
                apikeyId: this.select_api_key_id,
                symbol: this.get_symbol(),
                side,
                amount: q,
                price: p,
                running_server: this.select_server
            }
            // this.$notification.open({
			// 		message: `Create Order - ${this.get_symbol()}`,
			// 		description: `${q}, ${p}, ${side}`,
			// 		placement: 'topRight',
			// 	});
            try {
                const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/createorder`, body)
                this.$notification.open({
                        message: `Create Order - Success`,
                        description: `${this.select_pair} - Price: ${p} Vol: ${q}`,
                        placement: 'bottomLeft',
                    });
            } catch (e) {
                this.$notification.open({
                        message: `Create Order - Fail..`,
                        description: e.response.data.message,
                        placement: 'bottomLeft',
                    });
            }
            this.refresh_order_tables()
        },
        refresh_order_tables(){
            this.$refs.open_orders_ref.UpdateOrders()
            this.$refs.orders_ref.UpdateOrders()
            this.$refs.balance_ref.UpdateBalance()
        },
        async init(){
            const apikey_list = await this.get_apikeys()
            apikey_list.forEach(data => {
                if(data.exchange === ExchangeName){
                    this.api_key_list.push(data)
                }
            })
            // const symbols = await this.get_symbols()
            // this.symbol_list = []
        }
    },
    async mounted() {
        this.init()
    },
    
}
</script>
<style lang="">
    
</style>