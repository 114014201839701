<template>
    <div>
        <a-button @click="() => {this.add_view = true}">{{ $t("mm.add_button") }}</a-button>
        <a-button :loading="loading" @click="getMM">{{ $t("mm.refresh_button") }}</a-button>
        <div v-if="add_view">
            <a-form layout="inline">
                <a-input-group>
                    <a-input-search style="width: 300px"
                        v-model="new_mm_name"
                        placeholder="Name"
                        enter-button="Add"
                        @search="AddHandler"
                        />
                </a-input-group>
            </a-form>
        </div>
        <a-card :bordered="false" class="header-solid h-full mt-10" :bodyStyle="{paddingTop: 0, paddingBottom: 0 }"
            v-for="mm, index in mm_table.slice().reverse()" :key="index"
            >
            <template #title>
                <h6 class="font-semibold m-0">{{ mm.name }}</h6>
            </template>
            <template #extra>
                <a-button @click="deleteMM(mm)">{{ $t("mm.delete_button") }}</a-button>
            </template>

            <a-form layout="inline">
                <!-- 거래소 선택 -->
                <a-form-item>
                    <a-select
                        v-model="mm.exchange"
                        size="small"
                        style="width: 200px"
                        placeholder="Exchange"
                        :options="exchange_list"
                        @change="changeExchangeSelect(mm.exchange)"
                        showSearch
                        />
                </a-form-item>
                <!-- api키선택 -->
                <a-form-item>
                    <a-select
                        v-model="mm.apikey"
                        size="small"
                        style="width: 200px"
                        placeholder="APIKEY"
                        :options="apikey_list.filter(d => d.exchange === mm.exchange)"
                        showSearch
                        @change="handleApikeyChange"
                        />
                </a-form-item>
                <!-- 서버 표기 추가 -->
                <a-form-item>
                    <a-input
                        v-model="mm.server"
                        size="small"
                        style="width: 200px"
                        placeholder="Binding Server"
                        disabled>
                    </a-input>
                </a-form-item>
                <!-- 코인선택 -->
                <a-form-item>
                    <a-select
                        v-model="mm.coin"
                        size="small"
                        style="width: 200px"
                        placeholder="Coin"
                        :options="coin_list[mm.exchange]"
                        showSearch
                        />
                </a-form-item>
                <a-form-item>
                    <a-select
                        v-model="mm.pair"
                        size="small"
                        style="width: 200px"
                        placeholder="Pair"
                        :options="pair_list[mm.exchange]"
                        showSearch
                        />
                </a-form-item>
                <!-- Telegram -->
                <a-form-item>
                    <a-select
                        v-model="mm.telegram_id"
                        size="small"
                        style="width: 200px"
                        placeholder="telegram"
                        :options="telegram_list"
                        showSearch
                        />
                </a-form-item>
            </a-form>
            <a-divider />
            <span class="text-md font-bold">RUN</span>
            <a-switch :checked="mm.is_start" class="mx-25" @click="MMStartHandler(mm)"></a-switch>
            <a-divider />
            <!-- 수익실현 -->
            <span class="text-md font-semibold">PROFIT</span>
            <!-- 매수 -->
            <div class="mt-25 ml-5">
                <span class="font-semibold">{{$t("mm.label_buy")}}</span>
                <a-switch v-model="mm.configs.profit.buy.is_start" class="mx-10 mb-5"></a-switch>
            </div>
            <a-form layout="inline" class="mx-25">
                
                <a-form-item :label="$t('mm.label_target_price')">
                    <a-input v-model="mm.configs.profit.buy.target_price"></a-input>
                </a-form-item>

                <a-form-item :label="$t('mm.label_target_amount')">
                    <a-input v-model="mm.configs.profit.buy.target_amount"></a-input>
                </a-form-item>

                <a-form-item :label="$t('mm.label_trade_amount')">
                    <a-input v-model="mm.configs.profit.buy.trade_amount"></a-input>
                </a-form-item>

                <a-form-item :label="$t('mm.label_ignore_my_order')">
                    <a-checkbox v-model="mm.configs.profit.buy.ignore_my_order"></a-checkbox>
                </a-form-item>
            </a-form>
            <!-- 매도 -->
            <div class="mt-25 ml-5">
                <span class="font-semibold">{{$t("mm.label_sell")}}</span>
                <a-switch v-model="mm.configs.profit.sell.is_start" class="mx-10 mb-5"></a-switch>
            </div>
            <a-form layout="inline" class="mx-25">
                
                <a-form-item :label="$t('mm.label_target_price')">
                    <a-input v-model="mm.configs.profit.sell.target_price"></a-input>
                </a-form-item>

                <a-form-item :label="$t('mm.label_target_amount')">
                    <a-input v-model="mm.configs.profit.sell.target_amount"></a-input>
                </a-form-item>

                <a-form-item :label="$t('mm.label_trade_amount')">
                    <a-input v-model="mm.configs.profit.sell.trade_amount"></a-input>
                </a-form-item>

                <a-form-item :label="$t('mm.label_ignore_my_order')">
                    <a-checkbox v-model="mm.configs.profit.sell.ignore_my_order"></a-checkbox>
                </a-form-item>
            </a-form>
                
            <a-divider />

            <!-- 수익실현 -->
            <span class="text-md font-semibold">Arbitrage</span>

            <!-- 매도 -->
            <div class="mt-25 ml-5">
                <span class="font-semibold">{{$t("mm.label_sell")}}</span>
                <a-switch v-model="mm.configs.arbi.sell.is_start" class="mx-10 mb-5"></a-switch>
            </div>
            <a-form layout="inline" class="mx-25">
                <!-- 목표가격 -->
                <a-form-item :label="$t('mm.label_target_price')">
                    <a-input v-model="mm.configs.arbi.sell.target_price"></a-input>
                </a-form-item>
                <!-- 잔액보호 -->
                <a-form-item :label="`${$t('mm.label_protect_coin')} ${mm.coin || ''}`">
                    <a-input v-model="mm.configs.arbi.protect.coin"></a-input>
                </a-form-item>
                <!-- 속도 -->
                <a-form-item :label="$t('mm.label_speed')">
                    <a-input v-model="mm.configs.arbi.sell.speed"></a-input>
                </a-form-item>
                <!-- 총목표거래량 -->
                <a-form-item :label="$t('mm.label_total_trade_limit_coin')">
                    <a-input v-model="mm.configs.arbi.sell.total_amount"></a-input>
                    <span class="p-0">= {{mm.configs.arbi.sell.target_price * mm.configs.arbi.sell.total_amount}} {{mm.pair || ''}}</span>
                </a-form-item>

            </a-form>

            <!-- 매수 -->
            <div class="mt-25 ml-5">
                <span class="font-semibold">{{$t("mm.label_buy")}}</span>
                <a-switch v-model="mm.configs.arbi.buy.is_start" class="mx-10 mb-5"></a-switch>
            </div>
            <a-form layout="inline" class="mx-25">
                <!-- 목표가격 -->
                <a-form-item :label="$t('mm.label_target_price')">
                    <a-input v-model="mm.configs.arbi.buy.target_price"></a-input>
                </a-form-item>
                <!-- 잔액보호 -->
                <a-form-item :label="`${$t('mm.label_protect_pair')} ${mm.pair || ''}`">
                    <a-input v-model="mm.configs.arbi.protect.pair"></a-input>
                </a-form-item>
                <!-- 속도 -->
                <a-form-item :label="$t('mm.label_speed')">
                    <a-input v-model="mm.configs.arbi.buy.speed"></a-input>
                </a-form-item>
                <!-- 총목표거래량 -->
                <a-form-item :label="$t('mm.label_total_trade_limit_pair')">
                    <a-input v-model="mm.configs.arbi.buy.total_amount"></a-input>
                    <span class="p-0">= {{mm.configs.arbi.buy.target_price * mm.configs.arbi.buy.total_amount}} {{mm.pair || ''}}</span>
                </a-form-item>
            </a-form>
            
            <a-divider />
            <div class="mb-15">
            <a-button @click="updateMM(mm)">{{$t('mm.label_config_apply')}}</a-button>
            </div>
        </a-card>        
    </div>
</template>
<script>

const template = {
    name: 'MM name',
    is_start: false,
    exchange: undefined,
    apikey: undefined,
    symbol: undefined,
    server: undefined,
    telegram_id: undefined,
    configs: {
        profit: {
            buy: {
                is_start: false,
                target_price: 0,
                target_amount: 0,
                trade_amount: 0,
                ignore_my_order: false
            },
            sell: {
                is_start: false,
                target_price: 0,
                target_amount: 0,
                trade_amount: 0,
                ignore_my_order: false
            }
        },
        arbi: {
            buy: {
                is_start: false,
                target_price: 0,
                total_amount: 0,
                speed: 1
            },
            sell: {
                is_start: false,
                target_price: 0,
                total_amount: 0,
                speed: 1
            },
            protect: {
                coin: 0,
                pair: 0
            }
        },
        fake_wall: {
            buy: {
                is_start: false,
                start: 0,
                end: 0
            },
            sell: {
                is_start: false,
                start: 0,
                end: 0
            }
        }
    },
}

export default {
    components: {
        
    },

    data() {
        return {
            loading: true,
            new_mm_name: undefined,
            add_view: false,
            exchange_list: [],
            apikey_list: [],
            coin_list: {},
            pair_list: {},
            telegram_list: [],      
            mm_table: [
                // {
                //     name: 'MM name',
                //     "_id": '123213',
                //     is_start: true,
                //     exchange: undefined,
                //     apikey: undefined,
                //     coin: undefined,
                //     pair: undefined,
                //     configs: {
                //         profit: {
                //             buy: {
                //                 is_start: false,
                //                 target_price: 1000,
                //                 target_amount: 100000,
                //                 trade_amount: 90000,
                //                 ignore_my_order: false
                //             },
                //             sell: {
                //                 is_start: true,
                //                 target_price: 2000,
                //                 target_amount: 110000,
                //                 trade_amount: 90000,
                //                 ignore_my_order: false
                //             }
                //         },
                //         fake_wall: {
                //             buy: {
                //                 is_start: false,
                //                 start: 1,
                //                 end: 2
                //             },
                //             sell: {
                //                 is_start: false,
                //                 start: 3,
                //                 end: 4
                //             }
                //         }
                //     }
                // },
                // {
                //     name: 'MM name 2',
                //     "_id": '123214',
                //     is_start: false,
                //     exchange: undefined,
                //     apikey: undefined,
                //     symbol: undefined,
                //     configs: {
                //         profit: {
                //             buy: {
                //                 is_start: false,
                //                 target_price: 1000,
                //                 target_amount: 100000,
                //                 trade_amount: 90000,
                //                 ignore_my_order: true
                //             },
                //             sell: {
                //                 is_start: true,
                //                 target_price: 2000,
                //                 target_amount: 100000,
                //                 trade_amount: 90000,
                //                 ignore_my_order: false
                //             }
                //         },
                //         fake_wall: {
                //             buy: {
                //                 is_start: false,
                //                 start: 1,
                //                 end: 2
                //             },
                //             sell: {
                //                 is_start: false,
                //                 start: 3,
                //                 end: 4
                //             }
                //         }
                //     }
                // }
            ],
            apikey_list2: [],
        }
    },
    filters: {
        toUpperCase(value){
            return value.toUpperCase()
        }
    },
    async mounted() {
        await this.getExchange()
        await this.get_apikeys()
        await this.getMM()
        await this.getTelegram();
    },
    methods: {
        async getTelegram() {
            try {
                const res = await this.$http.get("/api/v1/telegram/");
                for (const o of res.data) {
                this.telegram_list.push({
                    label: o.name,
                    value: o.id,
                });
                // this.telegram_dict[o.id] = o.name
                }
                // console.log(this.telegram_list)
            } catch (e) {
                console.error(e);
            }
        },
        async MMStartHandler(mm) {
            try {
                const res = await this.$http.get(`/api/v1/autotrade/mm/run/${mm._id}`, {
                    params: {
                       server: mm.server
                    }
                });
                mm.is_start = res.data;
            } catch (e) {
                console.error(e)
            }
        },
        async createMM(mm) {
            try {
                const res = await this.$http.post('/api/v1/autotrade/mm', mm)
                return res.data.id
            } catch (e) {
                console.error(e)
            }
        },
        async getMM() {
            this.loading = true
            try {
                const res = await this.$http.get('/api/v1/autotrade/mm')
                this.mm_table = res.data
            } catch (e) {
                console.error(e)
            }
            this.loading = false
        },
        async updateMM(o) {
            try {
                let updateData = o
                await this.$http.put(`/api/v1/autotrade/mm/${o._id}`, updateData)
                this.$notification.success({
                    message: `Succeess Update`,
                    placement: 'bottomLeft',
                });

            } catch (e) {
                console.error(e)
                this.$notification.error({
                    message: `Error Update..`,
                    placement: 'bottomLeft',
                });
            }
        },
        async deleteMM(o) {
            try {
                await this.$http.delete(`/api/v1/autotrade/mm/${o._id}`)
                this.mm_table.splice(this.mm_table.indexOf(o), 1)
                this.$notification.success({
                    message: `Succeess Delete`,
                    placement: 'bottomLeft',
                });
            } catch (e) {
                console.error(e)
            }
        },
        async AddHandler(e) {
            const _template = Object.assign({}, template)
            const new_mm_table = Object.assign(_template, {name: e})
            const id = await this.createMM(new_mm_table)
            if (id){
                new_mm_table._id = id
                this.mm_table.push(new_mm_table)
                this.new_mm_name = undefined
                this.add_view = false
            }
        },
        async getExchange() {
            try {
                const res = await this.$http.get('/api/v1/info/exchange')
                for (const [key, value] of Object.entries(res.data)) {
                    this.exchange_list.push({
                        label: key,
                        value
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        async changeExchangeSelect(v) {
            if (!Object.keys(this.coin_list).includes(v)) {
                const _res = await this.get_symbols(v)
                this.coin_list[v] = _res.coin_list
                this.pair_list[v] = _res.pair_list
            }
            this.$forceUpdate()
            // await this.get_symbols(v)
        },
        async get_apikeys(){
            this.apikey_list = []
            this.is_apikey_loading = true
            const res = await this.$http.get('/api/v1/apikeys/', {
                // params:{
                //     exchange: v
                //     }
                })
            res.data.forEach(e => {
                this.apikey_list.push(
                    {   
                        exchange: e.exchange,
                        label: e.name,
                        value: e.id
                    }
                )
            });
            this.apikey_list2 = res.data
            this.is_apikey_loading = false
        },
        handleApikeyChange(value) {
            const selectedApiKey = this.apikey_list2.find(apikey => apikey.id === value);
            if (selectedApiKey) {
                this.mm_table[this.mm_table.length - 1].server = selectedApiKey.running_server; 
            }
        },
        async get_symbols(exchange){
            const coin_list = []
            const pair_list = []
            this.is_symbols_loading = true
            const res = await this.$http.get(`/api/v1/exchange/${exchange}/symbols`)
            let symbol_split = ''
            if (res.data[0].includes('/')) {
                symbol_split = '/'
            } else if (res.data[0].includes('_')) {
                symbol_split = '_'
            }
            if (symbol_split !== '') {
                res.data.forEach(e => {
                    const _symbol = e.split(symbol_split)
                    if (!coin_list.find(s => s.label === _symbol[0])) {
                        coin_list.push(
                            {
                                label: _symbol[0],
                                value: _symbol[0].toLowerCase()
                            }
                        )
                    }
                    if (!pair_list.find(s => s.label === _symbol[1])) {
                        pair_list.push({
                            label: _symbol[1],
                            value: _symbol[1].toLowerCase()
                        })
                    }

                });
            } else {
                res.data.forEach(e => {
                    coin_list.push(
                        {
                            label: e,
                            value: e.toLowerCase()
                        }
                    )
                });
                pair_list.push({
                    label: 'KRW',
                    value: 'krw'
                })
            }
            this.is_symbols_loading = false
            return {
                coin_list,
                pair_list
            }
        },
    },
}
</script>
<style lang="">
    
</style>