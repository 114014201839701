<template>
    <div>
        <a-button @click="CancelAllOrders()" :loading="loading_cancel_all_orders">{{ $t("trade.label_cancel_all_orders") }}</a-button>
        <a-button @click="CancelAllOrders('buy')" :loading="loading_cancel_all_orders">{{ $t("trade.label_cancel_buy_orders") }}</a-button>
        <a-button @click="CancelAllOrders('sell')" :loading="loading_cancel_all_orders">{{ $t("trade.label_cancel_sell_orders") }}</a-button>
        <a-button @click="UpdateOrders()" class="ml-5">
            <a-icon type="reload" theme="outlined" />
                Refresh
        </a-button>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns"
                     :row-key="record => record.order_id"
                     :data-source="orders_table" 
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    <!-- {{ $moment(Number(text)/1000).format('YYYY-MM-DD HH:mm:ss') }} -->
                    {{ text }}

                </template>
                <template slot="cancel-order" slot-scope="text, record">
                    <a-button danger class="m-0" @click="clickCancelOrderButtonHandler(record)" :loading="cancel_order_list.includes(record.order_id)">{{ $t("trade.label_cancel") }}</a-button>
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
const ExchangeName = 'bithumb';
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => a.order_date - b.order_date,
                    defaultSortOrder: 'descend',
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'side',
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    sorter: (a, b) => a.price - b.price,
                    dataIndex: 'price',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_amount'),
                    dataIndex: 'units',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_remaining'),
                    dataIndex: 'units_remaining',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_cancelorder'),
                    scopedSlots: { customRender: 'cancel-order'}
                }
            ],
            //  {
            //     "order_currency": "LM",
            //     "payment_currency": "KRW",
            //     "order_id": "C0698000000021593492",
            //     "order_date": "1713945167846299",
            //     "type": "ask",
            //     "watch_price": "0",
            //     "units": "5,000",
            //     "units_remaining": "5,000",
            //     "price": "14"
            //   }

            orders_table: [],
            cancel_order_list: [],
            loading_cancel_all_orders: false
        }
    },
    methods: {
        async CancelAllOrders(type='all'){
            this.loading_cancel_all_orders = true
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/cancelallorders`, {
                apikeyId: this.apikeyId,
                symbol: `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`,
                type,
                running_server: this.running_server
            })
            // {
            //     "status": "ok",
            //     "data": {
            //         "success-count": "1",
            //         "failed-count": "0",
            //         "next-id": "-1"
            //     }
            // }
            this.$notification.open({
                message: `Cancel Order`,
                description: `Success : ${res.data['total_order_count']}`,
                placement: 'bottomLeft',
            });
            this.loading_cancel_all_orders = false
        },
        async UpdateOrders(){
            const res = await this.$http.post('/api/v1/exchange2/bithumb/openorders', {
                apikeyId: this.apikeyId,
                symbol: `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`,
                running_server: this.running_server
            })
            // console.log(res.data)
            let res_ = res.data;
            if (res_.length > 0) {
                res_.forEach(item => {
                    const date = new Date(parseInt(item.order_date) / 1000); 
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

                    item.timestamp = koreanTime;

                    item.side = item.type === 'bid' ? 'buy' : 'sell';
                });
            }
            
            this.orders_table = res_;
        },
        async clickCancelOrderButtonHandler(record){
            this.cancel_order_list.push(record.order_id)
            // record.order_id
            const res = await this.$http.post('/api/v1/exchange2/bithumb/cancelorder', {
                apikeyId: this.apikeyId,
                symbol: `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`,
                order_id: record.order_id,
                side: record.type,
                running_server: this.running_server
            })
            this.$notification.open({
					message: `Cancel Order`,
					description: `${this.coin.toUpperCase()}/${this.pair.toUpperCase()} - 가격: ${record.price} 수량: ${record.units}`,
					placement: 'bottomLeft',
				});
            this.UpdateOrders()
        }
        
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (5 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">


</style>