var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":""},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-20",attrs:{"label":"Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [{ required: true, message: 'Please enter user name' }],
            } ]),expression:"[\n            'name',\n            {\n              rules: [{ required: true, message: 'Please enter user name' }],\n            },\n          ]"}],attrs:{"placeholder":"Name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Running Server","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'runserver',
            {
              rules: [{ required: true, message: 'Please select server' }],
            } ]),expression:"[\n            'runserver',\n            {\n              rules: [{ required: true, message: 'Please select server' }],\n            },\n          ]"}],attrs:{"placeholder":"server"}},_vm._l((_vm.server_list),function(v){return _c('a-select-option',{key:v.value,attrs:{"value":v.value}},[_vm._v(_vm._s(v.label))])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Exchange","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'exchange',
            {
              rules: [{ required: true, message: 'Please select exchange' }],
            } ]),expression:"[\n            'exchange',\n            {\n              rules: [{ required: true, message: 'Please select exchange' }],\n            },\n          ]"}],attrs:{"default-value":"Bithumb","placeholder":"exchange"},on:{"change":_vm.get_symbols}},_vm._l((_vm.exchange),function(e,i){return _c('a-select-option',{key:i,attrs:{"value":e.value}},[_vm._v(_vm._s(e.label))])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Currencies","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'currencies',
            {
              rules: [{ required: true, message: 'Please select currencies' }],
            } ]),expression:"[\n            'currencies',\n            {\n              rules: [{ required: true, message: 'Please select currencies' }],\n            },\n          ]"}],attrs:{"placeholder":"currencies","loading":_vm.is_currency_loading,"mode":"multiple"}},_vm._l((_vm.currency_list),function(e,i){return _c('a-select-option',{key:i,attrs:{"value":e.toLowerCase()}},[_vm._v(_vm._s(e))])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-20",attrs:{"label":"Api Key"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'apikey',
            {
              rules: [{ required: true, message: 'Please enter API Key' }],
            } ]),expression:"[\n            'apikey',\n            {\n              rules: [{ required: true, message: 'Please enter API Key' }],\n            },\n          ]"}],attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-20",attrs:{"label":"Secret"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'secret',
            {
              rules: [{ required: true, message: 'Please enter Secret Key' }],
            } ]),expression:"[\n            'secret',\n            {\n              rules: [{ required: true, message: 'Please enter Secret Key' }],\n            },\n          ]"}],attrs:{"placeholder":""}})],1)],1)],1),[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',[_c('a-button',{on:{"click":_vm.toggleImage}},[_vm._v(_vm._s(_vm.isImageVisible ? '접기' : 'API 입력 가이드'))]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isImageVisible)?_c('img',{staticClass:"responsive-image",attrs:{"src":require("./API_rule.png"),"alt":"api_rule"}}):_vm._e()])],1)],1)],_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 25px',
      background: '#fff',
      textAlign: 'right',
    })},[_c('a-button',{staticClass:"px-15",attrs:{"type":"primary","size":"small","html-type":"submit","align":"center"}},[_vm._v(" CREATE ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }