<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="balance_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true"
                     :loading="loading">
                
            </a-table>
		</a-card>
    </div>
</template>
<script>
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: 'Pair',
                    dataIndex: 'currency',
                },
                {
                    title: 'Free',
                    dataIndex: 'free',
                },
                {
                    title: 'Used',
                    dataIndex: 'used',
                },
                {
                    title: 'Total',
                    dataIndex: 'total',
                },
                
                
            ],

            balance_table: [
            ],
            loading: true
        }
    },
    computed: {
        ConvertCoin() {
            return this.coin.toUpperCase()
        },
        ConvertPair() {
            return this.pair.toUpperCase()
        },
    },
    methods: {
        async UpdateBalance(){
            const res = await this.$http.post('/api/v1/exchange2/upbit/balance', {
                apikeyId: this.apikeyId,
                symbol: `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`,
                running_server: this.running_server
            })

            let balance_coin = res.data[this.ConvertCoin]
            let balance_pair = res.data[this.ConvertPair]
            
            this.balance_table[0].currency = this.coin;
            this.balance_table[0].free = this.setPriceFormat(parseFloat(balance_coin.free));
            this.balance_table[0].used = this.setPriceFormat(parseFloat(balance_coin.used));
            this.balance_table[0].total = this.setPriceFormat(parseFloat(balance_coin.total));
            
            this.balance_table[1].currency = this.pair;
            if (this.pair.toUpperCase() === 'KRW') {
                this.balance_table[1].free = this.setPriceFormat(parseInt(balance_pair.free));
                this.balance_table[1].used = this.setPriceFormat(parseInt(balance_pair.used));
                this.balance_table[1].total = this.setPriceFormat(parseInt(balance_pair.total));
            } else {
                this.balance_table[1].free = this.setPriceFormat(parseFloat(balance_pair.free));
                this.balance_table[1].used = this.setPriceFormat(parseFloat(balance_pair.used));
                this.balance_table[1].total = this.setPriceFormat(parseFloat(balance_pair.total));
            }

        },
        init_balance(){
            this.balance_table = []
            this.balance_table.push({
                currency: this.coin,
                free: 0,
                used: 0,
                total: 0
            })
            this.balance_table.push({
                currency: this.pair,
                free: 0,
                used: 0,
                total: 0
            })
        },
        setPriceFormat(v){
            return Number(v).toLocaleString(undefined, { maximumFractionDigits: 5 })
        }
        
    },
    async mounted() {
        this.init_balance()
        await this.UpdateBalance()
        this.loading = false
        this.updateInterval = setInterval(() => {
            this.UpdateBalance()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        pair(v){
            this.loading = true
            this.UpdateBalance()
            this.loading = false
        }
    },

}
</script>

<style lang="scss">

</style>