<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="orders_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true">
                <template slot="timestamp" slot-scope="text">
                    <!-- {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }} -->
                    {{ text }}
                </template>
                <!-- <template slot="is_ask" slot-scope="text">
                    {{ text === false ? "BUY" : "SELL" }}
                </template> -->
                <template slot="amount" slot-scope="text, record">
                    {{ record.side === 'BUY_MARKET' ? record.cummulativeQuoteQty : Number(record.price * record.executedQty).toLocaleString(undefined, { maximumFractionDigits: 5 }) }}
                </template>
                <template slot="comma-format" slot-scope="text">
                    {{ Number(parseFloat(text)).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
const ExchangeName = 'mexc';
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('trade.column_timestamp'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => a.time - b.time,
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('trade.column_orderside'),
                    dataIndex: 'side',
                },
                
                // {
                //     title: '주문통화',
                //     dataIndex: 'order_currency',
                // },
                // {
                //     title: '결제통화',
                //     dataIndex: 'payment_currency',
                // },
                {
                    title: this.$i18n.t('trade.column_order_price'),
                    scopedSlots: { customRender: 'amount'}
                },
                {
                    title: this.$i18n.t('trade.column_price'),
                    dataIndex: 'price',
                    scopedSlots: { customRender: 'comma-format'}
                },
                {
                    title: this.$i18n.t('trade.column_amount'),
                    dataIndex: 'executedQty',
                    scopedSlots: { customRender: 'comma-format'}
                },
            ],
            // [
            //   {
            //     "symbol": "LTCBTC",
            //     "orderId": 1,
            //     "orderListId": -1, 
            //     "clientOrderId": "myOrder1",
            //     "price": "0.1",
            //     "origQty": "1.0",
            //     "executedQty": "0.0",
            //     "cummulativeQuoteQty": "0.0",
            //     "status": "NEW",
            //     "timeInForce": "GTC",
            //     "type": "LIMIT",
            //     "side": "BUY",
            //     "stopPrice": "0.0",
            //     "icebergQty": "0.0",
            //     "time": 1499827319559,
            //     "updateTime": 1499827319559,
            //     "isWorking": true,
            //     "origQuoteOrderQty": "0.000000"
            //   }
            // ]
            orders_table: []
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`
        },
        async UpdateOrders(){
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/orders`, {
                apikeyId: this.apikeyId,
                symbol: this.getSymbol(),
                running_server: this.running_server
            })
            let res_ = res.data;
            if (res_.length > 0) {
                res_.forEach(item => {
                    item.cTime = parseInt(item.time)
                    const date = new Date(item.cTime); 
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                    item.timestamp = koreanTime;
                    
                    if (item.type === 'MARKET' && item.side === 'BUY') {
                        item.side = "BUY_MARKET";
                        item.executedQty = 0;
                        item.price = 0;
                    } else if (item.type === 'MARKET' && item.side === 'SELL') {
                        item.side = "SELL_MARKET";
                        item.price = 0;
                    }
                });
            }
            this.orders_table = res_; 
        },
        
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">

</style>